import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { Event, PageView } from "../../../utils/analytics";

const ProtectedRoute = ({ path, title, user, component: Component, render, ...rest }) => {

  useEffect(() => {
    document.title = title;
    PageView(title, path);
  },[]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user !== undefined && user !== "Guest" && !user.isArchived){
          //if (user.isAccountAccepted) {
          //}
          return Component ? <Component {...props} /> : render(props);

        }
        else if(user.isArchived){
          <Redirect
              to={{ pathname: "/archived-account", state: { from: props.location } }}
            />
        }
        else{
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }      
      }}
    />
  );
};

export default ProtectedRoute;