import React, { useState } from 'react';
import { Row, Col, Button, Input } from 'reactstrap';
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { groupnames } from '../../../libraries/filters';
import { updateDocumentProperty } from '../../../services/firestore';
import { db } from '../../../services/firebase';

const AdminStudyAdminUserProfile = ({user}) => {
    const [refresh, setRefresh] = useState(false);
    const [region, setRegion] = useState(user.region);
    const regions = [
        'Undefined',
        'Toronto',
        'Vancouver',
        'Montreal',
        'Calgary',
        'Edmonton'
    ]

    const MakeRegionalAdmin = () => {
        updateDocumentProperty(db, "users", user.uid, "isRegionalAdmin", true);
        setRefresh(!refresh);
    }

    const RemoveRegionalAdmin = () => {
        if (user.isSiteAdmin) {
            return;
        }
        updateDocumentProperty(db, "users", user.uid, "isRegionalAdmin", false);
        setRefresh(!refresh);
    }

    const MakeStudyAdmin = () => {
        updateDocumentProperty(db, "users", user.uid, "isStudyAdmin", true);
        setRefresh(!refresh);
    }

    const RemoveStudyAdmin = () => {
        if (user.isSiteAdmin) {
            return;
        }
        updateDocumentProperty(db, "users", user.uid, "isStudyAdmin", false);
        setRefresh(!refresh);
    }

    const UpdateRegion = (target) => {
        setRegion(target.value);
        updateDocumentProperty(db, "users", user.uid, "region", target.value);
        setRefresh(!refresh);
    }

    const SetArchived = (value) => {
        updateDocumentProperty(db, "users", user.uid, "isArchived", value);
        setRefresh(!refresh);
    }

    const DeleteAccount = () => {
        if (user.isSiteAdmin || !user.isArchived) {
            return;
        }
        db.collection("users").doc(user.uid).delete();
    }

    return ( 
        <StyledRow key={user.id}>
            <Col xs="4">
                <p><b>Username: </b>{user.username}</p>
                <p><b>Email: </b>{user.email}</p>
            </Col>
            <Col xs="3">
                <div><b>Region: {user.region}</b></div>
                {/*
                <StyledInput style={{ marginBottom:'15px'}} value={region} type="select" name="region" id="region" onChange={(e) => UpdateRegion(e.target)}>
                    {regions.map((region) => (
                        <option  key={region} value={region}>
                            {region}
                        </option>
                    ))}
                </StyledInput>
                    */}
            </Col>
            <FlexCol>
                {/*
                {!user.isSiteAdmin && ((user.isRegionalAdmin === undefined || !user.isRegionalAdmin) ? <StyledButton onClick={() => MakeRegionalAdmin()} color="primary">
                    <FAIconButton  icon={["fas", "check"]}></FAIconButton>Make Regional Admin
                </StyledButton> : <StyledButton onClick={() => RemoveRegionalAdmin()} color="primary">
                    <FAIconButton  icon={["fas", "exclamation-circle"]}></FAIconButton>Remove Regional Admin
                </StyledButton>)}
                {!user.isSiteAdmin && ((user.isStudyAdmin === undefined || !user.isStudyAdmin) ? <StyledButton onClick={() => MakeStudyAdmin()} color="primary">
                    <FAIconButton  icon={["fas", "check"]}></FAIconButton>Make Study Admin
                </StyledButton> : <StyledButton onClick={() => RemoveStudyAdmin()} color="primary">
                    <FAIconButton  icon={["fas", "exclamation-circle"]}></FAIconButton>Remove Study Admin
                </StyledButton>)}
                    */}
                {!user.isSiteAdmin && ((user.isArchived === undefined || !user.isArchived) ? <StyledButton onClick={() => SetArchived(true)} color="warning">
                    <FAIconButton  icon={["fas", "exclamation-circle"]}></FAIconButton>Archive Account
                </StyledButton> : <StyledButton onClick={() => SetArchived(false)} color="warning">
                    <FAIconButton  icon={["fas", "exclamation-circle"]}></FAIconButton>Unarchive Account
                </StyledButton>)}
                {!user.isSiteAdmin && user.isArchived && <StyledButton onClick={() => DeleteAccount()} color="danger">
                    <FAIconButton  icon={["fas", "exclamation-circle"]}></FAIconButton>Delete Account (not reverseable!)
                </StyledButton>}
            </FlexCol>
            <hr />
        </StyledRow> );
}

export default AdminStudyAdminUserProfile;

const FlexCol = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
`

const StyledRow = styled(Row)`
    height: auto;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;

const StyledButton = styled(Button)`
    margin-bottom: 3px;
    margin-left: 5px;
    max-height: 36px;
    border-style: solid;
    border-width: 1.5px;
    border-color: black;
`;

const StyledInput = styled(Input)`
&:focus{
    border-color: #372844;
    box-shadow: 0 0.5rem 1rem #9780af;
}

appearance: menulist;
`