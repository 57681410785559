import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { db } from '../../../services/firebase';
import ImageUploadAndCrop from '../../common/formComponents/imageUploadAndCrop';
import ContentListUpdate from './contentListUpdate';
import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UploadImageToStorage } from '../../../services/firestore';

const ManageExerciseGroupContent = () => {
    const [primaryText, setPrimaryText] = useState([]);
    const [partnershipList, setPartnershipList] = useState([]);
    const [studyAnnouncementList, setStudyAnnouncementList] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [backgroundImageUrl, setBackgroundImageUrl] = useState();
    const [backgroundImageFile, setBackgroundImageFile] = useState();
    
    const toggle = () => setModal(!modal);
    const docRef = db.collection("webPages").doc("home")

    useEffect(() => {
        ReturnHomePageInformation();   
    }, []);

    const ReturnHomePageInformation = () =>{
        docRef.onSnapshot((doc) => {
            const data = doc.data();
            if(doc.data()){
                if(data.primaryText){
                    setPrimaryText(data.primaryText);
                }
                if(data.partnershipCarousel){
                    setPartnershipList(data.partnershipCarousel);
                }
    
                if(data.studyAnnouncementCarousel){
                    setStudyAnnouncementList(data.studyAnnouncementCarousel);
                }
                
                if(data.backgroundImageUrl){
                    setBackgroundImageUrl(data.backgroundImageUrl)
                }
            }
            else{
                docRef.set({ primaryText, partnershipCarousel: [],  studyAnnouncementCarousel: [], backgroundImageUrl: [] })
            }

        });
    }

    return (
        <Container fluid={true}>
            <h1>Exercise Group Content</h1>
            
        </Container>
     );
}
 
export default ManageExerciseGroupContent;

const StyledModal = styled(Modal)`
    position: absolute;
    left: 40%;
    top: 40%;
`

const StyledProfileImage = styled.img`
    padding: 5px;
    margin-right: 30px;
    box-shadow: 0 0.5rem 1rem #9780af;
    max-width: 250px;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;

const StyledButton = styled(Button)`
    margin-right: 15px;
`