import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components'

const YoutubeEmbed = ({ embedId }) => (
  <StyledVideo>
    <StyledIFrame
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </StyledVideo>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;

const StyledVideo = styled.div`
position: relative;
padding-bottom: 56.25%
`

const StyledIFrame = styled.iframe`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
`