export const months = [
    {_id:"0", name:"January"},
    {_id:"1", name:"February"},
    {_id:"2", name:"March"},
    {_id:"3", name:"April"},
    {_id:"4", name:"May"},
    {_id:"5", name:"June"},
    {_id:"6", name:"July"},
    {_id:"7", name:"August"},
    {_id:"8", name:"September"},
    {_id:"9", name:"October"},
    {_id:"10", name:"November"},
    {_id:"11", name:"December"},
];

const years = [];
var today = new Date();
var currentYear = today.getFullYear();

for(var i = 1; i <= 100; i++)
{
    years.push({_id: currentYear - i, name: currentYear - i});
}

export { years };

const days = [];

for(i = 1; i <= 31; i++)
{
    days.push({_id: i, name: i});
}

export { days };