export const cancerlocation = [
    {_id: "N/A", name: ""},
    {_id:"Bladder", name:"Bladder"},
    {_id:"Brain", name:"Brain"},
    {_id:"Breast", name:"Breast"},
    {_id:"Bone", name:"Bone"},
    {_id:"Cervical", name:"Cervical"},
    {_id:"Colorectal", name:"Colorectal"},
    {_id:"Esophageal", name:"Esophageal"},
    {_id:"Gall bladder", name:"Gall bladder"},
    {_id:"Gastric", name:"Gastric"},
    {_id:"Head and neck", name:"Head and neck"},
    {_id:"Kidney", name:"Kidney"},
    {_id:"Lymphoma", name:"Lymphoma"},
    {_id:"Leukemia", name:"Leukemia"},
    {_id:"Liver", name:"Liver"},
    {_id:"Lung", name:"Lung"},
    {_id:"Melanoma", name:"Melanoma"},
    {_id:"Multiple myeloma", name:"Multiple myeloma"},
    {_id:"Ovarian", name:"Ovarian"},
    {_id:"Pancreatic", name:"Pancreatic"},
    {_id:"Sarcoma", name:"Sarcoma"},
    {_id:"Thyroid", name:"Thyroid"},
]

export const cancerstatus = [
    {_id: "N/A", name: ""},
    {_id:"Finished all main treatments less than 6 months ago", name:"Finished all main treatments less than 6 months ago"},
    {_id:"Finished all main treatments between 6 and 12 months ago", name:"Finished all main treatments between 6 and 12 months ago"},
    {_id:"Finished all main treatments 1-3 years ago", name:"Finished all main treatments 1-3 years ago"},
    {_id:"Finished all main treatments 4-5 years ago", name:"Finished all main treatments 4-5 years ago"},
    {_id:"One of the above, plus am still receiving a second-line treatment (e.g., hormone therapy)", name:"One of the above, plus am still receiving a second-line treatment (e.g., hormone therapy)"},
    {_id:"Other", name:"Other"},
]

export const binaryanswer = [
    {_id:"Yes", name:"Yes"},
    {_id:"No", name:"No"},
]

export const learnaboutus = [
    {_id:"Flyer", name:"Flyer"},
    {_id:"Facebook/social media", name:"Facebook/social media"},
    {_id:"News media", name:"News media"},
    {_id:"Word of mouth", name:"Word of mouth"},
    {_id:"Web search", name:"Web search"},
    {_id:"Event/conference/symposium", name:"Event/conference/symposium"},
    {_id:"Cancer support organization", name:"Cancer support organization"},
    {_id:"From my provieder (physician, nurse, nutritionist)", name:"From my provieder (physician, nurse, nutritionist)"},
    {_id:"Other", name:"Other"},
]