import React from 'react';
import { Container, Row } from 'reactstrap';
import styled from 'styled-components'

const ArchivedAccountNotice = () => {
    return ( 
        <React.Fragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage />
                </ParentDiv>   
            </HeightDiv>
            <StyledContainer>
                <Row>
                    <h1>Account has been Archived</h1>
                    <p>This account has been archived and is no longer available.
                        If you have any questions please direct them to: <a href="mailto:HIMALAYAStrial@gmail.com">HIMALAYAStrial@gmail.com</a>
                    </p>
                </Row>
            </StyledContainer>
        </React.Fragment>
     );
}
 
export default ArchivedAccountNotice;

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #9780af;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 700px;
    margin-top: 15px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #dddddd;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;
 