import React from 'react';
import styled from 'styled-components'
import { Container } from 'reactstrap';
import groupImage from "../../images/MorningRun.png"
//TODO You will need to check they are logged in and they are not verified
const EmailConfirm = (props) => {
    return (
        <React.Fragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage src={groupImage} alt="Keep at it!" />
                </ParentDiv>   
            </HeightDiv>
            <StyledContainer>
                <h1>Email Confirmation</h1>
                <br />
                <p>
                    An email confirmation has been sent to the address you've specified. Please follow the instructions there to continue the registration process.
                </p>
                <p>Please check your junk or spam folder for mail from donotreply@himalayastrial.ca</p>
            </StyledContainer>
        </React.Fragment>
    );
}

export default EmailConfirm;

const StyledContainer = styled(Container)`
    background-color: white;
    border-radius: 10px;
    border: solid;
    border-color: #9780af #372844 #372844 #9780af;
    margin: auto;
    box-shadow: 0 0.5rem 1rem #9780af;
    padding: 15px;
    max-width: 700px;
    margin-top: 50px;
    margin-bottom: 70px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;