import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Input, Label, Button } from 'reactstrap';
import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UploadImageToStorage } from '../../../services/firestore';
import ImageUploadAndCrop from '../../common/formComponents/imageUploadAndCrop';

const ContentListUpdate = (props) => {
    const [list, setList] = useState([]);
    const [urls, setUrls] = useState([]);
    const [files, setFiles] = useState([]);
    const [crop, setCrop] = useState({unit: '%', width: 30, aspect: 1/1,});

    useEffect(() => {
        setList([...props.objectList]);  
    }, [props.objectList]);

    const UpdatePrimaryText = (text, id, property) =>{
        var newText = list;
        newText[id][property] = text;
        setList([...newText]);
    }

    const UpdateImageUrl = (file, url, id) => {
        var newFiles = files;
        files[id] = file;
        setFiles([...newFiles]);

        var newUrls = urls;
        urls[id] = url;
        setUrls([...newUrls]);

        var newText = list;
        newText[id].imageUrl = url;
        setList([...newText]);
    }

    const DeleteEntry = (entry) => {
        var newText = list;
        newText.splice(entry, 1);
        setList([...newText]);
        props.returnDataCallback(newText);
    }

    const newEntry = {
        title: "",
        content: "",
        imageUrl: "",
        index: "",
    }

    const AddNewEntry = () =>{
        var newText = list;
        list.push(newEntry);
        setList([...newText]);
    }

    const TriggerSubmit = () => {
        props.returnDataCallback(list);
    }

    const saveImage = (id) => {
        UploadImageToStorage(files[id], "sitePages/home/" + props.contentName + "/", "imageNumber" + id, SuccessUpload, FailedUpload, id);
    }

    const FailedUpload = () => {

    }

    const SuccessUpload = (url, id) => {
        UpdatePrimaryText(url, id, "imageUrl");
        var newFileList = files;
        newFileList[id] = "";
        setFiles([...newFileList])
    }

    const RemoveImage = (id) => {
        var newList = list;
        newList[id].imageUrl = "";
        setList([...newList]);
      }

    return ( 
        <Container fluid={true}>    
        {list.map((entry, i) => (
            <div key={i}>
                <Row>
                    <Col lg="8">
                        <Label>Title:</Label>
                        <StyledInput  name="title" id="title" placeholder="Title..." value = {entry.title} onChange={e => UpdatePrimaryText(e.target.value, i, "title")}/>
                    </Col>
                    {props.hasImageOnList && <Col lg="2">
                        <Label>Index:</Label>
                        <StyledInput type="number" name="index" id="index" placeholder="Index..." value = {entry.index} onChange={e => UpdatePrimaryText(e.target.value, i, "index")}/>
                    </Col>}
                    <TrashButtonCol lg="2">
                        <StyledButton onClick={() => DeleteEntry(i)} color="danger">
                            <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                        </StyledButton> 
                    </TrashButtonCol>
                </Row>
                <Row>
                    <Col>
                        <Label>Content:</Label>
                        <br />
                        <StyledInput  name="content" id="content" type="textarea" placeholder="Content..." value = {entry.content} onChange={e => UpdatePrimaryText(e.target.value, i, "content")}/>
                    </Col>
                </Row>
                {props.hasUrlLink && <Row>
                    <Col>
                        <Label>URL:</Label>
                        <p>In order to open a "send email" link, just add: mailto: and then the email address</p>
                        <StyledInput  name="url" id="url" placeholder="URL..." value = {entry.url} onChange={e => UpdatePrimaryText(e.target.value, i, "url")}/>
                    </Col>
                </Row>}
            {(props.hasImageOnList && !entry.imageUrl) &&<Row><Col><ImageUploadAndCrop passOver={i} descriptionText="Choose Image" maxWidth="1500px" crop={crop} submitFunction={UpdateImageUrl} /></Col></Row>}
            {(props.hasImageOnList && entry.imageUrl) && <Row>
                    <Col><br />
                        <StyledProfileImage src={entry.imageUrl} alt="" />
                        <StyledButton onClick={() => RemoveImage(i)} color="danger">
                            <FAIconButton icon={["fas", "times"]}></FAIconButton>
                        </StyledButton>
                        {(entry.imageUrl && files[i]) && <StyledButton color="primary" onClick={() => {saveImage(i)}}>Save Image</StyledButton>} 
                    </Col>
                </Row>}
                <br />
                <hr />
                <br />
            </div>
            ))}
            <Row>
                <Col>
                    <StyledButton onClick={() => AddNewEntry()} color="primary">
                        <FAIconButton  icon={["fas", "plus"]}></FAIconButton> Add Entry
                    </StyledButton>
                </Col>
                <Col>
                    <StyledButton onClick={() => TriggerSubmit()} color="primary">
                                    <FAIconButton  icon={["fas", "check"]}></FAIconButton> Save Details
                    </StyledButton>
                </Col>
            </Row>
            <hr />
        </Container> );
}
 
export default ContentListUpdate;

const StyledInput = styled(Input)`
&:focus{
    border-color: #372844;
    box-shadow: 0 0.5rem 1rem #9780af;
}
`

const TrashButtonCol = styled(Col)`
    margin-top: 25px;
`

const StyledProfileImage = styled.img`
    padding: 5px;
    margin-right: 30px;
    box-shadow: 0 0.5rem 1rem #9780af;
    max-width: 250px;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 0px;
    margin-left: 0px;
    
`;

const StyledButton = styled(Button)`
    max-width: 175px;
    max-height: 35px;
    margin-left: 5px;
`;