import React, { useState, useEffect } from 'react';
import { listenToCollection } from '../../../services/firestore';
import { db } from '../../../services/firebase';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components'
import AdminStudyAdminUserProfileList from './admin-study-admin-user-profile-list';
import AdminNav from '../adminComponents/adminNav';

const AdminStudyAdminControl = () => {
    const [users, setUsers] = useState([])

    useEffect(() => {
        var unsubscribe = listenToCollection(db, "users", setUsers);
        return unsubscribe;
    }, []);

    function paginate (arr, size) {
        return arr.reduce((acc, val, i) => {
            let idx = Math.floor(i / size)
            let page = acc[idx] || (acc[idx] = [])
            page.push(val)
            return acc
        }, [])
    }
    
    return ( 
        <React.Fragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage />
                </ParentDiv>   
            </HeightDiv>
            <StyledContainer>
                <AdminNav />
                <Row>
                    <Col>
                        <h1>Staff List</h1>
                    </Col>
                </Row>
                <br />
                <Row>
                    <AdminStudyAdminUserProfileList users={users} />
                </Row>
            </StyledContainer>
        </React.Fragment>
    );
}
 
export default AdminStudyAdminControl;

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #372844;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 1200px;
    margin-top: 15px;
    margin-bottom: 80px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #dddddd;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;