import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { Event, UpdateUser, PageView } from "../../../utils/analytics";

const RouteWrapper = ({ path, title, user, component: Component, render, ...rest }) => {

  useEffect(() => {
    document.title = title;
    PageView(title, path);
  },[]);

  return (
    <Route
      {...rest}
      render={(props) => {  
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default RouteWrapper;