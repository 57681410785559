import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled, {keyframes} from 'styled-components';
import '../../styles/homePage.css';
import { db } from '../../services/firebase';
import { Carousel } from 'react-responsive-carousel';
import ProjectCarousel from '../common/carousel';
import { isBrowser } from 'react-device-detect';
import HomeCarousel from './home-carousel';
import MinHeightWrapper from '../common/min-height-wrapper';
import ColorRunImage from '../../images/ColorRun.png';
import HelpButtonImage from '../../images/HelpButton.png';
import GreyRunImage from '../../images/GreyRun.png';

const HomePage = (props) => {
    const [partnershipCarousel, setPartnershipCarousel] = useState([]);
    const [studyCarousel, setStudyCarousel] = useState([]);
    const [backgroundImageUrl, setBackgroundImageUrl] = useState("");

    const docRef = db.collection("webPages").doc("home")

    useEffect(() => {
        ReturnHomePageInformation();   
    }, []);

    const sortList = (list) => {
        list.sort(function(a, b) {
            var textA = parseInt(a.index);
            var textB = parseInt(b.index);
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        return list;
    }

    const ReturnHomePageInformation = () =>{
        docRef.onSnapshot((doc) => {
            setBackgroundImageUrl(doc.data().backgroundImageUrl);
            setPartnershipCarousel(sortList(doc.data().partnershipCarousel));
            setStudyCarousel(sortList(doc.data().studyAnnouncementCarousel));
        });
    }

    const OpenURLWindow = (url) => {
        if(!url){return;}
        window.open(url);
    }
    
    return (
        <StyledDiv>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage src={backgroundImageUrl} alt="Keep at it!" />
                </ParentDiv>   
            </HeightDiv>
            <MarginDiv>
                <MinHeightWrapper>
                    <TopContainer fluid={true}>
                        <Row>
                            <AcronymCol xs="12" lg="6">
                                <BulletPointContainer fluid={true}>
                                        <StyledAnchor href="/about-us">
                                            <BulletRow marginBottom="15px">
                                                <ImageCol xl="3" lg="4" xs="4" sm="3">
                                                    <CircleImage src={ColorRunImage} alt="HIMALAYAS" />
                                                </ImageCol>
                                                <BulletCol>
                                                    <h5>Learn more about the HIMALAYAS study & meet the team</h5>
                                                </BulletCol>                           
                                            </BulletRow>
                                        </StyledAnchor>
                                        <StyledAnchor href="/ethicsAndStudy">
                                            <BulletRow>
                                                <ImageCol xl="3" lg="4" xs="4" sm="3">
                                                    <CircleImage src={HelpButtonImage} alt="HIMALAYAS" />
                                                </ImageCol>
                                                <BulletCol>
                                                    <h5>Question about the study?</h5>
                                                    <h5>Contact your local study team.</h5>
                                                </BulletCol>                           
                                            </BulletRow>
                                        </StyledAnchor>
                                        <StyledAnchor href="login">
                                            <BulletRow marginTop="15px">
                                                <ImageCol xl="3" lg="4" xs="4" sm="3">
                                                    <CircleImage src={GreyRunImage} alt="HIMALAYAS" />
                                                </ImageCol>
                                                <BulletCol>
                                                    <h5>Member of the CORE group?</h5>
                                                    <h5>Log in & get moving!</h5>
                                                </BulletCol>                           
                                            </BulletRow>
                                        </StyledAnchor>
                                </BulletPointContainer>
                            </AcronymCol>
                            <RightSlidingContentCol delay={0} widthmin = "600px">
                                    <StyledCarousel showThumbs={false} autoPlay={true} infiniteLoop={true} interval={6000} showStatus={false}>
                                    {studyCarousel.length > 0 && studyCarousel.map((entry, i) => (
                                        <CarouselItem key={i} onClick={() => OpenURLWindow(entry.url)}>
                                            {entry.imageUrl && <img src={entry.imageUrl} alt={entry.content}/>}
                                            <StyledTitle>{entry.title}</StyledTitle>
                                            {entry.content && <StyledContent>{entry.content}</StyledContent>}
                                            <br />
                                        </CarouselItem>
                                    ))}  
                                </StyledCarousel>
                            </RightSlidingContentCol>
                        </Row>
                    </TopContainer>
                </MinHeightWrapper>
                <ContentContainer browser={isBrowser}>
                    <Row>
                        <Col>
                            <h1>Study Collaborators, Funding & Sponsors</h1>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <HomeCarousel items={partnershipCarousel} />
                        </Col>
                    </Row>
                </ContentContainer>
            </MarginDiv>
        </StyledDiv>
    );
}

export default HomePage;

const MarginDiv = styled.div`
    margin-left: 15px;
    margin-right: 15px;
`

const AcronymCol = styled(Col)`
    margin-bottom: 25px;
`

const BulletPointContainer = styled(Container)`
display: flex;
flex-direction: column;
justify-content: space-evenly;
height: 100%;
padding-right: 0px;
padding-left: 0px;
`

const BulletRow = styled(Row)`
background-color: rgba(255, 255, 255, .8);
border: solid;
box-shadow: 0 0.5rem 1rem #372844;
border-width: 2px;
justify-content: center;
margin: auto;
border-radius: 75px;
padding: 15px;
max-width: 550px;
margin-top: ${props => props.marginTop ? props.marginTop : ""};
margin-bottom: ${props => props.marginBottom ? props.marginBottom : ""};
`

const BulletCol = styled(Col)`
    margin: auto;
`

const ImageCol = styled(Col)`
display: grid;

`

const CarouselItem = styled.div`
    &:hover {
        cursor: pointer;
    }
`

const StyledAnchor = styled.a`
    text-decoration: none;
    color: black;
`

const CircleImage = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50px;
    border-style: solid;
    background-color: white;
    border-color: #cfbc81;
`

const StyledDiv = styled.div`
    margin-bottom: 0px;
    padding-bottom: 0px;
`

const StyledCarousel = styled(Carousel)`
  max-height: auto;
  background-color: #372844;
  color: white;
  margin-bottom: -20px;
`

const StyledTitle = styled.h4`
    padding-top: 10px;
`

const StyledContent = styled.p`
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
`

const TopContainer = styled(Container)`
    margin-top: 30px;
`

const ContentContainer = styled(Container)`
    background-color: white;
    border: solid;
    box-shadow: 0 0.5rem 1rem #372844;
    justify-content: center;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 100px;
    padding: 20px;
    padding-bottom: 30px;
    border-radius: 15px;
`

const slideInRightAnimation = keyframes`
    from {
        transform: translateX(1000%);
    }
    to {
        transform: translateX(0px);
    }
`;

const RightSlidingContentCol = styled(Col)`
    border-radius: 15px;
    background-color: white;
    border: solid;
    box-shadow: 0 0.5rem 1rem #372844;
    max-width: ${props => props.widthmin};
    justify-content: center;
    margin: auto;
    margin-top: 25px;
    padding: 10px;
    padding-bottom: 30px;
    animation: ${slideInRightAnimation} 2s ${props => props.delay + "s"} both;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;