import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Input, Button, Label, Alert, FormFeedback, FormGroup, Form } from 'reactstrap';
import styled from 'styled-components';
import groupImage from "../../images/MorningRun.png";
import { updateDocumentProperty, listenToDocument } from '../../services/firestore';
import { db, auth } from '../../services/firebase';
import FirstTimePassword from './first-time-password';
import FirstTimeProfileInformation from './first-time-profile-information';
import FirstTimeCancerHistory from './first-time-profile-cancer-history';

const FirstTimeContainer = (props) => {

    const [user, setUser] = useState(null);

    useEffect(() => {
        console.log(auth.currentUser.uid);
        return listenToDocument(db, "users", auth.currentUser.uid, setUser)
    }, [])

    const returnPage = () => {
        if (user === null) return null;
        if(user.passwordHasChanged && user.firstPageComplete){
            return <FirstTimeCancerHistory user={user} />
        }
        else if (user.passwordHasChanged){
            return <FirstTimeProfileInformation user={user} />
        }
        else{
            return <FirstTimePassword user={user} />
        }
    }

    return (
        <StyledFragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage src={groupImage} alt="Keep at it!" />
                </ParentDiv>   
            </HeightDiv>
            {returnPage()}
        </StyledFragment>
    );
}

export default FirstTimeContainer;

const StyledP = styled.p`
    margin: 0px;
`;

const BoldP = styled.p`
    font-weight: bold;
    font-size: 16px;
    color: #372844;
    margin-top: 0px;
    margin-bottom: 0px;
`;

const ExplainingP = styled.p`
    font-size: 14px;
    color: #372844;
    margin-top: 0px;
    margin-bottom: 0px;
    font-style: italic;
`;

const RedText = styled.b`
    color:Tomato;
`

const StyledInput = styled(Input)`
    margin-bottom: 10px;
    &:focus{
        border-color: #372844;
        box-shadow: 0 0.5rem 1rem #9780af;
    }
`

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #9780af;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 500px;
    margin-top: 15px;
`

const StyledFragment = styled.div`
    height: 100%;
    min-height: ${window.innerHeight - 255}px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;
 