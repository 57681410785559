import React from 'react';
import { Container, Row, Button } from 'reactstrap';
import AdminAdminUserProfile from './admin-study-admin-user-profile';
import styled from 'styled-components';

const AdminStudyAdminUserProfileList = ({ users }) => {
    const FilterUsers = (users) => {
        return users.filter(user => user.isStaff || user.isAdmin || user.isRegionalAdmin || user.isStudyAdmin || user.isSiteAdmin);
    }
    
    const MapFilteredUsers = (users) => {
        let fiilteredUsers = FilterUsers(users);
        return fiilteredUsers.map((user, i) => (
            <AdminAdminUserProfile key={user.uid} user={user} />
            ))
        }
        
    //We used to have a pagination system here, but it was removed because it was not needed. Pagination was passed from the parent.
    
    /*
        const [currentListSpot, setCurrentListSpot] = useState(0);
    
        const SetPage = (number) => {
            setCurrentListSpot(number);
        }
    */
        
    /*
    const ReturnNavigation = (users) => {
        let fiilteredUsers = FilterUsers(users);
        return (
            <Row>
                {fiilteredUsers.length > 1 && <Col> 
                    <StyledButton disabled={currentListSpot === 0} onClick={() => SetPage(0)}>First</StyledButton>
                {currentListSpot > 1 && <StyledButton  onClick={() => SetPage(currentListSpot - 2)}>{currentListSpot - 1}</StyledButton>}
                {currentListSpot > 0 && <StyledButton  onClick={() => SetPage(currentListSpot - 1)}>{currentListSpot}</StyledButton>}
                    <StyledButton color="primary" onClick={() => SetPage(currentListSpot)}>{currentListSpot + 1}</StyledButton>
                {currentListSpot < fiilteredUsers.length - 1 && <StyledButton  onClick={() => SetPage(currentListSpot + 1)}>{currentListSpot + 2}</StyledButton>}
                {currentListSpot < fiilteredUsers.length - 2 && <StyledButton  onClick={() => SetPage(currentListSpot + 2)}>{currentListSpot + 3}</StyledButton>}
                    <StyledButton disabled={!(currentListSpot < fiilteredUsers.length - 1)} onClick={() => SetPage(fiilteredUsers.length - 1)}>Last</StyledButton>
                </Col>}
            </Row>
        )
    }
    */

    return ( 
        <Container>
            <Row>
                {MapFilteredUsers(users)}
            </Row>
        </Container>
    );
}
 
export default AdminStudyAdminUserProfileList;

const StyledButton = styled(Button)`
    margin-left: 2px;
    margin-right: 3px;
`

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #372844;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 1200px;
    margin-top: 15px;
    margin-bottom: 80px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #dddddd;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;