import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, ModalHeader, ModalBody, ModalFooter, Modal, Input } from 'reactstrap';
import '../../styles/table.css';
import styled from 'styled-components'
import YoutubeEmbed from '../common/youTubeEmbed';
import { UpdateUserInformation, GetUserInformation } from '../../services/firestore';
import { auth } from '../../services/firebase';
import { moduleStrings } from './moduleStrings';
import ModuleButton from './moduleButton';
import firebase from 'firebase';
import { OpenTicket } from '../../services/firestore';
import { TicketStrings } from '../adminPages/tickets/ticketStrings';
import Fireworks from '../common/fireworks';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TeamWorkImage from "../../images/TeamWork.png";
import { db } from '../../services/firebase';

const TopicSpecific = () => {
    const [fireworks, setFireworks] = useState(false);
    const [userProfileId] = useState(auth.currentUser.uid);
    const [user, setUser] = useState();
    const [moduleToComplete, setModuleToComplete] = useState("");
    const [reportedFeedback, setReportedFeedback] = useState(true);
    const [completedModule, setCompletedModule] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [feedback, setFeedback] = useState("");
    const toggle = () => setModal(!modal);

    const [exerciseBenefitsVideoURL, setExerciseBenefitsVideoURL] = useState("");
    const [exercisePrescriptionVideoURL, setExercisePrescriptionVideoURL] = useState("");
    const [exerciseBarriersVideoURL, setExerciseBarriersVideoURL] = useState("");
    
    const docRef = db.collection("webPages").doc("Core")

    useEffect(() => {
        ReturnPageInformation();   
    }, []);

    const ReturnPageInformation = () =>{
        docRef.onSnapshot((doc) => {
            setExerciseBenefitsVideoURL(doc.data().exerciseBenefitsVideoURL);
            setExercisePrescriptionVideoURL(doc.data().exercisePrescriptionVideoURL);
            setExerciseBarriersVideoURL(doc.data().exerciseBarriersVideoURL);
        });
    }

    useEffect(() => {
      GetUserInformation(userProfileId, SetUserInfo);
    }, []);

    const SetUserInfo = (user) =>{
        if(user.modules === undefined){
            user.modules = [];
        }
        setUser(user);
    }

    const StartModule = (moduleName) => {
        var newUserInformation = user;
        var moduleObject = {
            moduleName: moduleName, 
            timeStarted: firebase.firestore.Timestamp.fromDate(new Date()),
            timeCompleted: "",
            completed: false,
        }

        if(newUserInformation.modules === undefined){
            var modules = [];
            modules.push(moduleObject);
            newUserInformation.modules = modules;
        }
        else{
            var modules = newUserInformation.modules;
            for(var i = 0; i < modules.length; i++){
                if(modules[i].moduleName === moduleObject.moduleName){
                    return;
                }
            }
            modules.push(moduleObject);
            newUserInformation.modules = modules;
        }
        UpdateUserInformation(userProfileId, newUserInformation);
    }

    const CompleteModule = (moduleName) => {
        setCompletedModule(false);
        setReportedFeedback(true);
        setModuleToComplete(moduleName);
        setModalTitle("Complete Module?");
        setModalMessage("Please make sure you have read through the full module before completing");
        toggle(true);
    }

    const ConfirmModuleCompletion = () => {
        var newProperties = user;
        for(let i = 0; i < newProperties.modules.length; i++){
            if(newProperties.modules[i].moduleName === moduleToComplete){
                newProperties.modules[i].completed = true;
                newProperties.modules[i].timeCompleted = firebase.firestore.Timestamp.fromDate(new Date());
            }
        }
        UpdateUserInformation(user.uid, newProperties, ShowModuleFeedback);
    }

    const ShowModuleFeedback = (success) => {
        if(success){
            //Fireworks here?
            setCompletedModule(true);
            setReportedFeedback(false);
            setModalTitle("Module Completed!");
            setModalMessage("Congratulations on completing your module, please leave some feedback on the module");
            setFireworks(true);
            setTimeout(function(){setFireworks(false);}, 5000);
        }
        else{
            setModalTitle("Something went wrong");
            setModalMessage("Something happened! Please contact an admin");
        }
    }

    const SendFeedback = () =>{  
        OpenTicket(user.username, user.uid, user.email, TicketStrings.modulefeedback, feedback, false, FollowUpModuleFeedback);
    }

    const FollowUpModuleFeedback = (success) => {
        setReportedFeedback(true);
        if(success){
            setModalTitle("Feedback Received");
            setModalMessage("Thanks for your feedback!");
        }
        else{
            setModalTitle("Unable to submit feedback");
            setModalMessage("Something went wrong!");
        }
    }

    return (
        <React.Fragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage />
                </ParentDiv>   
            </HeightDiv>
            <StyledModal isOpen={modal}>
            {fireworks && <Fireworks />}
                <ModalHeader>{modalTitle}</ModalHeader>
                <ModalBody>
                    {modalMessage}
                    {!reportedFeedback && <hr />}
                    {!reportedFeedback && <StyledInput type="textarea" name="feedback" id="feedback" onChange={e => setFeedback(e.target.value)}/>}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Close</Button>
                    {!completedModule && <Button color="primary" onClick={ConfirmModuleCompletion}>Complete</Button>}
                    {(!reportedFeedback && completedModule) && <Button color="primary" onClick={SendFeedback}>Submit Feedback</Button>}
                </ModalFooter>
            </StyledModal>
            <MarginDiv>
                <StyledContainer>
                    <Row>
                        <Col>
                            <RightMarginButton color="primary" href="/exercise-groups">Back to exercise groups Page</RightMarginButton>
                            <Button color="primary" href="/exercise-groups/modules-monthly">Monthly Modules</Button>
                            
                            <h3><br />Topic-Specific Support Modules</h3>
                            <StyledParagraph>
                                Have a specific question / issue or need a refresher on a specific topic? 
                                Check out the individual support modules to get the answers you need now.
                            </StyledParagraph>
                            <h4>Accessing & completing behavioural support modules</h4>
                            <h5>
                                <StyledButton color="primary">
                                    <FAIconButton  icon={["fas", "download"]}></FAIconButton>
                                </StyledButton>
                                Step One
                            </h5>

                            <StyledParagraph>Press the download button on the modules you are interested in starting, this will start the module for your profile.</StyledParagraph>
                            <h5>
                                <CompleteModuleButton color="primary">
                                    <FAIconButton  icon={["fas", "check"]}></FAIconButton>
                                </CompleteModuleButton>
                                Step Two
                            </h5>

                            <StyledParagraph>
                                Once you have completed the module you will be able to select the green checkmark to verify you have completed the module on your profile.
                            </StyledParagraph>
                            <StarIcon icon={["fas", "star"]}></StarIcon>
                            <StyledParagraph>Indicates you have completed the module.</StyledParagraph>
                        </Col>
                        <VideoCol lg="6">
                            {/* <YoutubeEmbed embedId="dQw4w9WgXcQ" /> */}
                            <PlaceholderImage src={TeamWorkImage} />
                        </VideoCol>
                    </Row>
                    <h3><br /><u>Topic-Specific Support Modules</u></h3>
                    <Row>
                    <br/><h5>EDUCATION MODULES</h5>
                            <hr />
                        {exerciseBenefitsVideoURL !== "" && <VideoCol lg="6">
                            <YoutubeEmbed embedId={exerciseBenefitsVideoURL} />
                        </VideoCol>}
                        <Col>

                            <Row>
                                <Col lg="6">
                                    <ModuleButton 
                                    user={user} 
                                    modulestring={moduleStrings.ExerciseBenefits} 
                                    StartFunction={StartModule} 
                                    CompleteFunction={CompleteModule} 
                                    file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/topic-specific-modules/Exercise-Benefits.pdf'
                                    />

                                    <ul>
                                        <StyledListItem>Exercise benefits for AYAs</StyledListItem>
                                        <StyledListItem>Mental & emotional wellbeing</StyledListItem>
                                        <StyledListItem>Choosing the right type of exercise</StyledListItem>
                                        <StyledListItem>Social benefits</StyledListItem>
                                        <StyledListItem>Physical function</StyledListItem>
                                        <StyledListItem>Fatigue</StyledListItem>
                                    </ul>
                                </Col>
                                <Col lg="6">
                                    <ModuleButton 
                                    user={user} 
                                    modulestring={moduleStrings.CVD} 
                                    StartFunction={StartModule} 
                                    CompleteFunction={CompleteModule} 
                                    file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/topic-specific-modules/CVD.pdf' 
                                    />
                                    
                                    <ul>
                                        <StyledListItem>What is CVD?</StyledListItem>
                                        <StyledListItem>Cardiotoxic cancer treatments</StyledListItem>
                                        <StyledListItem>Modifiable risk factors</StyledListItem>
                                        <ul>
                                            <StyledListItem>Tobacco & alcohol</StyledListItem>
                                            <StyledListItem>Nutrition</StyledListItem>
                                            <StyledListItem>Physical inactivity</StyledListItem>
                                            <StyledListItem>Physical activity</StyledListItem>
                                        </ul>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                    <br /><h5>EXERCISE PRESCRIPTION</h5>
                    <hr />
                        {exercisePrescriptionVideoURL !== "" && <VideoCol lg="6">
                            <YoutubeEmbed embedId={exercisePrescriptionVideoURL} /> 
                        </VideoCol>}
                        <Col lg="6">

                            <ModuleButton 
                            user={user} 
                            modulestring={moduleStrings.ExercisePrescription} 
                            StartFunction={StartModule} 
                            CompleteFunction={CompleteModule} 
                            file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/topic-specific-modules/Exercise-Prescription.pdf'
                            />
                            <ul>
                                <StyledListItem><h6>Introduction to Exercise</h6></StyledListItem>
                                <ul>
                                    <StyledListItem>What is physical activity vs. exercise?</StyledListItem>
                                    <StyledListItem>What is physical fitness?</StyledListItem>
                                    <StyledListItem>Exercise session breakdown</StyledListItem>
                                </ul>
                                <StyledListItem><h6>Types of Exercise</h6></StyledListItem>
                                <ul>
                                    <StyledListItem>Aerobic vs. Resistance exercise</StyledListItem>
                                    <StyledListItem>Exercise quantity</StyledListItem>
                                </ul>
                                <StyledListItem><h6>Exercise Prescription</h6></StyledListItem>
                                <ul>
                                    <StyledListItem>FITT Principle</StyledListItem>
                                    <ul>
                                        <StyledListItem>Frequency</StyledListItem>
                                        <StyledListItem>Intensity</StyledListItem>
                                        <StyledListItem>Time</StyledListItem>
                                        <StyledListItem>Type</StyledListItem>
                                    </ul>
                                    <StyledListItem>Exercise Progression & Recovery</StyledListItem>
                                </ul>
                            </ul>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <br /><h5>BEHAVIOURAL SUPPORT INFORMATION & TOOLS</h5>
                        <hr />
                        {exerciseBarriersVideoURL !== "" && <VideoCol lg="6">
                            <YoutubeEmbed embedId={exerciseBarriersVideoURL} />
                        </VideoCol>}
                        <Col lg="3">
                            <ModuleButton 
                            user={user} 
                            modulestring={moduleStrings.StayingActive} 
                            StartFunction={StartModule} 
                            CompleteFunction={CompleteModule} 
                            file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/topic-specific-modules/Getting-and-Staying-Active.pdf'
                            />
                            <br />
                            <ModuleButton 
                            user={user} 
                            modulestring={moduleStrings.DecisionBalance} 
                            StartFunction={StartModule} 
                            CompleteFunction={CompleteModule} 
                            file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/topic-specific-modules/Decision-Balance.pdf'
                            />
                            <br />
                            <ModuleButton 
                            user={user} 
                            modulestring={moduleStrings.ActivityEnvironment} 
                            StartFunction={StartModule} 
                            CompleteFunction={CompleteModule} 
                            file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/topic-specific-modules/Activity-Environment.pdf' 
                            />
                            <br />
                            <ModuleButton 
                            user={user} 
                            modulestring={moduleStrings.Fun} 
                            StartFunction={StartModule} 
                            CompleteFunction={CompleteModule} 
                            file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/topic-specific-modules/Fun-with-Physical-Activity.pdf'
                            />
                            <br />
                            <ModuleButton 
                            user={user} 
                            modulestring={moduleStrings.GoalSetting} 
                            StartFunction={StartModule} 
                            CompleteFunction={CompleteModule} 
                            file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/topic-specific-modules/Goal-Setting.pdf'
                            />
                            <br />
                            <ModuleButton 
                            user={user} 
                            modulestring={moduleStrings.Motivation} 
                            StartFunction={StartModule} 
                            CompleteFunction={CompleteModule} 
                            file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/topic-specific-modules/Motivation-Regulation.pdf'
                            />
                            <br />
                        </Col>
                        <Col lg="3">
                            <ModuleButton 
                            user={user} 
                            modulestring={moduleStrings.SelfMonitoring} 
                            StartFunction={StartModule} 
                            CompleteFunction={CompleteModule} 
                            file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/topic-specific-modules/Self-Monitoring.pdf' 
                            />
                            <br />
                            <ModuleButton 
                            user={user} 
                            modulestring={moduleStrings.SelfTalk} 
                            StartFunction={StartModule} 
                            CompleteFunction={CompleteModule} 
                            file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/topic-specific-modules/Self-Talk.pdf'
                            />
                            <br />
                            <ModuleButton 
                            user={user} 
                            modulestring={moduleStrings.SocialSupport} 
                            StartFunction={StartModule} 
                            CompleteFunction={CompleteModule} 
                            file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/topic-specific-modules/Social-Support.pdf' 
                            />
                            <br />
                            <ModuleButton 
                            user={user} 
                            modulestring={moduleStrings.StimulusControl} 
                            StartFunction={StartModule} 
                            CompleteFunction={CompleteModule} 
                            file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/topic-specific-modules/Stimulus-Control.pdf'
                            />
                            <br />
                            <ModuleButton 
                            user={user} 
                            modulestring={moduleStrings.TimeManagement} 
                            StartFunction={StartModule} 
                            CompleteFunction={CompleteModule} 
                            file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/topic-specific-modules/Time-Management.pdf'
                            />
                            <br />
                        </Col>
                    </Row>
                </StyledContainer>
            </MarginDiv>
        </React.Fragment>
    );
}
    
export default TopicSpecific;

const MarginDiv = styled.div`
    margin-left: 15px;
    margin-right: 15px;
`

const PlaceholderImage = styled.img`
    width: 100%;
`

const RightMarginButton = styled(Button)`
    margin-right: 25px;
`

const StyledParagraph = styled.p`
    font-size: large;
`

const StyledListItem = styled.li`
    font-size: large;
`

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #372844;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 10px;
    max-width: 1500px;
    margin-top: 15px;
    margin-bottom: 90px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #dddddd;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;
 
const VideoCol = styled(Col)`
margin-bottom: 30px;
`

const StyledModal = styled(Modal)`
    position: absolute;
    left: 30%;
    top: 40%;
    min-width: 30%;
`

const StyledInput = styled(Input)`
margin-left: 10px;
border-color: black;
&:focus{
    border-color: #372844;
    box-shadow: 0 0.5rem 1rem #9780af;
}
`

const CompleteModuleButton = styled(Button)`
    min-width: 40px;
    min-height: 35px;
    margin-left: 5px;
    background-color: red;
    &:hover{
        background-color: green;
    }
    margin-right: 10px;
`;

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 0px;
    margin-left: 0px;
`;

const StarIcon = styled(FontAwesomeIcon)`
    margin-right: 0px;
    margin-left: 15px;
    color: gold;
`;

const StyledButton = styled(Button)`
    min-width: 40px;
    min-height: 35px;
    margin-left: 5px;
    margin-right: 10px;
`;