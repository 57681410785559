import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { db } from '../../../services/firebase';
import styled from 'styled-components';

const Ticket = () => {
    const [ticketInfo, setTicketInfo] = useState("");
    const [ticketId] = useState(useParams().id);
    const [modal, setModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalMessage, setModalMessage] = useState("");

    const toggle = () => setModal(!modal);

    useEffect(() => {
        getTicketInformation();
      }, []);

    const getTicketInformation = () => {

        const ticketRef = db.collection("tickets").doc(ticketId);

        ticketRef.get().then((doc) => {
            if (!doc.exists) {
               window.location = "/not-found";
            } else {
                var ticket = doc.data();
                ticket.id = doc.id;
                setTicketInfo(ticket);
            }
        }).catch((error) => {
            console.log("Could not find ticket", error);
        });
    }

    function convertUTCDateToLocalDate(date) {
        var newDate = new Date(date.getTime());

        var currentDate = Date.now();
        currentDate = new Date(currentDate);

        var offset = currentDate.getTimezoneOffset() / 60;
        var hours = date.getHours();
    
        newDate.setHours(hours - offset);
    
        return newDate;   
    }

    const ResolveTicketPrompt = () =>{
        setModalTitle("Resolve Ticket?");
        setModalMessage("");
        toggle();
    }

    const DeleteTicket = () => {
        db.collection("tickets").doc(ticketId).delete().then(function(){
            window.location = "/tickets"
        })
    }

    const ResolveTicket = () =>{
        var newTicketInfo = ticketInfo;
        ticketInfo.resolved = true;

        db.collection("tickets").doc(ticketId).update(newTicketInfo).then(function() {
            setModalTitle("Resolve Ticket?");
            setModalMessage("Ticket Resolved");
        })
        .catch(function(error) {
            console.error("Error writing document: ", error);
        });
    }

    return ( <React.Fragment>
        <HeightDiv>
            <ParentDiv>
                <StyledBackgroundImage />
            </ParentDiv>   
        </HeightDiv>
    
        <br />
        <StyledModal isOpen={modal}>
            <ModalHeader>{modalTitle}</ModalHeader>
            <ModalBody>
                {modalMessage}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Cancel</Button>
                {!ticketInfo.resolved && <Button color="primary" onClick={ResolveTicket}>Resolve</Button>}
            </ModalFooter>
        </StyledModal>
        {ticketInfo && <StyledContainer>
            
            <Row>
                <Col xs= "1"><Button color="secondary" href="/tickets">Back</Button></Col>
                <Col xs="6"><u><h2>Ticket Number: {ticketInfo.id}</h2></u></Col>
            </Row>
            <Row>
                <Col><h3>Username: {ticketInfo.username}</h3></Col>
                {ticketInfo.email && <h3>{ticketInfo.email}</h3>}
                <Col><h3>Resolved: {ticketInfo.resolved.toString()}</h3></Col>
            </Row>
            <br />
            <Row>
                <Col xs="9">
                    <h4>Description:</h4>
                    <p>{ticketInfo.context}</p>
                </Col>
                {!ticketInfo.resolved && <Col xs="3">
                    <Button type="button" color="warning" onClick={ResolveTicketPrompt}>Resolve</Button>
                </Col>}
                {ticketInfo.resolved && <Col xs="3">
                    <Button type="button" color="danger" onClick={DeleteTicket}>Delete</Button>
                </Col>}
            </Row>
            <Row>
            {ticketInfo.messagehistory && <MessageWindow browser={true} id="chatWindow">
                {ticketInfo.messagehistory && ticketInfo.messagehistory.map((message, i) => {
                    var time = new Date(1970, 0, 1);
                    time.setSeconds(message.timestamp.seconds);
                    time = convertUTCDateToLocalDate(time);

                    return(<React.Fragment key={i}><br />
                        {message.sender !== ticketInfo.userId && <RecipientsMessageStyle key={i}>
                                <StyledText>
                                    {message.recipientname && <u>{message.recipientname}</u>} 
                                    <br />
                                    {message.contents}
                                </StyledText>
                                <LeftTimeBox>
                                    {time.toLocaleString()}
                                </LeftTimeBox>
                            </RecipientsMessageStyle>} 
                            {message.sender === ticketInfo.userId && <SendersMessageStyle key={i}>
                                <StyledText>
                                    {message.sendername && <u>{message.sendername}</u>}
                                    <br />
                                    {message.contents}
                                </StyledText>
                                <RightTimeBox>
                                    {time.toLocaleString()}
                                </RightTimeBox>
                            </SendersMessageStyle>}
                        </React.Fragment>)
                    })} 
                </MessageWindow>}
            </Row>
        </StyledContainer>}
    
</React.Fragment> );
}
 
export default Ticket;

const StyledText = styled.p`
    color: white;
    padding: 3px;
    align-items: center;
    font-family: Segoe UI;
    font-size: 20px;
`

const SendersMessageStyle = styled(Container)`
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: 0 0.2rem .5rem #372844;
    background-color: #9780af;
    width: fit-content;
    max-width: 70%;
    margin-right: 5px;
`

const RecipientsMessageStyle = styled(Container)`
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: 0 0.2rem .5rem black;
    background-color: #808080;
    width: fit-content;
    max-width: 70%;
    margin-left: 5px;
`

const MessageWindow = styled.div`
    overflow: scroll;
    overflow-x: hidden;
    height: ${props => props.browser ?  "550px" : "460px"};
    width: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    padding-bottom: 20px;
`

const StyledModal = styled(Modal)`
    position: absolute;
    left: 40%;
    top: 40%;
`

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #372844;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 1500px;
    margin-top: 15px;
    margin-bottom: 90px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #9780af;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

const RightTimeBox = styled.span`
    color: #999;
    font-size: 15px;
    color: white;
`

const LeftTimeBox = styled.span`
    color: white;
    font-size: 15px;
    margin-left: 00px;
`