import React, { useState } from 'react';
import styled from 'styled-components';
import { Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PopUpText = (props) => {
    const [open, setOpen] = useState(false);

    const toggle = () =>{
        setOpen(!open);
    }

    const identifier = props.text.replace(/[^A-Z0-9]/ig, "");

   return (<React.Fragment>
        <FAIconButton id={identifier} icon={["fas", "question-circle"]}></FAIconButton>
            <Tooltip placement="right" isOpen={open} target={identifier} toggle={toggle}>
                {props.text}
            </Tooltip>
        </React.Fragment>
        );
}
export default PopUpText;

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 0px;
    margin-left: 0px;
    max-width: 15px;
    color: grey;
`;