import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Label, Input, Button, FormFeedback } from 'reactstrap';
import * as aboutme from "../../libraries/aboutme"
import { auth, db } from '../../services/firebase';
import { UpdateUserInformation } from '../../services/firestore';
import groupImage from "../../images/MorningRun.png"
import CheckBox from '../common/formComponents/checkBox';
import styled from 'styled-components'
import { days, years, months } from '../../libraries/dates';
import { GetLongituteAndLatitute } from '../../services/helper';
import PopUpText from '../common/popUpText';

const AboutMe = (props) => {
     const [activities, setActivities] = useState([]);
     const [otherfavorites, setOtherFavorites] = useState("");
     const [fitnesslevel, setFitnessLevel] = useState("");
     const [activereason, setActiveReason] = useState([]);
     const [fitnesslocation, setFitnessLocation] = useState([]);
     const [fitnesstime, setFitnessTime] = useState([]);
     const [reason, setReason] = useState("");
     const [personality, setPersonality] = useState("");
     const [worksituation, setWorkSituation] = useState("");
     const [aboutuser, setAboutUser] = useState("");
     const [errorMessage, setErrorMessage] = useState("");

     const [day, setDay] = useState(days[0]._id );
     const [month, setMonth] = useState(months[0]._id);
     const [year, setYear] = useState(years[0]._id);
     const [city, setCity] = useState({value: "", validity: "empty"});
     const [cities, setCities] = useState([]);
     const [postalCode, setPostalCode] = useState({value: "", validity: "empty"});
     const [validAge, setValidAge] = useState("");
    
    const [testNumber, setTestNumber] = useState(0);

     useEffect(() => {          
            checkIfValidEmail();
       }, []);

       
     const docRef = db.collection("webPages").doc("global")

     useEffect(() => {
        ReturnGlobalPageInformation();   
    }, []);

    
    const ReturnGlobalPageInformation = () =>{
        docRef.onSnapshot((doc) => {
            const data = doc.data();
            if(doc.data()){
                if(data.primaryText){
                    setCities(data.primaryText);
                    validateCity(data.primaryText[0].name);
                }
            }
        });
    }

       const validateCity = (value) => {
        if(value === ""){
            setCity({value: value, validity: "empty"})
        }
        else{
            setCity({value: value, validity: "valid"})
        }
    }

    const validatePostalCode = (value) => {
        const re = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
        if(re.test(String(value).toLowerCase())){
            setPostalCode({value: value, validity: "valid"});
        }
        else if(value === ""){
            setPostalCode({value: value, validity: "empty"});
        }
        else{
            setPostalCode({value: value, validity: "invalid"});
        }
    }

        
    const validateDay = (value) => {
        setDay(value);
        validateAge(value, "day");
    }

    const validateMonth = (value) => {
        setMonth(value);
        validateAge(value, "month");
    }

    const validateYear = (value) => {
        setYear(value);
        validateAge(value, "year");
    }

    const validateAge = (value, property) =>{
        var testYear = year;
        var testMonth = month;
        var testDay = day;
        
        if(property === "year"){
            testYear  = value;
        }
        else if(property === "month"){
            testMonth = value;
        }
        else{
            testDay = value;
        }
        
        var dob = new Date();
        dob.setFullYear(testYear, testMonth, testDay);
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms);
        
        var age = Math.abs(age_dt.getUTCFullYear() - 1970);

        if(age < 18){
            setValidAge("invalid");
        }
        else{
            setValidAge("valid");
        }
    }
     
    const checkIfValidEmail = () => {
        if(props.location.state !== undefined){
            var actionCode = props.location.state.actionCode;
            auth.applyActionCode(actionCode).then((resp) => {
                UpdateUserInformation(auth.currentUser.uid, { emailVerified: true })
                auth.currentUser.reload();
            }).catch((error) => {
                console.log(error);
        });
        }
    }

     const HandleActivitiesCheckbox = (value) =>{
        var activitiesArray = activities;

        const match = activitiesArray.find(element => {
            if (element.includes(value)) {
              return true;
            }
          });

        if(!match){
            activitiesArray.push(value);
        }
        else{
            for(let i = 0; i < activitiesArray.length; i++){
                if(activitiesArray[i] === value){
                    activitiesArray.splice(i, 1);
                }
            }
        }

        setActivities(activitiesArray);
        setTestNumber(testNumber + 1);
     }

     const HandleReasonsCheckbox = (value) => {
         var reasonsArray = activereason;

         
        const match = reasonsArray.find(element => {
            if (element.includes(value)) {
              return true;
            }
          });

         if(!match){
             if(reasonsArray.length < 3){
                reasonsArray.push(value);
              }
         }
         else{
             for(let i = 0; i < reasonsArray.length; i++){
                 if(reasonsArray[i] === value){
                     reasonsArray.splice(i, 1);
                 }
             }
         }

         setActiveReason(reasonsArray);
         setTestNumber(testNumber + 1);
     }

     const HandleFitnessLocationCheckbox = (value) => {
        var reasonsArray = fitnesslocation;

        
       const match = reasonsArray.find(element => {
           if (element.includes(value)) {
             return true;
           }
         });

        if(!match){
            reasonsArray.push(value);
        }
        else{
            for(let i = 0; i < reasonsArray.length; i++){
                if(reasonsArray[i] === value){
                    reasonsArray.splice(i, 1);
                }
            }
        }

        setFitnessLocation(reasonsArray);
        setTestNumber(testNumber + 1);
    }

    const HandleFitnessTimeCheckbox = (value) => {
        var reasonsArray = fitnesstime;

        
       const match = reasonsArray.find(element => {
           if (element.includes(value)) {
             return true;
           }
         });

        if(!match){
            reasonsArray.push(value);
        }
        else{
            for(let i = 0; i < reasonsArray.length; i++){
                if(reasonsArray[i] === value){
                    reasonsArray.splice(i, 1);
                }
            }
        }

        setFitnessTime(reasonsArray);
        setTestNumber(testNumber + 1);
    }

     const GetCoordinates = () => {
        const code = { postalCode: postalCode.value}
        GetLongituteAndLatitute(code, CombineCoordinates);
    }

    const CombineCoordinates = (coordinates) =>{
        var newCoordinates;
        if(coordinates.postalcodes.length === 0){ 
            newCoordinates = "N/A"
        }
        else{
            var newCoordinates = {lat: coordinates.postalcodes[0]["lat"], lng: coordinates.postalcodes[0]["lng"]};
        }
        doSubmit(newCoordinates);  
    }

    const validDetails = () => {
        if(validAge !=="valid"){
            return false;
        }
        else if(city.validity !== "valid"){
            return false;
        }
        else if(postalCode.validity !== "valid"){
            return false;
        }

        return true;
    }

    const doSubmit = (coordinates) => {
        if(!validDetails()) {return;}

        const data = {
            activities: activities,
            activereason: activereason,
            otherfavorites: otherfavorites,
            fitnesslevel: fitnesslevel,
            fitnesslocation: fitnesslocation,
            fitnesstime: fitnesstime,
            reasonforpartner: reason,
            personality: personality,
            worksituation: worksituation,
            aboutuser: aboutuser,
            city: city.value,
            coordinates: coordinates,
            postalCode: postalCode.value,
            dateofbirth: {day: day, month: month, year: year},
        }

        UpdateUserInformation(auth.currentUser.uid, data, moveToNextWindow)
    };

    const moveToNextWindow = () =>{
        window.location = "/cancer-history";
    }

    return (
        <React.Fragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage src={groupImage} alt="Keep at it!" />
                </ParentDiv>   
            </HeightDiv>
            <MarginDiv>
                <StyledContainer>
                    <Row>
                        <p>
                            Welcome back to HIMALAYAStrial.ca! In order to increase your chances of finding an exercise partner, we encourage you 
                            to fill out as much information as possible in the following steps.
                        </p>
                    </Row>
                    <br />
                    <Row>
                        <Label>Date of birth<RedText>*</RedText>:</Label>
                        <div>We don't display your full date of birth. We only use this to calculate and display your age to help find your exercise partner.</div>
                        <Col>
                            <StyledInput valid={validAge === "valid"} invalid={validAge === "invalid"} type="select" name="day" id="day" onChange={e => validateDay(e.target.value)}>
                                {days.map((day) => (
                                    <option  key={day._id} value={day._id}>
                                        {day.name}
                                    </option>
                                ))}
                            </StyledInput>
                            {validAge === "invalid" && <FormFeedback invalid>Must be over 18 to use service</FormFeedback>}
                        </Col>
                        <Col>
                            <StyledInput valid={validAge === "valid"} invalid={validAge === "invalid"} type="select" name="month" id="month" onChange={e => validateMonth(e.target.value)}>
                                {months.map((month) => (
                                    <option  key={month._id} value={month._id}>
                                        {month.name}
                                    </option>
                                ))}
                            </StyledInput>
                        </Col>
                        <Col>
                            <StyledInput valid={validAge === "valid"} invalid={validAge === "invalid"} type="select" name="year" id="year" onChange={e => validateYear(e.target.value)}>
                                {years.map((year) => (
                                    <option  key={year._id} value={year._id}>
                                        {year.name}
                                    </option>
                                ))}
                            </StyledInput>    
                        </Col>
                        <br />
                    </Row>
                    <br />
                    <Row>
                        <Col lg="6" md="8">
                        <Label for="city">What is your city of residence<RedText>*</RedText>:</Label>
                        <StyledInput valid={city.validity === "valid"} invalid={city.validity === "invalid"} type="select" name="city" id="city" onChange={e => validateCity(e.target.value)}>
                            {cities.map((city, i) => (
                                <option  key={city._id} value={city.name}>
                                    {city.name}
                                </option>
                            ))}
                        </StyledInput>
                        </Col>
                        <br />
                    </Row>
                    <br />
                    <Row>
                        <Col lg="6" md="8">
                            <Label for="postalcode">What is your home postal code<RedText>*</RedText>:</Label>
                            <Label>Postal code will be kept private, but will be used by our system to find exercise partners near you.</Label>
                            <Label>Ex: A1A 2B2</Label>
                            <StyledInput valid={postalCode.validity === "valid"} invalid={postalCode.validity === "invalid"} name="postalcode" id="postalcode" onChange={e => validatePostalCode(e.target.value)}/>
                            {postalCode.validity === "invalid" && <FormFeedback invalid>Must be a valid postal code</FormFeedback>}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <h3>My Public Info - About Me & Physical Activity History</h3>
                        <p>This information will be displayed on your profile, to other users.</p>
                        <hr />
                    </Row>
                    <Row>
                        <Col lg="6" md="8">
                            <Label>What are your favorite activities?<PopUpText text="Please list your favourites." /></Label>
                            <CheckBox 
                                name="activities"
                                options={aboutme.activities}
                                useranswers={activities}
                                onChange={e => HandleActivitiesCheckbox(e.target.value, e.target.checked)} 
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col lg="6" md="8">
                            <Label>Do you have any other favorite activities?<PopUpText text="Did we miss some? What other activities do you love?" /></Label>
                            <StyledInput maxlength="280" name="otherfavorites" id="otherfavorites" placeholder="other activities..." onChange={e => setOtherFavorites(e.target.value)} />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col lg="6" md="8">
                            <Label>What is your fitness level?<PopUpText text="Help find great exercise partner matches by indicating how active you are." /></Label>
                            <StyledInput type="select" name="fitnesslevel" id="fitnesslevel" onChange={e => setFitnessLevel(e.target.value)}>
                                {aboutme.fitnesslevel.map((level) => (
                                    <option  key={level._id} value={level._id}>
                                        {level.name}
                                    </option>
                                ))}
                            </StyledInput>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col lg="6" md="8">
                            <Label>Identify your top three reasons for wanting to become more active:</Label>
                            <CheckBox 
                                name="activereason" 
                                id="activereason" 
                                options={aboutme.activereasons}
                                useranswers={activereason}
                                onChange={e => HandleReasonsCheckbox(e.target.value, e.target.checked)} 
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col lg="6" md="8">
                            <Label>Where do you prefer to be active?<PopUpText text="Where do you like to exercise? (Choose all that apply)" /></Label>
                            <CheckBox name="fitnesslocation"
                                id="fitnesslocation"
                                options={aboutme.activitylocation}
                                useranswers={fitnesslocation}
                                onChange={e => HandleFitnessLocationCheckbox(e.target.value, e.target.checked)}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col lg="6" md="8">
                            <Label>When do you prefer to be active?
                                <PopUpText text="When do you like to exercise? OR What is the best time of day for you to exercise? (Choose all that apply)"/>
                            </Label>
                            <CheckBox name="fitnesstime"
                                id="fitnesstime"
                                options={aboutme.activitytime}
                                useranswers={fitnesstime}
                                onChange={e => HandleFitnessTimeCheckbox(e.target.value, e.target.checked)}
                            />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col lg="7" md="7">
                            <Label>Please list some reasons why you may want an exercise partner:
                            <PopUpText text="Please list the ways in which you think having an exercise partner may help 
                                you achieve your exercise goals? Please rank your responses in order of importance if you list more than one benefit" />
                            </Label>
                            <StyledInput type="textarea" maxlength="280" name="reason" id="reason" placeholder="Partner Reason..." onChange={e => setReason(e.target.value)} />
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col lg="6" md="6">
                            <Label>Which of the following best describes you?<PopUpText text="Which of these best describes you?" /></Label>
                            <StyledInput type="select" name="personality" id="personality" onChange={e => setPersonality(e.target.value)}>
                                    {aboutme.personality.map((personality) => (
                                        <option key={personality._id} value={personality._id}>
                                            {personality.name}
                                        </option>
                                    ))}
                            </StyledInput>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col lg="6" md="6">
                            <Label>Which of the following best describes your work situation?</Label>
                            <StyledInput type="select" name="worksituation" id="worksituation" onChange={e => setWorkSituation(e.target.value)}>
                                    {aboutme.worksituation.map((worksituation) => (
                                        <option key={worksituation._id} value={worksituation._id}>
                                            {worksituation.name}
                                        </option>
                                    ))}
                            </StyledInput>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col lg="7" md="9">
                            <Label>About you. Tell us about yourself!<PopUpText text="Anything goes…Who are you? What are you into? What gets you excited? (max 280 characters)"/></Label>
                            <StyledInput maxlength="280" type="textarea" name="aboutuser" id="aboutuser" onChange={e => setAboutUser(e.target.value)}/>
                        </Col>
                    </Row>
                    <br />
                    <Button color="primary" disabled={!validDetails()} onClick={() => {GetCoordinates()}}>Save & Continue</Button>
                </StyledContainer>
            </MarginDiv>
        </React.Fragment>
    );
}

export default AboutMe;

const MarginDiv = styled.div`
    margin-left: 15px;
    margin-right: 15px;
`

const StyledInput = styled(Input)`
&:focus{
    border-color: #372844;
    box-shadow: 0 0.5rem 1rem #9780af;
}
`
const StyledContainer = styled(Container)`
    justify-content: center;
    background-color: white;
    border-radius: 10px;
    border: solid;
    border-color: #9780af #372844 #372844 #9780af;
    margin: auto;
    margin-top: 15px;
    box-shadow: 0 0.5rem 1rem #9780af;
    padding: 20px;
    max-width: 1000px;
    margin-bottom: 70px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;
 
const RedText = styled.b`
    color:Tomato;
`