import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, ModalHeader, ModalBody, ModalFooter, Modal, Input } from 'reactstrap';
import styled from 'styled-components';
import { GetUserInformation, OpenTicket, UpdateUserInformation } from '../../services/firestore';
import { auth } from '../../services/firebase';
import { moduleStrings } from './moduleStrings';
import firebase from 'firebase';
import ModuleButton from './moduleButton';
import { TicketStrings } from '../adminPages/tickets/ticketStrings';
import Fireworks from '../common/fireworks';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import forestWalk from "../../images/ForestHike.png";

const SupportMonthly = () => {
    const [fireworks, setFireworks] = useState(false);
    const [userProfileId, setUserProfileId] = useState(auth.currentUser.uid);
    const [user, setUser] = useState();
    const [moduleToComplete, setModuleToComplete] = useState("");
    const [reportedFeedback, setReportedFeedback] = useState(true);
    const [completedModule, setCompletedModule] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [feedback, setFeedback] = useState("");
    const toggle = () => setModal(!modal);

    useEffect(() => {
      GetUserInformation(userProfileId, SetUserInfo);
    }, []);

    const SetUserInfo = (user) =>{
        if(user.modules === undefined){
            user.modules = [];
        }
        setUser(user);
    }

    const StartModule = (moduleName) => {
        var newUserInformation = user;
        var moduleObject = {
            moduleName: moduleName, 
            timeStarted: firebase.firestore.Timestamp.fromDate(new Date()),
            timeCompleted: "",
            completed: false,
        }

        if(newUserInformation.modules === undefined){
            var modules = [];
            modules.push(moduleObject);
            newUserInformation.modules = modules;
        }
        else{
            var modules = newUserInformation.modules;
            for(var i = 0; i < modules.length; i++){
                if(modules[i].moduleName === moduleObject.moduleName){
                    return;
                }
            }
            modules.push(moduleObject);
            newUserInformation.modules = modules;
        }
        UpdateUserInformation(userProfileId, newUserInformation);
    }

    const CompleteModule = (moduleName) => {
        setCompletedModule(false);
        setReportedFeedback(true);
        setModuleToComplete(moduleName);
        setModalTitle("Complete Module?");
        setModalMessage("Please make sure you have read through the full module before completing");
        toggle(true);
    }

    const ConfirmModuleCompletion = () => {
        var newProperties = user;
        for(let i = 0; i < newProperties.modules.length; i++){
            if(newProperties.modules[i].moduleName === moduleToComplete){
                newProperties.modules[i].completed = true;
                newProperties.modules[i].timeCompleted = firebase.firestore.Timestamp.fromDate(new Date());
            }
        }
        UpdateUserInformation(user.uid, newProperties, ShowModuleFeedback);
    }

    const ShowModuleFeedback = (success) => {
        if(success){
            setCompletedModule(true);
            setReportedFeedback(false);
            setModalTitle("Module Completed!");
            setModalMessage("Congratulations on completing your module, please leave some feedback on the module");
            setFireworks(true);
            setTimeout(function(){setFireworks(false);}, 5000);
        }
        else{
            setModalTitle("Something went wrong");
            setModalMessage("Something happened! Please contact an admin");
        }
    }

    const SendFeedback = () =>{
        if(feedback === ""){
            toggle(false);
            return;
        }
        OpenTicket(user.username, user.uid, user.email, TicketStrings.modulefeedback, feedback, false, FollowUpModuleFeedback);
    }

    const FollowUpModuleFeedback = (success) => {
        setReportedFeedback(true);
        if(success){
            setModalTitle("Feedback Received");
            setModalMessage("Thanks for your feedback!");
        }
        else{
            setModalTitle("Unable to submit feedback");
            setModalMessage("Something went wrong!");
        }
    }

    return (
        <React.Fragment>
            <StyledModal isOpen={modal}>
            {fireworks && <Fireworks />}
                <ModalHeader>{modalTitle}</ModalHeader>
                <ModalBody>
                    {modalMessage}
                    {!reportedFeedback && <hr />}
                    {!reportedFeedback && <StyledInput type="textarea" name="feedback" id="feedback" onChange={e => setFeedback(e.target.value)}/>}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Close</Button>
                    {!completedModule && <Button color="primary" onClick={ConfirmModuleCompletion}>Complete</Button>}
                    {(!reportedFeedback && completedModule) && <Button color="primary" onClick={SendFeedback}>Submit Feedback</Button>}
                </ModalFooter>
            </StyledModal>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage />
                </ParentDiv>   
            </HeightDiv>
            <MarginDiv>
                <StyledContainer>
                    <Row>
                        <Col lg="6">
                            <RightMarginButton color="primary" href="/exercise-groups">Back to exercise groups Page</RightMarginButton>
                            <Button color="primary" href="/exercise-groups/modules-specific">Specific Modules</Button>
                            <h3><br />Monthly Behavioural Support Modules</h3>
                            <StyledParagraph>
                                New to exercise or want a more guided experience getting back into it? Follow our carefully developed 
                                12-month exercise support plan that has been designed to help you start and maintain your new exercise routine.
                            </StyledParagraph>
                            <h4>Accessing & completing behavioural support modules</h4>
                            <h5>
                                <StyledButton color="primary">
                                    <FAIconButton  icon={["fas", "download"]}></FAIconButton>
                                </StyledButton>
                                Step One
                            </h5>

                            <StyledParagraph>Press the download button on the modules you are interested in starting, this will start the module for your profile.</StyledParagraph>
                            <h5>
                                <CompleteModuleButton color="primary">
                                    <FAIconButton  icon={["fas", "check"]}></FAIconButton>
                                </CompleteModuleButton>
                                Step Two
                            </h5>
                            
                            <StyledParagraph>
                                Once you have completed the module you will be able to select the green checkmark to verify you have completed the module on your profile.
                            </StyledParagraph>
                            <StarIcon icon={["fas", "star"]}></StarIcon>
                            <StyledParagraph>Indicates you have completed the module.</StyledParagraph>
                        </Col>
                        <VideoCol>
                            {/* <YoutubeEmbed embedId="dQw4w9WgXcQ" /> */}
                            <PlaceholderImage src={forestWalk} />
                        </VideoCol> 
                    </Row>
                    <h3><br /><u>Monthly Behavioural Support Modules</u></h3>
                    <Container>
                        <Row>
                            <TableCol lg="3" md="4" sm="6">
                                    <ModuleButton 
                                    user={user} 
                                    modulestring={moduleStrings.MonthOne} 
                                    StartFunction={StartModule} 
                                    CompleteFunction={CompleteModule} 
                                    file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/monthly-behavioural-support-modules/Month-1.pdf'
                                    />
                                    <b>Topics:</b>
                                    <ul>
                                        <StyledListItem>Exercise Introduction</StyledListItem>
                                        <StyledListItem>Getting active</StyledListItem>
                                        <StyledListItem>Goal Setting</StyledListItem>
                                        <StyledListItem>Self-monitoring</StyledListItem>
                                        <StyledListItem>Activity tracker sheets</StyledListItem>
                                    </ul>
                                </TableCol>
                                <TableCol lg="3" md="4" sm="6">
                                    <ModuleButton 
                                    user={user} 
                                    modulestring={moduleStrings.MonthTwo} 
                                    StartFunction={StartModule} 
                                    CompleteFunction={CompleteModule} 
                                    file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/monthly-behavioural-support-modules/Month-2.pdf' 
                                    />
                                    <b>Topics:</b>
                                    <ul>
                                        <StyledListItem>Types of exercise</StyledListItem>
                                        <StyledListItem>Exercise prescription</StyledListItem>
                                        <StyledListItem>Motivation regulation</StyledListItem>
                                        <StyledListItem>Overcoming barriers</StyledListItem>
                                        <StyledListItem>Activity tracker sheets</StyledListItem>
                                    </ul>
                                </TableCol>
                                <TableCol lg="3" md="4" sm="6">
                                    <ModuleButton 
                                    user={user} 
                                    modulestring={moduleStrings.MonthThree} 
                                    StartFunction={StartModule} 
                                    CompleteFunction={CompleteModule} 
                                    file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/monthly-behavioural-support-modules/Month-3.pdf' 
                                    />
                                    <b>Topics:</b>
                                    <ul>
                                        <StyledListItem>Cancer-related cardiovascular disease</StyledListItem>
                                        <StyledListItem>Having fun with physical activity</StyledListItem>
                                        <StyledListItem>Physical activity environmental scan</StyledListItem>
                                        <StyledListItem>Activity tracking</StyledListItem>
                                        <StyledListItem>Activity tracker sheets</StyledListItem>
                                    </ul>
                                </TableCol>
                                <TableCol lg="3" md="4" sm="6">
                                    <ModuleButton 
                                    user={user} 
                                    modulestring={moduleStrings.MonthFour} 
                                    StartFunction={StartModule} 
                                    CompleteFunction={CompleteModule} 
                                    file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/monthly-behavioural-support-modules/Month-4.pdf' 
                                    />
                                    <b>Topics:</b>
                                    <ul>
                                        <StyledListItem>Exercise benefits: Mental & emotional</StyledListItem>
                                        <StyledListItem>Exercise progression & recovery</StyledListItem>
                                        <StyledListItem>Stimulus control</StyledListItem>
                                        <StyledListItem>Goal setting</StyledListItem>
                                        <StyledListItem>Self talk</StyledListItem>
                                        <StyledListItem>Activity tracker sheets</StyledListItem>
                                    </ul>
                                </TableCol>
                                <TableCol lg="3" md="4" sm="6">
                                    <ModuleButton 
                                    user={user} 
                                    modulestring={moduleStrings.MonthFive} 
                                    StartFunction={StartModule} 
                                    CompleteFunction={CompleteModule} 
                                    file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/monthly-behavioural-support-modules/Month-5.pdf'
                                    />
                                    <b>Topics:</b>
                                    <ul>
                                        <StyledListItem>Exercise benefits: Social</StyledListItem>
                                        <StyledListItem>Social support</StyledListItem>
                                        <StyledListItem>Decision balance</StyledListItem>
                                        <StyledListItem>Activity tracker sheets</StyledListItem>
                                    </ul>
                                </TableCol>
                                <TableCol lg="3" md="4" sm="6">
                                    <ModuleButton 
                                    user={user} 
                                    modulestring={moduleStrings.MonthSix} 
                                    StartFunction={StartModule} 
                                    CompleteFunction={CompleteModule} 
                                    file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/monthly-behavioural-support-modules/Month-6.pdf' 
                                    />
                                    <b>Topics:</b>
                                    <ul>
                                        <StyledListItem>Exercise benefits: Physical function</StyledListItem>
                                        <StyledListItem>Time management</StyledListItem>
                                        <StyledListItem>Overcoming barriers</StyledListItem>
                                        <StyledListItem>Goal setting</StyledListItem>
                                        <StyledListItem>Activity tracker sheets</StyledListItem>
                                    </ul>
                                </TableCol>
                                <TableCol lg="3" md="4" sm="6">
                                    <ModuleButton 
                                    user={user} 
                                    modulestring={moduleStrings.MonthSeven} 
                                    StartFunction={StartModule} 
                                    CompleteFunction={CompleteModule} 
                                    file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/monthly-behavioural-support-modules/Month-7.pdf' 
                                    />
                                    <b>Topics:</b>
                                    <ul>
                                        <StyledListItem>Exercise benefits: Fatigue</StyledListItem>
                                        <StyledListItem>Exercise maintenance</StyledListItem>
                                        <StyledListItem>Activity tracker sheets</StyledListItem>
                                    </ul>
                                </TableCol>
                                <TableCol lg="3" md="4" sm="6">
                                    <ModuleButton 
                                    user={user} 
                                    modulestring={moduleStrings.MonthEight} 
                                    StartFunction={StartModule} 
                                    CompleteFunction={CompleteModule} 
                                    file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/monthly-behavioural-support-modules/Month-8.pdf' 
                                    />
                                    <b>Topics:</b>
                                    <ul>
                                        <StyledListItem>Stimulus control</StyledListItem>
                                        <StyledListItem>Motivation regulation</StyledListItem>
                                        <StyledListItem>Self talk</StyledListItem>
                                        <StyledListItem>Goal setting</StyledListItem>
                                        <StyledListItem>Activity tracker sheets</StyledListItem>
                                    </ul>
                                </TableCol>
                                <TableCol lg="3" md="4" sm="6">
                                    <ModuleButton 
                                    user={user} 
                                    modulestring={moduleStrings.MonthNine} 
                                    StartFunction={StartModule} 
                                    CompleteFunction={CompleteModule} 
                                    file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/monthly-behavioural-support-modules/Month-9.pdf' 
                                    />
                                    <b>Topics:</b>
                                    <ul>
                                        <StyledListItem>Exercise & physical fitness refresher</StyledListItem>
                                        <StyledListItem>Activity tracker sheets</StyledListItem>
                                    </ul>
                                </TableCol>
                                <TableCol lg="3" md="4" sm="6">
                                    <ModuleButton 
                                    user={user} 
                                    modulestring={moduleStrings.MonthTen} 
                                    StartFunction={StartModule} 
                                    CompleteFunction={CompleteModule} 
                                    file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/monthly-behavioural-support-modules/Month-10.pdf' 
                                    />
                                    <b>Topics:</b>
                                    <ul>
                                        <StyledListItem>Social support</StyledListItem>
                                        <StyledListItem>Decision balance</StyledListItem>
                                        <StyledListItem>Activity tracker sheets</StyledListItem>
                                    </ul>
                                </TableCol>
                                <TableCol lg="3" md="4" sm="6">
                                    <ModuleButton 
                                    user={user} 
                                    modulestring={moduleStrings.MonthEleven} 
                                    StartFunction={StartModule} 
                                    CompleteFunction={CompleteModule} 
                                    file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/monthly-behavioural-support-modules/Month-11.pdf' 
                                    />
                                    <b>Topics:</b>
                                    <ul>
                                        <StyledListItem>Activity tracker sheets</StyledListItem>
                                    </ul>
                                </TableCol>
                                <TableCol lg="3" md="4" sm="6">
                                    <ModuleButton 
                                    user={user} 
                                    modulestring={moduleStrings.MonthTwelve} 
                                    StartFunction={StartModule} 
                                    CompleteFunction={CompleteModule} 
                                    file='https://himalayas-space.nyc3.cdn.digitaloceanspaces.com/docs/monthly-behavioural-support-modules/Month-12.pdf' 
                                    />
                                    <b>Topics:</b>
                                    <ul>
                                        <StyledListItem>Goal setting</StyledListItem>
                                        <StyledListItem>Time management</StyledListItem>
                                        <StyledListItem>Overcoming barriers</StyledListItem>
                                        <StyledListItem>Self monitoring</StyledListItem>
                                        <StyledListItem>Activity tracker sheets</StyledListItem>
                                    </ul>
                                </TableCol>
                            </Row>
                    </Container>
                </StyledContainer>
            </MarginDiv>
        </React.Fragment>
     );
}

export default SupportMonthly;

const MarginDiv = styled.div`
    margin-left: 15px;
    margin-right: 15px;
`

const PlaceholderImage = styled.img`
    width: 100%;
`

const RightMarginButton = styled(Button)`
    margin-right: 25px;
`

const StyledParagraph = styled.p`
    font-size: large;
`

const StyledListItem = styled.li`
    font-size: large;
`

const VideoCol = styled(Col)`
    min-height: 500px;
    margin-bottom: 30px;
`

const TableCol = styled(Col)`
    padding-top: 10px;
    border-style: solid;
    border-color: black;
    border-width: 1px;
`
 
const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #372844;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 10px;
    max-width: 1500px;
    margin-top: 15px;
    margin-bottom: 90px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #dddddd;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

const StyledModal = styled(Modal)`
    position: absolute;
    left: 30%;
    top: 40%;
    min-width: 30%;
`

const StyledInput = styled(Input)`
margin-left: 10px;
border-color: black;
&:focus{
    border-color: #372844;
    box-shadow: 0 0.5rem 1rem #9780af;
}
`
const CompleteModuleButton = styled(Button)`
    min-width: 40px;
    min-height: 35px;
    margin-left: 5px;
    background-color: red;
    &:hover{
        background-color: green;
    }
    margin-right: 10px;
`;

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 0px;
    margin-left: 0px;
`;

const StarIcon = styled(FontAwesomeIcon)`
    margin-right: 0px;
    margin-left: 15px;
    color: gold;
`;

const StyledButton = styled(Button)`
    min-width: 40px;
    min-height: 35px;
    margin-left: 5px;
    margin-right: 10px;
`;