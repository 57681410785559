import React, { useState } from 'react';
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Input, Button, Label } from 'reactstrap';
import * as options from '../../libraries/cancerhistory'
import styled from 'styled-components';
import { updateDocumentProperty, updateDocument } from '../../services/firestore';
import { db, auth } from '../../services/firebase';

const FirstTimeCancerHistory = (props) => {

    const [cancerlocation, setCancerLocation] = useState("");
    const [otherlocation, setOtherLocation] = useState("");
    const [cancerstatus, setCancerStatus] = useState("");
    const [treatmentdescription, setTreatmentDescription] = useState("");
    const [supportgroup, setSupportGroup] = useState("");
    const [supportgroupname, setSupportGroupName] = useState("");
    const [learnaboutus, setLearnAboutUs] = useState({
        "Flyer": false,
        "Word of mouth": false,
        "Website": false,
        "Event / Conference / Symposium": false,
        "Facebook": false,
        "Instagram": false,
        "Twitter": false,
        "Friend": false,
        "Healthcare Provider": false,
        "Other": false
    });

    const [shareCancerLocation, setShareCancerLocation] = useState(true);
    const [shareOtherLocation, setShareOtherLocation] = useState(true);
    const [shareCancerStatus, setShareCancerStatus] = useState(true);
    const [shareTreatmentDescription, setShareTreatmentDescription] = useState(true);

    const HandleLearnAboutCheckbox = (value, checked) =>{
        var learnArray = learnaboutus;
         
        const match = learnArray.find(element => {
            if (element.includes(value)) {
              return true;
            }
          });

         if(!match){
            learnArray.push(value);
         }
         else{
             for(let i = 0; i < learnArray.length; i++){
                 if(learnArray[i] === value){
                    learnArray.splice(i, 1);
                 }
             }
         }

         setLearnAboutUs(learnArray);
    }

    const back = () => {
        updateDocumentProperty(db, "users", auth.currentUser.uid, "firstPageComplete", false);
    };

    const nextPage = () => {
        let user = props.user;
        user.secondPageComplete = true;
        user.shareCancerLocation = shareCancerLocation;
        user.shareCancerStatus = shareCancerStatus;
        user.shareOtherLocation = shareOtherLocation;
        user.shareTreatmentDescription = shareTreatmentDescription;

        user.cancerlocation = cancerlocation;
        user.otherlocation = otherlocation;
        user.cancerstatus = cancerstatus;
        user.treatmentdescription = treatmentdescription;
        user.hasbeenpartofsupportgroup = supportgroup;
        user.supportgroupname = supportgroupname;
        user.howdidtheylearnaboutus = learnaboutus;

        updateDocument(db, "users", props.user.uid, user, () => {window.location = "/exercise-groups"}, (err) => {alert("Error saving data. Please try again: " + err)});
        //console.log("First time user flow complete.");
        //window.location = "/exercise-groups"
    }

    const ToggleStateCheckbox = (id, checked, state, stateSetter) => {
        let valueObj = state;
        valueObj[id] = checked;
        stateSetter(valueObj);
    }

    return (
        <StyledFragment>
            <StyledContainer>
                <br />
                <h1 style={{textAlign:"center"}}>Cancer History</h1>
                <Row>
                    <Col>
                        <p>
                        This information will be displayed on your profile to other users. Please do complete this section as it 
                        allows other members to know more about how similar you are in your cancer history and treatment status.
                        Women have told us that sometimes this information is important to them.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label><BoldP>Your most recent cancer diagnosis? (if other, fill in below)</BoldP></Label>
                        <div style={{backgroundColor:"lavender", borderRadius:"15px", paddingRight:"10px", textAlign:"right", marginBottom:"10px"}}>
                            <Label style={{marginRight:"10px"}}>Share in profile</Label>
                            <Input onClick={() => setShareCancerLocation(!shareCancerLocation)} checked={shareCancerLocation} style={{marginRight:"10px"}} type="checkbox" />
                        </div>
                        <StyledInput type="select" name="cancerlocation" id="cancerlocation" onChange={e => setCancerLocation(e.target.value)}>
                            {options.cancerlocation.map((location) => (
                                <option  key={location._id} value={location._id}>
                                    {location.name}
                                </option>
                            ))}
                        </StyledInput>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Label><BoldP>If you have had another cancer diagnosis, please list it here:</BoldP></Label>
                        <div style={{backgroundColor:"lavender", borderRadius:"15px", paddingRight:"10px", textAlign:"right", marginBottom:"10px"}}>
                            <Label style={{marginRight:"10px"}}>Share in profile</Label>
                            <Input onClick={() => setShareOtherLocation(!shareOtherLocation)} checked={shareOtherLocation} style={{marginRight:"10px"}} type="checkbox" />
                        </div>
                        <StyledInput maxlength="280" name="cancerlocation" id="cancerlocation" placeholder="other location..." onChange={e => setOtherLocation(e.target.value)} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Label><BoldP>Which of the following best describes you now?</BoldP></Label>
                        <div style={{backgroundColor:"lavender", borderRadius:"15px", paddingRight:"10px", textAlign:"right", marginBottom:"10px"}}>
                            <Label style={{marginRight:"10px"}}>Share in profile</Label>
                            <Input onClick={() => setShareCancerStatus(!shareCancerStatus)} checked={shareCancerStatus} style={{marginRight:"10px"}} type="checkbox" />
                        </div>
                        <StyledInput type="select" name="cancerstatus" id="cancerstatus" onChange={e => setCancerStatus(e.target.value)}>
                            {options.cancerstatus.map((status) => (
                                <option  key={status._id} value={status._id}>
                                    {status.name}
                                </option>
                            ))}
                        </StyledInput>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Label><BoldP>Please briefly describe your cancer treatments:</BoldP></Label>
                        <div style={{backgroundColor:"lavender", borderRadius:"15px", paddingRight:"10px", textAlign:"right", marginBottom:"10px"}}>
                            <Label style={{marginRight:"10px"}}>Share in profile</Label>
                            <Input onClick={() => setShareTreatmentDescription(!shareTreatmentDescription)} checked={shareTreatmentDescription} style={{marginRight:"10px"}} type="checkbox" />
                        </div>
                        <StyledInput maxlength="280" name="treatmentdescription" id="treatmentdescription" placeholder="Treatment..." onChange={e => setTreatmentDescription(e.target.value)} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                    <Label><BoldP>Have you worked with cancer support organizations before?</BoldP></Label>
                        <StyledInput type="select" name="supportgroup" id="supportgroup" onChange={e => setSupportGroup(e.target.value)}>
                            {options.binaryanswer.map((status) => (
                                <option  key={status._id} value={status._id}>
                                    {status.name}
                                </option>
                            ))}
                        </StyledInput>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                    <Label><BoldP>If yes, what program? (list the name and location if possible, for example: HEALTH program, Toronto Rehabilitation Institute or WE-Can program, ELLICSR - Princess Margaret Cancer Centre)</BoldP></Label>
                        <StyledInput maxlength="100" name="supportgroupname" id="supportgroupname" placeholder="Support Group..." onChange={e => setSupportGroupName(e.target.value)} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Label><BoldP>How did you learn about us?</BoldP></Label>
                        {learnaboutus !== undefined && Object.entries(learnaboutus).map((element) => (
                            <div key={element[0]}>
                                <Input type="checkbox" value={learnaboutus[element[0]]} onChange={e => ToggleStateCheckbox(element[0], e.target.checked, learnaboutus, setLearnAboutUs)}/>
                                <Label style={{marginLeft:"8px"}}>
                                   {element[0]}
                                </Label>
                            </div>
                        ))}
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col style={{textAlign:"left"}}>
                        <Button onClick={() => back()} color="secondary">Back</Button>
                    </Col>
                    <Col style={{textAlign:"right"}}>
                        <Button color="primary" onClick={() => {nextPage()}}>Save & Complete</Button>
                    </Col>
                </Row>
            </StyledContainer>
        </StyledFragment>
    );
}

export default FirstTimeCancerHistory;

const StyledP = styled.p`
    margin: 0px;
`;

const BoldP = styled.p`
    font-weight: bold;
    font-size: 16px;
    color: #372844;
    margin-top: 0px;
    margin-bottom: 0px;
`;

const ExplainingP = styled.p`
    font-size: 14px;
    color: #372844;
    margin-top: 0px;
    margin-bottom: 0px;
    font-style: italic;
`;

const RedText = styled.b`
    color:Tomato;
`

const StyledInput = styled(Input)`
    margin-bottom: 10px;
    &:focus{
        border-color: #372844;
        box-shadow: 0 0.5rem 1rem #9780af;
    }
`

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #9780af;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 500px;
    margin-top: 15px;
`

const StyledFragment = styled.div`
    height: 100%;
    min-height: ${window.innerHeight - 255}px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;
 