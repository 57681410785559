import styled from 'styled-components'
import React, { useState, useEffect } from "react";
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { UpdateUser } from './utils/analytics';
import { Container } from 'reactstrap';
import Login from './components/sitePages/login';
import Logout from './components/sitePages/logout';
import Register from './components/registration/register';
import Profile from './components/profilePages/profile';
import LostPassword from './components/sitePages/lostPassword';
import NotFound from './components/common/notFound';
import ProfileList from './components/profilePages/profileList';
import HomePage from './components/sitePages/homePage';
import Messages from './components/profilePages/messages';
import FrequentlyAskedQuestions from './components/sitePages/frequentlyAskedQuestions';
import Navbar from './components/common/navbar';
import Footer from './components/common/footer';
import TermsOfUse from './components/sitePages/termsOfUse';
import AboutMe from './components/registration/aboutMe';
import CancerHistory from './components/registration/cancerHistory';
import EmailConfirm from './components/registration/emailConfirm';
import PhotoUpload from './components/registration/photoUpload';
import ProtectedRoute from './components/common/routeWrappers/protectedRoute';
import VerifiedRoute from './components/common/routeWrappers/verifiedRoute';
import AdminRoute from './components/common/routeWrappers/adminRoute';
import { auth } from './services/firebase'
import MessageRoom from './components/profilePages/messageRoom';
import { GetUserInformation } from './services/firestore';
import StudyInfoAndTeam from './components/sitePages/StudyInfoAndTeam';
import CoreGroup from './components/protectedPages/coreGroup';
import EthicsAndStudy from './components/sitePages/ethicsAndStudy';
import SupportMonthly from './components/protectedPages/supportMonthly';
import AdminLandingPage from './components/adminPages/admin-landing-page';
import TeamProfiles from './components/adminPages/teamprofiles';
import AdminProfileControl from './components/adminPages/site-admin/admin-site-user-control';
import EditTeamProfile from './components/adminPages/editteamprofile';
import TicketListings from './components/adminPages/tickets/ticketListings';
import WaitingAdminAcceptance from './components/profilePages/waitingforadmin';
import TopicSpecific from './components/protectedPages/topicSpecific';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCheckSquare, faCoffee, faEdit, faRedo, faTrash, faChevronDown, faChevronUp, faUserPlus, faExclamationCircle, faCopy, faAnchor, faSpaceShuttle,
  faBiohazard, faSkull, faBalanceScale, faMinusSquare, faToggleOn, faToggleOff, faTimes, faCheck, faEye, faDownload, faPlus, faStar, faMinus, faQuestionCircle, 
  faSave, faAngleDoubleDown, faBan } from '@fortawesome/free-solid-svg-icons'
import PageControl from './components/adminPages/pageControl';
import PersonalProfile from './components/profilePages/personalProfile';
import PasswordReset from './components/profilePages/passwordreset';
import ArchivedAccountNotice from './components/common/archivedAccount';
import ActionUrl from './components/common/actionUrl';
import Ticket from './components/adminPages/tickets/ticket';
import RouteWrapper from './components/common/routeWrappers/routeWrapper';
import { useCookies } from 'react-cookie';
import CookiePermissionPopup from './components/sitePages/cookiePermissions';
import { v4 as uuidv4 } from 'uuid';
import AdminStudyAdminControl from './components/adminPages/study-admin/admin-study-admin-control';
import AdminRegionalUserControl from './components/adminPages/regional-admin/admin-regional-user-control';
import AdminStudyUpdateDocuments from './components/adminPages/study-admin/admin-study-update-documents';
import FirstTimeContainer from './components/sitePages/first-time-container';
import AdminRegionalEnrolParticipant from './components/adminPages/regional-admin/admin-regional-enrol-users';
import AdminStudyEnrolStaff from './components/adminPages/study-admin/admin-study-enrol-staff';

library.add(fab, faCheckSquare, faCoffee, faEdit, faRedo, faTrash, faChevronDown, faChevronUp, faUserPlus, faExclamationCircle, faCopy, faAnchor, faSpaceShuttle,
  faBiohazard, faSkull, faBalanceScale, faMinusSquare, faToggleOn, faToggleOff, faTimes, faCheck, faEye, faDownload, faPlus, faStar, faMinus, faQuestionCircle, 
  faSave, faAngleDoubleDown, faBan);

const history = createBrowserHistory();

const App = () => {

  const [cookies, setCookie] = useCookies();
  const [cookiesOpen, setCookiesOpen] = useState(true);
  const [user, setUser] = useState();

  function onAuthStateChange() {
    return auth.onAuthStateChanged(user => {
      if (user) {
        //auth.signOut();
        GetUserInformation(user.uid, setUserProfile);
        UpdateUser(user.uid);
      } else {
        setUser("Guest");
        if (cookies['himalayas-trial-user-id'] === undefined) {
          const randomGUID = uuidv4(); //generate random cookie
          setCookie('himalayas-trial-user-id', randomGUID, { path: '/'});
          UpdateUser(cookies['himalayas-trial-user-id']); 
        } else {
          UpdateUser(cookies['himalayas-trial-user-id']);
        }
      }
    });
  }

  useEffect(() => {
    onAuthStateChange();
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => { setCookiesOpen(false)}, 6000);
    return () => {clearTimeout(timer)};
  }, [])

 const setUserProfile = ((user) => {
    setUser(user);
    setCookie('himalayas-trial-user-id', user.uid, { path: '/'});
  });

  return (
    <Router history={history} >
      <Navbar user={user} />
      <CookiePermissionPopup cookiesOpen={cookiesOpen} setCookiesOpen={() => (setCookiesOpen())}/>
      {console.log("Version: 1.1")}{/* Updated April 27th 2022 */}
      {user && <StyledContainer fluid={true}>
        <Switch>
          {/* General Pages */}
          <RouteWrapper path="/frequently-asked-questions" title="Home | HIMALAYAS Trial" user={user} component={FrequentlyAskedQuestions} />
          <RouteWrapper path="/terms" title="Terms Of Use | HIMALAYAS Trial" user={user} component={TermsOfUse} />
          <RouteWrapper path="/about-us" title="About Us | HIMALAYAS Trial" user={user} component={StudyInfoAndTeam} />
          <RouteWrapper path="/ethicsAndStudy" title="Ethics and Study | HIMALAYAS Trial" user={user} component={EthicsAndStudy} />
          <RouteWrapper path="/action-url" title="Redirect | HIMALAYAS Trial" user={user} component={ActionUrl} />

          {/* Login */}
          <RouteWrapper path="/login" title="Login | HIMALAYAS Trial" user={user} render={(props) => <Login user={user} {...props} />} />
          <RouteWrapper path="/logout" title="Logout | HIMALAYAS Trial" user={user} component={Logout} />
          <RouteWrapper path="/lost-password" title="Lost Password | HIMALAYAS Trial" user={user} component={LostPassword} />
          <RouteWrapper path="/password-reset" title="Password Reset | HIMALAYAS Trial" user={user} component={PasswordReset} />

          {/* Registration */}
          <RouteWrapper path="/register" title="Register | HIMALAYAS Trial" user={user} component={Register} />
          <RouteWrapper path="/email-confirmation" title="Email Confirmation | HIMALAYAS Trial" user={user} component={EmailConfirm} />
          <ProtectedRoute path="/about-me" title="About Me | HIMALAYAS Trial" user={user} component={AboutMe} />
          <ProtectedRoute path="/cancer-history" title="Cancer History | HIMALAYAS Trial" user={user} component={CancerHistory} />
          <ProtectedRoute path="/photo-upload" title="Photo Upload | HIMALAYAS Trial" user={user} component={PhotoUpload} />

          {/* User only information */}
          <VerifiedRoute path="/first-time" title="First Time | HIMALAYAS Trial" user={user} component={FirstTimeContainer} />
          <VerifiedRoute path="/profile/:id" title="Profile | HIMALAYAS Trial" user={user} component={Profile} />
          <VerifiedRoute path="/personal" title="Personal | HIMALAYAS Trial" user={user} component={PersonalProfile} />
          <VerifiedRoute path="/profile-list" title="Profile List | HIMALAYAS Trial" user={user} component={ProfileList} />
          <VerifiedRoute path="/messages" title="Messages | HIMALAYAS Trial" user={user} component={Messages} />
          <VerifiedRoute path="/messageRoom/:id" title="Message Room | HIMALAYAS Trial" user={user} component={MessageRoom} />
          <VerifiedRoute path="/exercise-groups" exact title="Core Groups | HIMALAYAS Trial" user={user} component={CoreGroup} />
          <VerifiedRoute path="/exercise-groups/modules-monthly" title="Monthly Modules | HIMALAYAS Trial" user={user} component={SupportMonthly} />
          <VerifiedRoute path="/exercise-groups/modules-specific" title="Specific Modules | HIMALAYAS Trial" user={user} component={TopicSpecific} />
          <RouteWrapper path="/awaiting-admin" title="Await Admin | HIMALAYAS Trial" user={user} component={WaitingAdminAcceptance} />
          <RouteWrapper path="/archived-account" title="Archived Account | HIMALAYAS Trial" user={user} component={ArchivedAccountNotice} />

          <AdminRoute path="/admin-home" title="Admin Home | HIMALAYAS Trial" user={user} component={AdminLandingPage} />
          <AdminRoute path="/team-list" title="Team List | HIMALAYAS Trial" user={user} component={TeamProfiles} />
          <AdminRoute path="/edit-team-profile/:id" title="Edit Team Profile | HIMALAYAS Trial" user={user} component={EditTeamProfile} />
          <AdminRoute path="/admin-site-profile-control" title="Admin Profile Control | HIMALAYAS Trial" user={user} component={AdminProfileControl} />
          <AdminRoute path="/admin-study-admin-control" title="Admin Admin Control | HIMALAYAS Trial" user={user} component={AdminStudyAdminControl} />
          <AdminRoute path="/admin-study-update-documents" title="Admin Document Control | HIMALAYAS Trial" user={user} component={AdminStudyUpdateDocuments} />
          <AdminRoute path="/admin-study-enrol-staff" title="Admin Study Register User | HIMALAYAS Trial" user={user} component={AdminStudyEnrolStaff} />
          <AdminRoute path="/admin-regional-admin-control" title="Admin Regional Control | HIMALAYAS Trial" user={user} component={AdminRegionalUserControl} />
          <AdminRoute path="/admin-regional-enrol-participant" title="Admin Register User | HIMALAYAS Trial" user={user} component={AdminRegionalEnrolParticipant} />
          <AdminRoute path="/page-control" title="Page Control | HIMALAYAS Trial" user={user} component={PageControl} />
          <AdminRoute path="/tickets" title="Tickets | HIMALAYAS Trial" user={user} component={TicketListings} />
          <AdminRoute path="/view-ticket/:id" title="View Ticket | HIMALAYAS Trial" user={user} component={Ticket} />

          <RouteWrapper path="/not-found" title="Not Found | HIMALAYAS Trial" user={user} component={NotFound} />
          <RouteWrapper path="/" title="Home | HIMALAYAS Trial" user={user} exact component={HomePage} />
          <Route component={NotFound} />
        </Switch>
      </StyledContainer>}
      {user && <Footer user={user} />}
    </Router>
    
  );
}

export default App;

const StyledContainer = styled(Container)`
    padding-top: 104px;
    padding-bottom: 0px;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: ${window.innerHeight - 135}px;
`