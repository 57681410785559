import React, { useState } from 'react';
import { auth, db } from '../../services/firebase';
import { Container, Row, Col, Label, Input, Button, Alert, FormFeedback } from 'reactstrap';
import styled from 'styled-components';
import joggingImage from "../../images/ForestHike.png";
import firebase from "firebase/app";
import emailjs, { init } from 'emailjs-com';

const Register = () => {
    init("user_8Qt3ZJv9dlnGhiJefkhBh");
    const [fullName, setFullName] = useState({value: "", validity: "empty"});
    const [username, setUsername] = useState({value: "", validity: "empty"});
    const [password, setPassword] = useState({value: "", validity: "empty"});
    const [confirmPassword, setConfirmPassword] = useState({value: "", validity: "empty"});
    const [email, setEmail] = useState({value: "", validity: "empty"});
    const [acceptedTerms, setAcceptedTerms] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    
    const validateUsername = (value) =>{
        if(value.length > 1 && value.length < 26){
            setUsername({value: value, validity: "valid"});
        }
        else if(value === ""){
            setUsername({value: value, validity: "empty"});
        }
        else{
            setUsername({value: value, validity: "invalid"})
        }
    }

    const validateFullName = (value) =>{
        if(value.length > 1 && value.length < 100){
            setFullName({value: value, validity: "valid"});
        }
        else if(value === ""){
            setFullName({value: value, validity: "empty"});
        }
        else{
            setFullName({value: value, validity: "invalid"})
        }
    }

    /*
    const validateStudyId = (value) =>{
        if(value.length > 1){
            setStudyId({value: value, validity: "valid"});
        }
        else if(value === ""){
            setStudyId({value: value, validity: "empty"});
        }
        else{
            setStudyId({value: value, validity: "invalid"})
        }
    }
    */

    const validatePassword = (value) => {
        if(value.length > 5 && value.match(/\d+/g)){
            setPassword({value: value, validity: "valid"});
        }
        else if(value === ""){
            setPassword({value: value, validity: "empty"});
        }
        else{
            setPassword({value: value, validity: "invalid"})
        }
    }

    const validateComparePassword = (value) =>{
        if(value === password.value){
            setConfirmPassword({value: value, validity: "valid"});
        }
        else if(value ===""){
            setConfirmPassword({value: value, validity: "empty"});
        }
        else{
            setConfirmPassword({value: value, validity: "invalid"});
        }
    }

    const validateEmail = (value) =>{
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(re.test(String(value).toLowerCase())){
            setEmail({value: value, validity: "valid"});
        }
        else if(value === ""){
            setEmail({value: value, validity: "empty"});
        }
        else{
            setEmail({value: value, validity: "invalid"});
        }
    }

    const validateForm = () => {
        if(username.validity !== "valid"){
            return false;
        }
        else if(password.validity !== "valid"){
            return false;
        }
        else if(confirmPassword.validity !== "valid"){
            return false;
        }
        else if(email.validity !== "valid"){
            return false;
        }
        else if(!acceptedTerms){
            return false;
        }
        return true;
    }

     const doSubmit = () => {
        if(!validateForm) {return;}

        const data  = {
            username: username.value,
            password: password.value,
            confirmPassword: confirmPassword.value,
            email: email.value,
            acceptedTerms: acceptedTerms,
        }

        auth.createUserWithEmailAndPassword(data.email, data.password)
        .then((userCredential) => {
            var user = userCredential.user;
            user.updateProfile({
                displayName: data.username,
                //TODO may need to add more information to the profile here
            }).then(function(){
                db.collection("users").doc(user.uid).set({
                    username: data.username,
                    uid: user.uid,
                    email: data.email,
                    accountCreatedTimestamp: firebase.firestore.Timestamp.fromDate(new Date()),
                    isAdmin: false,
                    isAccountAccepted: false,
                    emailVerified: false,
                    isArchived: false,
                    region: "unassigned",
                }, db.SetOptions)
                .then(function() {
                    emailjs.send("service_jk915bb", "template_30zcvfo", {
                        name: data.username,
                        email: data.email
                    }).then(function(){
                        user.sendEmailVerification().then(function(){
                            window.location = "/email-confirmation";
                        });
                    }); 
                })
                .catch(function(error) {
                    console.error("Error writing document: ", error);
                });
            })
        })
        .catch((error) => {
            var errorMessage = error.message;
            setErrorMessage(errorMessage);
        });    
    };

    return (
        <React.Fragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage src={joggingImage} alt="Keep at it!" />
                </ParentDiv>   
            </HeightDiv>
            <MarginDiv>
                <StyledContainer>
                    <Row>
                        <h1>Information</h1>
                        <hr />
                    </Row>
                    <Row>
                        <Col>
                            <Label><RedText>*</RedText>Full Name:</Label>
                            <StyledInput valid={fullName.validity === "valid"} invalid={fullName.validity === "invalid"} name="fullName" id="fullName" placeholder="Full Name..." onChange={e => validateFullName(e.target.value)} />
                            {fullName.validity === "invalid" && <FormFeedback invalid>Full name must be between 2 and 100 characters</FormFeedback>}
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label><RedText>*</RedText>Username:</Label>
                            <StyledInput valid={username.validity === "valid"} invalid={username.validity === "invalid"} name="username" id="username" placeholder="Username..." onChange={e => validateUsername(e.target.value)} />
                            {username.validity === "invalid" && <FormFeedback invalid>Username must be between 2 and 25 characters</FormFeedback>}
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label><RedText>*</RedText>Password:</Label>
                            <StyledInput valid={password.validity === "valid"} invalid={password.validity === "invalid"} type="password" name="password" id="password" placeholder="Password..." onChange={e => validatePassword(e.target.value)} />
                            {password.validity === "invalid" && <FormFeedback invalid>Password must be at least 6 characters long and contain a number</FormFeedback>}
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label><RedText>*</RedText>Confirm Password:</Label>
                            <StyledInput valid={confirmPassword.validity ==="valid"} invalid={confirmPassword.validity === "invalid"} type="password" name="confirmPassword" id="confirmPassword" placeholder="Confirm Password..." onChange={e => validateComparePassword(e.target.value)} />
                            {confirmPassword.validity === "invalid" && <FormFeedback invalid>Passwords must match</FormFeedback>}
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label><RedText>*</RedText>Email:</Label>
                            <br />
                            <Label style={{fontStyle:'italic', fontSize:'small'}}>Email address will not be seen by other users.</Label>
                            <StyledInput valid={email.validity ==="valid"} invalid={email.validity === "invalid"} type="email" name="email" id="email" placeholder="Email..." onChange={e => validateEmail(e.target.value)}/>
                            {email.validity === "invalid" && <FormFeedback invalid>Must be a valid email</FormFeedback>}
                            <br />
                        </Col>
                        <hr />
                    </Row>
                    {/* Remove this section if we don't want to use study ID's 
                    <Row>
                        <Col>
                            <Label><RedText>*</RedText>Study ID:</Label>
                            <br />
                            <StyledInput valid={studyId.validity ==="valid"} invalid={studyId.validity === "invalid"} type="key" name="key" id="key" placeholder="ID..." onChange={e => validateStudyId(e.target.value)}/>
                            {studyId.validity === "invalid" && <FormFeedback invalid>Study Key Required</FormFeedback>}
                            <br />
                        </Col>
                        <hr />
                    </Row>
                    */}
                    <Row>
                        <FlexCol >
                            <StyledCheckbox style={{marginRight:'10px'}} addon type="checkbox" name="acceptedTerms" aria-label="Checkbox for following text input" onChange={e => setAcceptedTerms(e.target.checked)} />
                            <Label> I have accepted and agreed to the <a href="/terms" target="_blank">terms and conditions</a><RedText>*</RedText></Label>
                        </FlexCol>

                        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                        <StyledButton disabled={!validateForm()} color="primary" onClick={() => {doSubmit()}}>Register</StyledButton>
                        <br />
                    </Row>
                </StyledContainer>
            </MarginDiv>
        </React.Fragment>
    );
}

export default Register;

const FlexCol = styled(Col)`
    display: flex;
`;

const StyledButton = styled(Button)`
    margin-top: 20px;
`

const MarginDiv = styled.div`
    margin-left: 15px;
    margin-right: 15px;
`

const StyledCheckbox = styled(Input)`
&:active {
    background-color: #9780af;
    border-color: #372844;
}
`

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #9780af;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 700px;
    margin-top: 15px;
    margin-bottom: 90px;
`

const StyledInput = styled(Input)`
&:focus{
    border-color: #372844;
    box-shadow: 0 0.5rem 1rem #9780af;
}
`

const RedText = styled.b`
    color:Tomato;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;
 