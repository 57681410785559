export const moduleStrings = {
    MonthOne: "Month One",
    MonthTwo: "Month Two",
    MonthThree: "Month Three",
    MonthFour: "Month Four",
    MonthFive: "Month Five",
    MonthSix: "Month Six",
    MonthSeven: "Month Seven",
    MonthEight: "Month Eight",
    MonthNine: "Month Nine",
    MonthTen: "Month Ten",
    MonthEleven: "Month Eleven",
    MonthTwelve: "Month Twelve",
    MonthTwelve: "Month Twelve",
    ExerciseBenefits: "Exercise Benefits",
    CVD: "CVD",
    ExercisePrescription: "Exercise Prescription",
    StayingActive: "Getting and Staying Active",
    DecisionBalance: "Decision Balance",
    ActivityEnvironment: "Activity Environment",
    Fun: "Fun with Physical Activity",
    GoalSetting: "Goal Setting",
    Motivation: "Motivation Regulation",
    SelfMonitoring: "Self-Monitoring",
    SelfTalk: "Self-Talk",
    SocialSupport: "Social Support",
    StimulusControl: "Stimulus Control",
    TimeManagement: "Time Management",
}