import React, { useState } from 'react';
import { Col, Input, Row, Button, Container } from 'reactstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PopUpText from '../../common/popUpText';

const ProfileSelect = ({user, propertyName, label, value, name, options, editFunction, tooltip = false, tooltipText}) => {
    const [hovered, setHovered] = useState(false);
    const [editing, setEditing] = useState(false);
    const [newValue, setNewValue] = useState(value);

    const acceptFunction = (newValue) => {
        //TODO Property validation
        user[propertyName] = newValue;
        editFunction(user);
        setEditing(false);
    }

    const OpenEdit = () => {
        setNewValue(value);
        setEditing(true);
    }

    const CancelEdit = () =>{
        setNewValue(value);
        setEditing(false);
    }

    const UpdateValue = (value) => {
        setNewValue(value);
    }
    
    return (<StyledContainer onMouseEnter={() => setHovered(true)} onMouseLeave={()=> setHovered(false)}>
        <Row>
        <Col xs="8">
            <h4>{label}{tooltip && <PopUpText text={tooltipText} />}</h4>
        </Col>
        {(!editing) && <FlexCol >
            <StyledButton onClick={() => OpenEdit()} color="primary">
                <FAIconButton  icon={["fas", "edit"]}></FAIconButton>
            </StyledButton>
            </FlexCol>}
        {editing && <FlexCol>
            <StyledButton onClick={() => CancelEdit(false)} color="danger">
                <FAIconButton icon={["fas", "times"]}></FAIconButton>
            </StyledButton>
            <StyledButton onClick={() => acceptFunction(newValue)} color="primary">
                <FAIconButton  icon={["fas", "save"]}></FAIconButton>
            </StyledButton>
        </FlexCol>
        }
        </Row>
        <Row>
            {editing && <Col>
            <StyledInput onBlur={() => acceptFunction(newValue)} type="select" name={name} id={name} value={newValue} onChange={e => UpdateValue(e.target.value)}>
                            {options.map((option) => (
                                <option  key={option._id} value={option._id}>
                                    {option.name}
                                </option>
                            ))}
                        </StyledInput>
            </Col> }
            {!editing && <Col>{value}</Col>}
        </Row>
        <br />

    </StyledContainer>)
}

export default ProfileSelect;

const StyledContainer = styled(Container)`
    padding-left: 0px;
    padding-right: 0px;
`

const StyledInput = styled(Input)`
appearance: menulist;

&:focus{
    border-color: #372844;
    box-shadow: 0 0.5rem 1rem #9780af;
}
`

const FlexCol = styled(Col)`

`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 0px;
    margin-left: 0px;
`;

const StyledButton = styled(Button)`
    max-width: 40px;
    max-height: 35px;
    margin-left: 5px;
    margin-right:5px;
`;