import React, { useState } from 'react';
import { auth } from '../../services/firebase';
import { Container, Row, Col, Label, Input, Button, Alert, FormFeedback } from 'reactstrap';
import styled from 'styled-components'
import joggingImage from "../../images/MorningRun.png"

const PasswordReset = (props) => {
     const [password, setPassword] = useState({value: "", validity: "empty"});
     const [confirmPassword, setConfirmPassword] = useState({value: "", validity: "empty"});
     const [errorMessage, setErrorMessage] = useState("");
     const [confirmMessage, setConfirmMessage] = useState(false);

    const validatePassword = (value) => {
        if(value.length > 5 && value.match(/\d+/g)){
            setPassword({value: value, validity: "valid"});
        }
        else if(value === ""){
            setPassword({value: value, validity: "empty"});
        }
        else{
            setPassword({value: value, validity: "invalid"})
        }
    }

    const validateComparePassword = (value) =>{
        if(value === password.value){
            setConfirmPassword({value: value, validity: "valid"});
        }
        else if(value ===""){
            setConfirmPassword({value: value, validity: "empty"});
        }
        else{
            setConfirmPassword({value: value, validity: "invalid"});
        }
    }

    const validateForm = () => {
        if(password.validity !== "valid"){
            return false;
        }
        else if(confirmPassword.validity !== "valid"){
            return false;
        }
        return true;
    }

     const doSubmit = () => {
        if(!validateForm) { return; }

        if(auth.currentUser){
            auth.currentUser.updatePassword(password.value)
            .then((userCredential) => {
                setConfirmMessage(true);
            })
            .catch((error) => {
                var errorMessage = error.message;
                setErrorMessage(errorMessage);
            });    
        }
        else{
            var actionCode = props.location.state.actionCode;
            if(!actionCode){
                console.log("You got here not logged in and not from the redirect")
                return;
            }
            auth.verifyPasswordResetCode(actionCode).then((email) => {
                var emailAccount = email;
                auth.confirmPasswordReset(actionCode, password.value).then((resp) => {
                    setConfirmMessage(true);
                }).catch((error) => {
                    console.log(error);
                })
            }).catch((error) => {
                console.log(error);
            })
        }

        
    };

    const goBack = () => {
        window.location = "/personal";
    }

    return (
        <React.Fragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage src={joggingImage} alt="Keep at it!" />
                </ParentDiv>   
            </HeightDiv>
            {!confirmMessage && <StyledContainer>
                <Row>
                    <h1>Password Reset</h1>
                    <hr />
                </Row>
                <Row>
                    <Col>
                        <Label>Password<RedText>*</RedText>:</Label>
                        <StyledInput valid={password.validity === "valid"} invalid={password.validity === "invalid"} type="password" name="password" id="password" placeholder="Password..." onChange={e => validatePassword(e.target.value)} />
                        {password.validity === "invalid" && <FormFeedback invalid>Password must be at least 6 characters long and contain a number</FormFeedback>}
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label>Confirm Password<RedText>*</RedText>:</Label>
                        <StyledInput valid={confirmPassword.validity ==="valid"} invalid={confirmPassword.validity === "invalid"} type="password" name="confirmPassword" id="confirmPassword" placeholder="Confirm Password..." onChange={e => validateComparePassword(e.target.value)} />
                        {confirmPassword.validity === "invalid" && <FormFeedback invalid>Passwords must match</FormFeedback>}
                        <br />
                    </Col>
                </Row>
                <Row>
                    {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                    <Button disabled={!validateForm()} color="primary" onClick={() => {doSubmit()}}>Change Password</Button>
                    <br />
                </Row>
            </StyledContainer>}
            {confirmMessage && <StyledContainer>
                <Row>
                    <h1>Your password has been reset</h1>
                </Row>
                <Row>
                    {auth.currentUser && <Button color="primary" onClick={() => {goBack()}}>Back To Profile</Button>}
                    <br />
                </Row>
            </StyledContainer>     
            }
        </React.Fragment>
    );
}

export default PasswordReset;

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #9780af;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 700px;
    margin-top: 15px;
`

const StyledInput = styled(Input)`
&:focus{
    border-color: #372844;
    box-shadow: 0 0.5rem 1rem #9780af;
}
`

const RedText = styled.b`
    color:Tomato;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;
 