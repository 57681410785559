import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import FileUploadButton from '../../common/formComponents/fileUploadButton';

const AdminStudyUpdateDocument = (props) => {

    const [message, setMessage] = useState('');

    return ( 
        <Container>
            <Row style={{ display:'flex', flexDirection:'row'}}>
                <Col xs='3'>
                    <h5>{props.title}</h5>
                </Col>
                <Col>
                    <FileUploadButton folderPath={props.folderPath} fileName={props.fileName} updateMessage={setMessage}/>
                </Col>
                <Col xs='3'>
                    <p style={{margin:'0px'}}>{message}</p>
                </Col>
            </Row>
        </Container>
    );
}
 
export default AdminStudyUpdateDocument;
