import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled, { keyframes } from 'styled-components';
import { db } from '../../services/firebase';
import { Carousel } from 'react-responsive-carousel';
import Support from '../sitePages/support';
import { GetUserInformation } from '../../services/firestore';
import { auth } from '../../services/firebase';


const CoreGroup = (props) => {
    const [primaryText, setPrimaryText] = useState([]);
    const [coreCarousel, setCoreCarousel] = useState([]);
    const [groupNotes, setGroupNotes] = useState([]);
    const [userProfileId, setUserProfileId] = useState(auth.currentUser.uid);

    const docRef = db.collection("webPages").doc("Core")

    useEffect(() => {
        GetUserInformation(userProfileId, ReturnHomePageInformation);
    }, []);

    const sortList = (list) => {
        list.sort(function(a, b) {
            var textA = parseInt(a.index);
            var textB = parseInt(b.index);
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        return list;
    }

    const ReturnHomePageInformation = (user) =>{
        docRef.onSnapshot((doc) => {
            var data = doc.data();

            setPrimaryText(data.primaryText);
            setCoreCarousel(sortList(data.coreList));

            for(var i = 0; i < data.groupNotes.length; i++)
            {
                if(data.groupNotes[i].group == user.group){
                    setGroupNotes(data.groupNotes[i]);
                }
            }

            setGroupNotes(data.groupNotes[0]);
        });
    }

    return (
        <React.Fragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage />
                </ParentDiv>   
            </HeightDiv>
            {primaryText.length > 0 && <MarginDiv><ContentContainer>
                <Row>
                    {groupNotes &&<CarouselCol md="6">
                        <NoteDiv>
                            <StyledTitle>{groupNotes.title}</StyledTitle>
                            <StyledContent>{groupNotes.content}</StyledContent>
                            {groupNotes.url && <StyledAnchor href={groupNotes.url} target="_blank">Contact Us</StyledAnchor>}
                        </NoteDiv>    
                    </CarouselCol>}
                    <CarouselCol>
                        <StyledCarousel showThumbs={false} autoPlay={true} infiniteLoop={true} interval={6000} showStatus={false}>
                            {coreCarousel.map((entry, i) => (
                                <div key={i}>
                                    {entry.imageUrl && <StyledImg src={entry.imageUrl} />}
                                    <StyledTitle>{entry.title}</StyledTitle>
                                    {entry.content && <StyledContent>{entry.content}</StyledContent>}
                                    <br />
                                </div>
                            ))}  
                        </StyledCarousel>
                    </CarouselCol>
                </Row>
            </ContentContainer></MarginDiv>}
            <Support />
        </React.Fragment>
    );
}

const StyledAnchor = styled.a`
    color: white;
    font-weight: bold;
    text-decoration: underline;
`

const MarginDiv = styled.div`
    margin-left: 15px;
    margin-right: 15px;
`

const ContentContainer = styled(Container)`
    margin-top: 20px;
`

const StyledCarousel = styled(Carousel)`
  max-height: auto;
  min-height: 150px;
  background-color: #372844;
  color: white;
`

const NoteDiv = styled.div`
max-height: auto;
min-height: 150px;
background-color: #372844;
color: white;
text-align: center;
padding-bottom: 15px;
`

const StyledImg = styled.img`
`

const StyledTitle = styled.h4`
    padding-top: 10px;
`

const StyledContent = styled.p`
    padding-left: 30px;
    padding-right: 30px;
`

const fadeInAnimation = keyframes`
    from {
        opacity: 0;
    }
    to {
       
        opacity: 1;
    }
`;

const CarouselCol = styled(Col)`
    background-color: white;
    border: solid;
    border-color: #9780af #372844 #372844 #9780af;
    justify-content: center;
    margin: auto;
    padding: 10px;
    max-width: 600px;
    animation: ${fadeInAnimation} 2s ${props => props.delay + "s"} both;
    box-shadow: 0 0.5rem 1rem #372844;
    font-size: 18px;
`

const StyledBackgroundImage = styled.div`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #dddddd;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;
 
export default CoreGroup;