import React from 'react';
import styled from 'styled-components'
import { Button } from 'reactstrap';
import { moduleStrings } from './moduleStrings';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ModuleButton = (props) => {
    //file, user, modulestring, StartFunction, CompleteFunction

    return (<div>
        <StyledButton onClick={() => props.StartFunction(props.modulestring)} href={props.file} download color="primary">
            <FAIconButton  icon={["fas", "download"]}></FAIconButton>
        </StyledButton>
        <b> <u>{props.modulestring}</u></b>
        {props.user && props.user.modules.find(obj => {return obj.moduleName === props.modulestring && !obj.completed}) && 
        <CompleteModuleButton onClick={() => props.CompleteFunction(props.modulestring)} color="primary">
            <FAIconButton  icon={["fas", "check"]}></FAIconButton>
        </CompleteModuleButton>}
        {props.user && props.user.modules.find(obj => {return obj.moduleName === props.modulestring && obj.completed}) && 
            <StarIcon icon={["fas", "star"]}></StarIcon>}
    </div> );
}
 
export default ModuleButton;

const CompleteModuleButton = styled(Button)`
    min-width: 40px;
    min-height: 35px;
    margin-left: 5px;
    background-color: red;
    margin-left: 50px;
    &:hover{
        background-color: green;
    }
`;

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 0px;
    margin-left: 0px;
`;

const StarIcon = styled(FontAwesomeIcon)`
    margin-right: 0px;
    margin-left: 15px;
    color: gold;
`;

const StyledButton = styled(Button)`
    min-width: 40px;
    min-height: 35px;
    margin-left: 5px;
`;