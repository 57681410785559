import React, { useState } from 'react';
import { Container, Row, Col, Label, Input, Button, Form } from 'reactstrap';
import { auth } from '../../services/firebase'
import styled from 'styled-components'
import groupImage from "../../images/MorningRun.png"

//TODO pressing enter does not prompt the button to login, perhaps needs to be part of a form component?
const Login = (props) => {
     const [email, setEmail] = useState("");
     const [password, setPassword] = useState("");
     const [errorMessage, setErrorMessage] = useState("");

    const checkUser = () => {
        if (props.user === "Guest") {
            //TODO: User is guest, they're not logged in.
        }
        else{
            if (props.user.firstTimeLogin || props.user.firstTimeLogin === undefined){
                window.location = "/first-time";
            }
            else{
                window.location = "/exercise-groups";
            }
        }
    }

    checkUser();

     const doSubmit = async (event) => {
         event.preventDefault();
          const data = {
              email: email,
              password: password,
          }
          await auth.signInWithEmailAndPassword(data.email, data.password)
          .then((userCredential) => {
            console.log("Props after logging in.");
            console.log(props);
            //TODO: We want to switch to a first time login page if the user has not logged in before
            //window.location = props.history.location.state ? props.history.location.state.from.pathname : "/exercise-groups";
        })
          .catch((error) => {
            var errorMessage = error.message;
            console.log("Something went wrong" + errorMessage);
            setErrorMessage("Incorrect Login Attempt");
          });    
      };

    return (
        <StyledFragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage src={groupImage} alt="Keep at it!" />
                </ParentDiv>   
            </HeightDiv>
            <StyledContainer>
                
                    <Row>
                        <Col>
                            <h1>Login</h1>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col>
                            <Label>Email:</Label>
                            <Form onSubmit={doSubmit}>
                                <StyledInput name="email" id="email" placeholder="Email..." onChange={e => setEmail(e.target.value)} />
                            </Form>
                            <br />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>Password:</Label>
                            <Form onSubmit={doSubmit}>
                                <StyledInput name="password" type="password" id="password" placeholder="Password..." onChange={e => setPassword(e.target.value)} />
                            </Form>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>{errorMessage && <div className="alert alert-danger">{errorMessage}</div>}</Col>
                    </Row>
                    
                    <Row>
                    <Button color="primary" onClick={doSubmit}>Login</Button>
                    </Row>
                    <br />
                    < Row>
                        <p>Forgot your <a href="/lost-password">password</a>?</p>
                        {/*<p><a href="/register">Need an account</a>?</p>*/}
                    </Row>
                
            </StyledContainer> 
        </StyledFragment>
    );
}

export default Login;

const StyledInput = styled(Input)`
&:focus{
    border-color: #372844;
    box-shadow: 0 0.5rem 1rem #9780af;
}
`

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #9780af;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 500px;
    margin-top: 15px;
`

const StyledFragment = styled.div`
    height: 100%;
    min-height: ${window.innerHeight - 255}px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;
 