import React, { useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { DeleteTeamMember } from "../../../services/firestore.js";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BlankUser from "../../../images/BlankUser.jpg"

const AdminTeamMemberRow = ({member }) => {
    const [hovered, setHovered] = useState(false);

    const RefreshWindow = () => {
        window.location = "/team-list"
    }

    return ( 
        <StyledRow onMouseEnter={() => setHovered(true)} onMouseLeave={()=> setHovered(false)} key={member.id}>
            <Col>
                {member.imageUrl && <StyledProfileImage src={member.imageUrl} alt={member.name} />}
                {!member.imageUrl && <StyledProfileImage src={BlankUser} alt={member.name} />}
            </Col>
            <Col lg="3" md="4">
                <h4>{member.name}</h4>
            </Col>
            <Col lg="4" md="4">
                {member.specialization}
            </Col>
            <Col>
                {member.location}
            </Col>
            <Col >
            {hovered && 
                <StyledButton href={member.profileurl} color="primary">
                    <FAIconButton  icon={["fas", "edit"]}></FAIconButton>
                </StyledButton>
                }</Col>
                <Col>
            {hovered && 
                <StyledButton onClick={() => DeleteTeamMember(member.id, RefreshWindow)} color="danger">
                    <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                </StyledButton>
            
            }
            </Col>
            <hr />
        </StyledRow> 
    );
}
 
export default AdminTeamMemberRow;

const StyledProfileImage = styled.img`
    height: 80px;
    border-radius: 50px;
`

const StyledRow = styled(Row)`
    height: auto;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;

const StyledButton = styled(Button)`
    margin-left: 5px;
`;