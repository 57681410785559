import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Input, Button, Label, FormFeedback } from 'reactstrap';
import * as aboutme from "../../libraries/aboutme"
import styled from 'styled-components';
import groupImage from "../../images/MorningRun.png";
import { updateDocumentProperty, updateDocument } from '../../services/firestore';
import { db } from '../../services/firebase';
import { days, years, months } from '../../libraries/dates';

const FirstTimeProfileInformation = (props) => {
    const [username, setUsername] = useState("");
    const [currentName, setCurrentName] = useState(props.user.username);

    const [day, setDay] = useState(days[0]._id );
    const [month, setMonth] = useState(months[0]._id);
    const [year, setYear] = useState(years[0]._id);
    const [postalCode, setPostalCode] = useState({value: "", validity: "empty"});
    const [validAge, setValidAge] = useState("");
    const [activities, setActivities] = useState({
        "Aerobics":false,
        "Cycling":false,
        "Hiking":false,
        "Stair Climbing":false,
        "Team Sports":false,
        "Cross-country skiing":false,
        "Dancing":false,
        "Running":false,
        "Swimming":false,
        "Walking":false,
    });
    const [otherfavorites, setOtherFavorites] = useState("");
    const [fitnesslevel, setFitnessLevel] = useState("");
    const [activereason, setActiveReason] = useState({
        "Weight Loss": false,
        "Reduce Fatigue": false,
        "Improve Physical Function": false,
        "Improve cardiovascular health": false,
        "Reduce stress": false,
        "Social Support": false,
        "Improve emotional health": false,
        "Sense of Accomplishment": false,
        "Return to work / school": false,
        "Improve cognitive function": false,
});
    const [fitnesslocation, setFitnessLocation] = useState({
        "Indoor":false,
        "Outdoor":false,
        "Home":false,
        "Gym":false,
        "Community Center":false,
        "Other":false,
    });
    const [fitnesstime, setFitnessTime] = useState({
        "Morning":false,
        "Afternoon":false,
        "Evening":false,
        "Weekdays":false,
        "Weekends":false,
    });
    const [reason, setReason] = useState("");
    const [personality, setPersonality] = useState("");
    const [worksituation, setWorkSituation] = useState("");
    const [aboutuser, setAboutUser] = useState("");

    const [shareActivities, setShareActivities] = useState(true);
    const [shareFitness, setShareFitness] = useState(true);
    const [shareReasons, setShareReasons] = useState(true);
    const [shareLocation, setShareLocation] = useState(true);
    const [shareTime, setShareTime] = useState(true);
    const [sharePartnerReason, setSharePartnerReason] = useState(true);
    const [sharePersonality, setSharePersonality] = useState(true);
    const [shareWork, setShareWork] = useState(true);
    const [shareAbout, setShareAbout] = useState(true);

    useEffect(() => {
        if (props.user.postalCode !== undefined && props.user.postalCode !== ""){
            validatePostalCode(props.user.postalCode);
        }
        if (props.user.dateofbirth !== undefined && props.user.dateofbirth !== ""){
            setDay(props.user.dateofbirth.day);
            setMonth(props.user.dateofbirth.month);
            setYear(props.user.dateofbirth.year);
            validateDay(props.user.dateofbirth.day);
            validateMonth(props.user.dateofbirth.month);
            validateYear(props.user.dateofbirth.year);
        }
    }, [])

    useEffect(() => {
        updateDocumentProperty(db, "users", props.user.uid, "firstTimeLogin", false);
    }, [])

    const UpdateUserName = () => {
        if (username === ""){
            return;
        }
        updateDocumentProperty(db, "users", props.user.uid, "username", username);
        setCurrentName(username);
    }

    const validatePostalCode = (value) => {
        const re = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
        if(re.test(String(value).toLowerCase())){
            setPostalCode({value: value, validity: "valid"});
        }
        else if(value === ""){
            setPostalCode({value: value, validity: "empty"});
        }
        else{
            setPostalCode({value: value, validity: "invalid"});
        }
    }
        
    const validateDay = (value) => {
        setDay(value);
        validateAge(value, "day");
    }

    const validateMonth = (value) => {
        setMonth(value);
        validateAge(value, "month");
    }

    const validateYear = (value) => {
        setYear(value);
        validateAge(value, "year");
    }

    const validateAge = (value, property) =>{
        var testYear = year;
        var testMonth = month;
        var testDay = day;
        
        if(property === "year"){
            testYear  = value;
        }
        else if(property === "month"){
            testMonth = value;
        }
        else{
            testDay = value;
        }
        
        var dob = new Date();
        dob.setFullYear(testYear, testMonth, testDay);
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms);
        
        var age = Math.abs(age_dt.getUTCFullYear() - 1970);

        if(age < 18){
            setValidAge("invalid");
        }
        else{
            setValidAge("valid");
        }
    }

    const HandleActivitiesCheckbox = (value) =>{
        var activitiesArray = activities;

        const match = activitiesArray.find(element => {
            if (element.includes(value)) {
              return true;
            }
          });

        if(!match){
            activitiesArray.push(value);
        }
        else{
            for(let i = 0; i < activitiesArray.length; i++){
                if(activitiesArray[i] === value){
                    activitiesArray.splice(i, 1);
                }
            }
        }

        setActivities(activitiesArray);
     }

     const HandleReasonsCheckbox = (value) => {
         var reasonsArray = activereason;

         
        const match = reasonsArray.find(element => {
            if (element.includes(value)) {
              return true;
            }
          });

         if(!match){
             if(reasonsArray.length < 3){
                reasonsArray.push(value);
              }
         }
         else{
             for(let i = 0; i < reasonsArray.length; i++){
                 if(reasonsArray[i] === value){
                     reasonsArray.splice(i, 1);
                 }
             }
         }

         setActiveReason(reasonsArray);
     }

     const HandleFitnessLocationCheckbox = (value) => {
        var reasonsArray = fitnesslocation;

        
       const match = reasonsArray.find(element => {
           if (element.includes(value)) {
             return true;
           }
         });

        if(!match){
            reasonsArray.push(value);
        }
        else{
            for(let i = 0; i < reasonsArray.length; i++){
                if(reasonsArray[i] === value){
                    reasonsArray.splice(i, 1);
                }
            }
        }

        setFitnessLocation(reasonsArray);
    }

    const HandleFitnessTimeCheckbox = (value) => {
        var reasonsArray = fitnesstime;

        
       const match = reasonsArray.find(element => {
           if (element.includes(value)) {
             return true;
           }
         });

        if(!match){
            reasonsArray.push(value);
        }
        else{
            for(let i = 0; i < reasonsArray.length; i++){
                if(reasonsArray[i] === value){
                    reasonsArray.splice(i, 1);
                }
            }
        }

        setFitnessTime(reasonsArray);
    }

    const nextPage = () => {
        if (validAge === "valid" && postalCode.validity === "valid")
        {
            let user = props.user;
            user.firstPageComplete = true;
            user.shareActivities = shareActivities;
            user.shareFitness = shareFitness;
            user.shareReasons = shareReasons;
            user.shareLocation = shareLocation;
            user.shareTime = shareTime;
            user.sharePartnerReason = sharePartnerReason;
            user.sharePersonality = sharePersonality;
            user.shareWork = shareWork;
            user.shareAbout = shareAbout;

            user.activities = activities;
            user.otherfavorites = otherfavorites;
            user.fitnesslevel = fitnesslevel;
            user.activereason = activereason;
            user.fitnesslocation = fitnesslocation;
            user.fitnesstime = fitnesstime;
            user.reason = reason;
            user.personality = personality;
            user.worksituation = worksituation;
            user.aboutuser = aboutuser;

            user.postalCode = postalCode.value;
            user.dateofbirth = {day: day, month: month, year: year};
            updateDocument(db, "users", props.user.uid, user);
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0;
        }
        else{
            console.log(validAge);
            console.log(postalCode.validity);
        }
    }

    const ToggleStateCheckbox = (id, checked, state, stateSetter) => {
        let valueObj = state;
        valueObj[id] = checked;
        stateSetter(valueObj);
    }

    /*
    const ToggleReason = (id, checked) => {
        let valueObj = activereason;
        valueObj[id] = checked;
        setActiveReason(valueObj);
    }

    const ToggleFitnessLocation = (id, checked) => {
        let valueObj = activereason;
        valueObj[id] = checked;
        setActiveReason(valueObj);
    }

    const ToggleFitnessTime = (id, checked) => {
        let valueObj = activereason;
        valueObj[id] = checked;
        setActiveReason(valueObj);
    }
    */

    return (
        <StyledFragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage src={groupImage} alt="Keep at it!" />
                </ParentDiv>   
            </HeightDiv>

            <StyledContainer>
                <Row>
                    <h1 style={{textAlign:"center"}}>Profile Information</h1>
                </Row>
                <Row>
                    <p>
                        Welcome to HIMALAYAStrial.ca! In order to increase your chances of finding an exercise partner, we encourage you 
                        to fill out as much information as possible in the following steps.
                    </p>
                </Row>
                <Row>
                    <h4 style={{textAlign:"center"}}>Private Information</h4>
                </Row>
                <Row>
                    <Label style={{display:"flex"}}>
                        <RedText>*</RedText>
                        <BoldP>Date of birth:</BoldP>
                    </Label>
                    <div>
                        <ExplainingP>
                            We don't display your full date of birth. We only use this to calculate and display your age to help find your exercise partner.
                        </ExplainingP>
                    </div>
                    <Col>
                        <StyledInput valid={validAge === "valid"} invalid={validAge === "invalid"} type="select" name="day" id="day" value={day} onChange={e => validateDay(e.target.value)}>
                            {days.map((day) => (
                                <option  key={day._id} value={day._id}>
                                    {day.name}
                                </option>
                            ))}
                        </StyledInput>
                        {validAge === "invalid" && <FormFeedback invalid>Must be over 18 to use service</FormFeedback>}
                    </Col>
                    <Col>
                        <StyledInput valid={validAge === "valid"} invalid={validAge === "invalid"} type="select" name="month" id="month" value={month} onChange={e => validateMonth(e.target.value)}>
                            {months.map((month) => (
                                <option  key={month._id} value={month._id}>
                                    {month.name}
                                </option>
                            ))}
                        </StyledInput>
                    </Col>
                    <Col>
                        <StyledInput valid={validAge === "valid"} invalid={validAge === "invalid"} type="select" name="year" id="year" value={year} onChange={e => validateYear(e.target.value)}>
                            {years.map((year) => (
                                <option  key={year._id} value={year._id}>
                                    {year.name}
                                </option>
                            ))}
                        </StyledInput>    
                    </Col>
                    <br />
                </Row>
                <br />
                <Row>
                    <Col>
                        <Label for="postalcode" style={{display:"flex"}}>
                            <RedText>*</RedText>
                            <BoldP>Postal Code:</BoldP>
                        </Label>
                        <Label><ExplainingP>Postal code will be kept private, but will be used by our system to find exercise partners near you.</ExplainingP></Label>
                        <Label><ExplainingP>Ex: A1A 2B2</ExplainingP></Label>
                        <StyledInput valid={postalCode.validity === "valid"} invalid={postalCode.validity === "invalid"} name="postalcode" id="postalcode" value={postalCode.value} onChange={e => validatePostalCode(e.target.value)}/>
                        {postalCode.validity === "invalid" && <FormFeedback invalid>Must be a valid postal code</FormFeedback>}
                    </Col>
                </Row>
                <br />
                <Row>
                    <h4 style={{textAlign:"center"}}>Public or Private Information</h4>
                    <StyledP>You can choose what information will be shared.</StyledP>
                </Row>
                <br />
                <Row >
                    <Col>
                        <Label><BoldP>Update Username</BoldP></Label>
                    </Col>
                </Row>
                <Row >
                    <Col>Current Username: {currentName}</Col>
                </Row>
                <Row>
                    <Col>
                        <ExplainingP>This is what other users will see as your name on the site.</ExplainingP>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <StyledInput value={username} onChange={(e) => {setUsername(e.target.value)}}></StyledInput>
                    </Col>
                </Row>
                <div style={{textAlign:"center"}}>
                    <Button color="primary" onClick={() => UpdateUserName()}>Set New Username</Button>
                </div>
                <br />
                <Row>
                    <Col>
                        <Label><BoldP>What are your favorite activities?</BoldP></Label>
                        <div style={{backgroundColor:"lavender", borderRadius:"15px", paddingRight:"10px", textAlign:"right"}}>
                            <Label style={{marginRight:"10px"}}>Share in profile</Label>
                            <Input onClick={() => setShareActivities(!shareActivities)} checked={shareActivities} style={{marginRight:"10px"}} type="checkbox" />
                        </div>
                        {aboutme.activities.map((act) => (
                            <div key={act._id}>
                                {activities !== undefined && <Input type="checkbox" value={activities[act._id]} onChange={e => ToggleStateCheckbox(act._id, e.target.checked, activities, setActivities)}/>}
                                <Label style={{marginLeft:"8px"}}>
                                   {act.name}
                                </Label>
                            </div>
                        ))}
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Label><BoldP>Do you have any other favorite activities?</BoldP></Label>
                        <StyledInput maxlength="280" name="otherfavorites" id="otherfavorites" placeholder="other activities..." onChange={e => setOtherFavorites(e.target.value)} />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <Label><BoldP>What is your fitness level?</BoldP></Label>
                        <div style={{backgroundColor:"lavender", borderRadius:"15px", paddingRight:"10px", textAlign:"right", marginBottom:"10px"}}>
                            <Label style={{marginRight:"10px"}}>Share in profile</Label>
                            <Input onClick={() => setShareFitness(!shareFitness)} checked={shareFitness} style={{marginRight:"10px"}} type="checkbox" />
                        </div>
                        <StyledInput type="select" name="fitnesslevel" id="fitnesslevel" onChange={e => setFitnessLevel(e.target.value)}>
                            {aboutme.fitnesslevel.map((level) => (
                                <option  key={level._id} value={level._id}>
                                    {level.name}
                                </option>
                            ))}
                        </StyledInput>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Label><BoldP>Identify your top three reasons for wanting to become more active:</BoldP></Label>
                        <div style={{backgroundColor:"lavender", borderRadius:"15px", paddingRight:"10px", textAlign:"right"}}>
                            <Label style={{marginRight:"10px"}}>Share in profile</Label>
                            <Input onClick={() => setShareReasons(!shareReasons)} checked={shareReasons} style={{marginRight:"10px"}} type="checkbox" />
                        </div>
                        {aboutme.activereasons.map((reason) => (
                            <div key={reason._id}>
                                {activereason !== undefined && <Input type="checkbox" value={activereason[reason._id]} onChange={e => ToggleStateCheckbox(reason._id, e.target.checked, activereason, setActiveReason)}/>}
                                <Label style={{marginLeft:"8px"}}>
                                   {reason.name}
                                </Label>
                            </div>
                        ))}
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Label><BoldP>Where do you prefer to be active?</BoldP></Label>
                        <div style={{backgroundColor:"lavender", borderRadius:"15px", paddingRight:"10px", textAlign:"right"}}>
                            <Label style={{marginRight:"10px"}}>Share in profile</Label>
                            <Input onClick={() => setShareLocation(!shareLocation)} checked={shareLocation} style={{marginRight:"10px"}} type="checkbox" />
                        </div>
                        {fitnesslocation !== undefined && Object.entries(fitnesslocation).map((fitLoc) => (
                            <div key={fitLoc[0]}>
                                <Input type="checkbox" value={fitnesslocation[fitLoc[0]]} onChange={e => ToggleStateCheckbox(fitLoc[0], e.target.checked, fitnesslocation, setFitnessLocation)}/>
                                <Label style={{marginLeft:"8px"}}>
                                   {fitLoc[0]}
                                </Label>
                            </div>
                        ))}
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Label><BoldP>When do you prefer to be active?</BoldP></Label>
                        <div style={{backgroundColor:"lavender", borderRadius:"15px", paddingRight:"10px", textAlign:"right"}}>
                            <Label style={{marginRight:"10px"}}>Share in profile</Label>
                            <Input onClick={() => setShareTime(!shareTime)} checked={shareTime} style={{marginRight:"10px"}} type="checkbox" />
                        </div>
                        {fitnesstime !== undefined && Object.entries(fitnesstime).map((fitTime) => (
                            <div key={fitTime[0]}>
                                <Input type="checkbox" value={fitnesstime[fitTime[0]]} onChange={e => ToggleStateCheckbox(fitTime[0], e.target.checked, fitnesstime, setFitnessTime)}/>
                                <Label style={{marginLeft:"8px"}}>
                                   {fitTime[0]}
                                </Label>
                            </div>
                        ))}
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Label><BoldP>Please list some reasons why you may want an exercise partner:</BoldP></Label>
                        <div style={{backgroundColor:"lavender", borderRadius:"15px", paddingRight:"10px", textAlign:"right", marginBottom:"10px"}}>
                            <Label style={{marginRight:"10px"}}>Share in profile</Label>
                            <Input onClick={() => setSharePartnerReason(!sharePartnerReason)} checked={sharePartnerReason} style={{marginRight:"10px"}} type="checkbox" />
                        </div>
                        <StyledInput type="textarea" maxlength="280" name="reason" id="reason" placeholder="Partner Reason..." onChange={e => setReason(e.target.value)} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Label><BoldP>Which of the following best describes you?</BoldP></Label>
                        <div style={{backgroundColor:"lavender", borderRadius:"15px", paddingRight:"10px", textAlign:"right", marginBottom:"10px"}}>
                            <Label style={{marginRight:"10px"}}>Share in profile</Label>
                            <Input onClick={() => setSharePersonality(!sharePersonality)} checked={sharePersonality} style={{marginRight:"10px"}} type="checkbox" />
                        </div>
                        <StyledInput type="select" name="personality" id="personality" onChange={e => setPersonality(e.target.value)}>
                                {aboutme.personality.map((personality) => (
                                    <option key={personality._id} value={personality._id}>
                                        {personality.name}
                                    </option>
                                ))}
                        </StyledInput>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Label><BoldP>Which of the following best describes your work situation?</BoldP></Label>
                        <div style={{backgroundColor:"lavender", borderRadius:"15px", paddingRight:"10px", textAlign:"right", marginBottom:"10px"}}>
                            <Label style={{marginRight:"10px"}}>Share in profile</Label>
                            <Input onClick={() => setShareWork(!shareWork)} checked={shareWork} style={{marginRight:"10px"}} type="checkbox" />
                        </div>
                        <StyledInput type="select" name="worksituation" id="worksituation" onChange={e => setWorkSituation(e.target.value)}>
                                {aboutme.worksituation.map((worksituation) => (
                                    <option key={worksituation._id} value={worksituation._id}>
                                        {worksituation.name}
                                    </option>
                                ))}
                        </StyledInput>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Label><BoldP>About you. Tell us about yourself!</BoldP></Label>
                        <div style={{backgroundColor:"lavender", borderRadius:"15px", paddingRight:"10px", textAlign:"right", marginBottom:"10px"}}>
                            <Label style={{marginRight:"10px"}}>Share in profile</Label>
                            <Input onClick={() => setShareAbout(!shareAbout)} checked={shareAbout} style={{marginRight:"10px"}} type="checkbox" />
                        </div>
                        <StyledInput maxlength="280" type="textarea" name="aboutuser" id="aboutuser" onChange={e => setAboutUser(e.target.value)}/>
                    </Col>
                </Row>
                <br />
                <div style={{textAlign:"right"}}>
                    <Button color="primary" onClick={() => {nextPage()}}>Save & Continue</Button>
                </div>
            </StyledContainer>
        </StyledFragment>
    );
}

export default FirstTimeProfileInformation;

const StyledP = styled.p`
    margin: 0px;
`;

const BoldP = styled.p`
    font-weight: bold;
    font-size: 16px;
    color: #372844;
    margin-top: 0px;
    margin-bottom: 0px;
`;

const ExplainingP = styled.p`
    font-size: 14px;
    color: #372844;
    margin-top: 0px;
    margin-bottom: 0px;
    font-style: italic;
`;

const RedText = styled.b`
    color:Tomato;
`

const StyledInput = styled(Input)`
    margin-bottom: 10px;
    &:focus{
        border-color: #372844;
        box-shadow: 0 0.5rem 1rem #9780af;
    }
`

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #9780af;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 500px;
    margin-top: 15px;
`

const StyledFragment = styled.div`
    height: 100%;
    min-height: ${window.innerHeight - 255}px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;
 