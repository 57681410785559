import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { DeleteUser, UpdateUserInformation } from '../../services/firestore';
import { auth, db } from '../../services/firebase';
import blankUser from '../../images/BlankUser.jpg';
import styled from 'styled-components'
import ProfileInput from "./profileEditFields/profileInput";
import ProfileSelect from './profileEditFields/profileSelect';
import * as aboutme from "../../libraries/aboutme";
import * as cancerinfo from "../../libraries/cancerhistory";
import ProfileCheck from './profileEditFields/profileCheck';
import { GetLongituteAndLatitute } from '../../services/helper';

const PersonalProfile = () => {
    const [currentUser, setCurrentUser] = useState({});
    const [userProfile, setProfile] = useState({});
    const [userProfileId, setUserProfileId] = useState(auth.currentUser.uid);
    const [age, setAge] = useState("");
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [cities, setCities] = useState([]);
    const [confirmButtonOn, setConfirmButtonOn] = useState(false);

    const cityRef = db.collection("webPages").doc("global")

    useEffect(() => {
       ReturnGlobalPageInformation();   
   }, []);

   
   const ReturnGlobalPageInformation = () =>{
       cityRef.onSnapshot((doc) => {
           const data = doc.data();
           if(doc.data()){
               if(data.primaryText){
                   setCities(data.primaryText);
               }
           }
       });
   }
        
    const toggle = () => setModal(!modal);
    var userRef = db.collection("users");

     useEffect(() => {
        GetCurrentlyViewedProfileInformation(userProfileId, mapToViewModel);
      }, []);

      const GetCurrentlyViewedProfileInformation = (userid, successcallback) => {
        userRef.doc(userid).get().then(function(doc) {
        if (doc.exists) {
            db.collection("users").doc(userid)
            .onSnapshot((doc) => {
                successcallback(doc.data());
            });
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            window.location = "/not-found"
        }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }

    const mapToViewModel = (user) => {
        if(!user.activities){
            user.activities = [];
        }
        setProfile(user);
        setCurrentUser(user);
        
        var dateofbirth = "N/A";

        if(user.dateofbirth){
            dateofbirth = new Date(user.dateofbirth.year, user.dateofbirth.month, user.dateofbirth.day);
            setAge(calculateAge(dateofbirth));
        }
     }

    const calculateAge = (dob) => {     
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms);

        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    const ValidatePostalCode = (postalCode) => {
        const re = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
        if(re.test(String(postalCode).toLowerCase())){
            return true;
        }
        else{
            setModalTitle("Invalid Postal Code");
            setModalMessage("Please enter a valid postal code");
            setCurrentUser(currentUser);
            setConfirmButtonOn(false);
            toggle();
            return false;
        }
    }

    const GetCoordinates = (newProperties) => {
        GetLongituteAndLatitute(newProperties, CombineCoordinates);
    }

    const CombineCoordinates = (coordinates, newProperties) =>{
        var newCoordinates;
        if(coordinates.postalcodes.length === 0){ 
            newCoordinates = "N/A"
        }
        else{
            var newCoordinates = {lat: coordinates.postalcodes[0]["lat"], lng: coordinates.postalcodes[0]["lng"]};
        }
        newProperties["coordinates"] = newCoordinates;
        EditProfileField(newProperties);  
    }

    const EditProfileField = (newProperties) => {
            UpdateUserInformation(userProfileId, newProperties);
    }

    const PromptUserArchive = () => {
        setModalTitle("Delete Account?");
        setModalMessage("You will no longer be able to access the HIMALAYAS services");
        setConfirmButtonOn(true);
        toggle();
    }

    return (
        <React.Fragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackground />
                </ParentDiv>   
            </HeightDiv>
            <MarginDiv>
                <StyledContainer>
                    <br />
                    <StyledModal isOpen={modal}>
                        <ModalHeader>{modalTitle}</ModalHeader>
                            <ModalBody>
                                {modalMessage}
                            </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggle}>Cancel</Button>
                            {confirmButtonOn && <Button color="primary" onClick={() => DeleteUser(auth.currentUser.uid)}>Confirm</Button>}
                        </ModalFooter>
                    </StyledModal>
                    <Row>
                        <AccountCol xs="12" md="5" lg="4" xl="3">
                            {/*
                            {userProfile.imageurl && <ProfileImage src={userProfile.imageurl} alt={userProfile.username} />}
                            {!userProfile.imageurl && <div><br /><ProfileImage src={blankUser} alt={userProfile.username} /></div>}
                            */}
                            <div><br /><ProfileImage src={blankUser} alt={userProfile.username} /></div>
                            <h1><br />{userProfile.username}</h1>
                            {age && <StyledParagraph>{userProfile.username} is {age} years old</StyledParagraph>}
                            {userProfile.isAdmin && <div><br /><StyledParagraph>HIMALAYAS Administrator</StyledParagraph></div>}
                            <br />
                            <h3>Account Actions</h3>
                            <hr />
                            {/*
                            <StyledButton href={"/photo-upload"} color="primary">Upload Photo</StyledButton>
                            <br />
                            <br />
                            */}
                            <StyledButton href={"/lost-password"} color="primary">Change Password</StyledButton>
                            <br />
                            <br />
                            <StyledButton onClick={PromptUserArchive} color="danger">Delete Account</StyledButton>
                            <br />
                            <br />
                        </AccountCol>
                        <Col>
                            <InputContainer fluid={true}>
                                <hr />
                                <Row>
                                    <Col xl="6"  xs="12">
                                    <ProfileInput
                                        user={currentUser}
                                        propertyName="recentachievement"  
                                        label={"Recent Achievement:"}
                                        value={userProfile.recentachievement} 
                                        name="recentachievement"
                                        editFunction={EditProfileField}
                                        type="textarea"
                                        tooltip={true}
                                        tooltipText="Tell us about something you have done for your health recently that you are proud of. (max 280 characters)"
                                    />
                                    <ProfileInput
                                        user={currentUser}
                                        propertyName="aboutuser"  
                                        label={userProfile.username + "'s Self Description:"}
                                        value={userProfile.aboutuser} 
                                        name="aboutuser"
                                        editFunction={EditProfileField}
                                        type="textarea"
                                        tooltip={true}
                                        tooltipText="Anything goes…Who are you? What are you into? What gets you excited? (max 280 characters)"
                                    />
                                    </Col>
                                    <Col>
                                        <ProfileSelect
                                        user={currentUser}
                                        propertyName="city"  
                                        label="City:" 
                                        value={userProfile.city} 
                                        name="city"
                                        editFunction={EditProfileField}
                                        options={cities}
                                        />

                                        <ProfileInput
                                        user={currentUser}
                                        propertyName="postalCode"  
                                        label="Postal Code:" 
                                        value={userProfile.postalCode} 
                                        name="postalCode"
                                        editFunction={GetCoordinates}
                                        validationFunction={ValidatePostalCode}
                                        />
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                <h3><u>Exercise History</u></h3> 
                                    <Col xl="6" xs="12">
                                        <ProfileInput
                                        user={currentUser}
                                        type="textarea"
                                        propertyName="reasonforpartner"  
                                        label="Reason for Wanting Partner:" 
                                        value={userProfile.reasonforpartner} 
                                        name="reasonforpartner"
                                        editFunction={EditProfileField}
                                        tooltip={true}
                                        tooltipText="Please list the ways in which you think having an exercise partner may help you achieve your exercise goals? 
                                        Please rank your responses in order of importance if you list more than one benefit."
                                        placeholderText={"Example: Motivation, Accountability, Building My Community, Venting"}
                                        />

                                        <ProfileSelect
                                        user={currentUser}
                                        propertyName="personality"  
                                        label="Personality:" 
                                        value={userProfile.personality} 
                                        name="personality"
                                        editFunction={EditProfileField}
                                        options={aboutme.personality}
                                        tooltip={true}
                                        tooltipText="Which of these best describes you?"
                                        />
                                        <ProfileCheck
                                        user={currentUser}
                                        propertyName="activities"  
                                        label="Favorite Activities:" 
                                        value={userProfile.activities} 
                                        name="favorites"
                                        editFunction={EditProfileField}
                                        options={aboutme.activities}
                                        tooltip={true}
                                        tooltipText="Please list your favourites."
                                        />

                                        <ProfileInput
                                        user={currentUser}
                                        propertyName="otherfavorites"  
                                        label="Other Favorite Activities:" 
                                        value={userProfile.otherfavorites} 
                                        name="otherfavorites"
                                        editFunction={EditProfileField}
                                        tooltip={true}
                                        tooltipText="Did we miss some? What other activities do you love?"
                                        />
                                    </Col>
                                    <Col>
                                        <ProfileCheck
                                        user={currentUser}
                                        propertyName="activereason"  
                                        label="Benefits of Having an Exercise Partner:" 
                                        value={userProfile.activereason} 
                                        name="activereason"
                                        editFunction={EditProfileField}
                                        options={aboutme.activereasons}
                                        limit={3}
                                        />
                                        
                                        <ProfileSelect
                                        user={currentUser}
                                        propertyName="fitnesslevel"  
                                        label="Exercise / Activity Level:" 
                                        value={userProfile.fitnesslevel} 
                                        name="fitnesslevel"
                                        editFunction={EditProfileField}
                                        options={aboutme.fitnesslevel}
                                        tooltip={true}
                                        tooltipText="Help find great exercise partner matches by indicating how active you are."
                                        />
                                        <ProfileCheck
                                        user={currentUser}
                                        propertyName="fitnesslocation"  
                                        label="Preferred Exercise Location:" 
                                        value={userProfile.fitnesslocation} 
                                        name="fitnesslocation"
                                        editFunction={EditProfileField}
                                        options={aboutme.activitylocation}
                                        tooltip={true}
                                        tooltipText="Where do you like to exercise? (Choose all that apply)"
                                        />
                            
                                        <ProfileCheck
                                        user={currentUser}
                                        propertyName="fitnesstime"  
                                        label="Preferred Exercise Time:" 
                                        value={userProfile.fitnesstime} 
                                        name="fitnesstime"
                                        editFunction={EditProfileField}
                                        options={aboutme.activitytime}
                                        tooltip={true}
                                        tooltipText="When do you like to exercise? OR What is the best time of day for you to exercise? (Choose all that apply)" />
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <h3><u>Cancer History</u></h3>
                                    <Col xs="12" xl="6">
                                        <ProfileSelect
                                        user={currentUser}
                                        propertyName="cancerlocation"  
                                        label="Recent Cancer Diagnosis:" 
                                        value={userProfile.cancerlocation} 
                                        name="cancerlocation"
                                        editFunction={EditProfileField}
                                        options={cancerinfo.cancerlocation}
                                        tooltip={true}
                                        tooltipText="What was your most recent cancer diagnosis?" />

                                        <ProfileInput
                                        user={currentUser}
                                        propertyName="otherlocation"  
                                        label="Previous Diagnosis:" 
                                        value={userProfile.otherlocation} 
                                        name="otherlocation"
                                        editFunction={EditProfileField}
                                        tooltip={true}
                                        tooltipText="If you were previously diagnosed with a different cancer, please list it here." />

                                        <ProfileSelect
                                        user={currentUser}
                                        propertyName="cancerstatus"  
                                        label="Status of Cancer:" 
                                        value={userProfile.cancerstatus} 
                                        name="cancerstatus"
                                        editFunction={EditProfileField}
                                        options={cancerinfo.cancerstatus} />

                                        </Col>
                                        <Col>
                                        <ProfileInput
                                        user={currentUser}
                                        propertyName="treatmentdescription"  
                                        label="Cancer Treatments:" 
                                        value={userProfile.treatmentdescription} 
                                        name="treatmentdescription"
                                        editFunction={EditProfileField}
                                        tooltip={true}
                                        tooltipText="Please list and describe the treatments you received for your most recent cancer diagnosis."
                                        />

                                        <ProfileInput
                                        user={currentUser}
                                        propertyName="supportgroupname"  
                                        label="Cancer Support Services:" 
                                        value={userProfile.supportgroupname} 
                                        name="supportgroupname"
                                        editFunction={EditProfileField}
                                        tooltip={true}
                                        tooltipText="Please list any cancer support services (e.g. exercise, psychosocial, art, music) you have participated in and the organization that provided them."
                                        />
                                    </Col>
                                </Row>
                            </InputContainer> 
                        </Col>
                    </Row>
                </StyledContainer>
            </MarginDiv>
        </React.Fragment>
    );
}
 
export default PersonalProfile;

const AccountCol = styled(Col)`
    text-align: center;
`

const InputContainer = styled(Container)`
    padding-left: 0px;
    padding-right: 0px;
`

const MarginDiv = styled.div`
    margin-left: 15px;
    margin-right: 15px;
`

const StyledParagraph = styled.p`
    font-size: large;
`

const StyledModal = styled(Modal)`
    position: absolute;
    left: 40%;
    top: 40%;
`

const StyledButton = styled(Button)`
    min-width: 200px;
    border-style: solid;
    border-width: 1.5px;
    border-color: black;
`

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #372844;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    margin-top: 50px;
`

const ProfileImage = styled.img`
    box-shadow: 0 0.5rem 1rem #372844;
    max-width: 100%;
`

const StyledBackground = styled.div`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #dddddd;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;