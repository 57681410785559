import React, { useState } from 'react';
import { ArchiveUser,  UpdateUserInformation } from '../../../services/firestore';
import { Container, Row, Col, Button } from 'reactstrap';
import UserProfileRow from './admin-site-user-profile-row';
import styled from 'styled-components';
import emailjs, { init } from 'emailjs-com';

const AdminUserProfileList = ({ users }) => {

    init("user_8Qt3ZJv9dlnGhiJefkhBh");

    const [currentListSpot, setCurrentListSpot] = useState(0);

    
    const AcceptProfile = (user, group) =>{
        const properties = { isAccountAccepted: true, isArchived: false, group };
        UpdateUserInformation(user.uid, properties);
        if(!user.isArchived){
            emailjs.send("service_jk915bb", "template_xyrjsbs", {
                to_name: user.username,
                email: user.email,
            });
        }
     }

    const RejectProfile = (userID) => {
        ArchiveUser(userID);
    }

    const SetPage = (number) => {
        setCurrentListSpot(number);
    }

    return ( 
        <Container>
            <Row>
            {(users && users.length > 0) && users[currentListSpot].map((user, i) => {return(
                <UserProfileRow key={user.uid} user={user} deleteFunction={RejectProfile} acceptFunction={AcceptProfile} />)
            })} 
            </Row>
            <Row>
                {users.length > 1 && <Col> 
                    <StyledButton disabled={currentListSpot === 0} onClick={() => SetPage(0)}>First</StyledButton>
                {currentListSpot > 1 && <StyledButton  onClick={() => SetPage(currentListSpot - 2)}>{currentListSpot - 1}</StyledButton>}
                {currentListSpot > 0 && <StyledButton  onClick={() => SetPage(currentListSpot - 1)}>{currentListSpot}</StyledButton>}
                    <StyledButton color="primary" onClick={() => SetPage(currentListSpot)}>{currentListSpot + 1}</StyledButton>
                {currentListSpot < users.length - 1 && <StyledButton  onClick={() => SetPage(currentListSpot + 1)}>{currentListSpot + 2}</StyledButton>}
                {currentListSpot < users.length - 2 && <StyledButton  onClick={() => SetPage(currentListSpot + 2)}>{currentListSpot + 3}</StyledButton>}
                    <StyledButton disabled={!(currentListSpot < users.length - 1)} onClick={() => SetPage(users.length - 1)}>Last</StyledButton>
                </Col>}
            </Row>
        </Container>
    );
}
 
export default AdminUserProfileList;

const StyledButton = styled(Button)`
    margin-left: 2px;
    margin-right: 3px;
`

const ProfileCard = styled(Col)`
    height: 210px;
    max-width: 300px;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    text-align: center;
    margin-right: 20px;
    margin-top: 20px;
    border-radius: 15px;
    box-shadow: 0 0.2rem .6rem #372844;
`