import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import styled from 'styled-components';

const AdminLandingPage = (props) => {

    return (
        <div>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage />
                </ParentDiv>   
            </HeightDiv>
            <StyledContainer>
                <Row>
                    <h1>Admin Landing Page</h1>
                </Row>
                <hr />
                <Row>
                    <FlexCol>
                        {/*SITE ADMIN*/}
                        {(props.user !== undefined && props.user.isSiteAdmin) &&<h3>Site Admin</h3>}
                        {(props.user !== undefined && props.user.isSiteAdmin) && <h4><a href="/admin-site-profile-control">Manage User Profiles</a></h4>}
                        {/*STUDY ADMIN*/}
                        {(props.user !== undefined && (props.user.isSiteAdmin || props.user.isStudyAdmin)) &&<h3>Study Admin</h3>}
                        {(props.user !== undefined && (props.user.isSiteAdmin || props.user.isStudyAdmin)) && <h4><a href="/team-list">Manage Team Profiles</a></h4>}
                        {(props.user !== undefined && (props.user.isSiteAdmin || props.user.isStudyAdmin)) && <h4><a href="/page-control">Manage Page Control</a></h4>}
                        {(props.user !== undefined && (props.user.isSiteAdmin || props.user.isStudyAdmin)) && <h4><a href="/admin-study-update-documents">Update Study Documents</a></h4>}
                        {(props.user !== undefined && (props.user.isSiteAdmin || props.user.isStudyAdmin)) && <h4><a href="/tickets">User Support Tickets</a></h4>}
                        {(props.user !== undefined && (props.user.isSiteAdmin || props.user.isStudyAdmin)) && <h4><a href="/admin-study-admin-control">Manage Staff</a></h4>}
                        {(props.user !== undefined && (props.user.isSiteAdmin || props.user.isStudyAdmin)) && <h4><a href="/admin-study-enrol-staff">Enrol Staff</a></h4>}
                        {/*REGIONAL ADMIN*/}
                        {(props.user !== undefined && (props.user.isSiteAdmin || props.user.isRegionalAdmin)) &&<h3>Regional Admin</h3>}
                        {(props.user !== undefined && (props.user.isSiteAdmin || props.user.isRegionalAdmin)) && <h4><a href="/admin-regional-admin-control">Manage Participants</a></h4>}
                        {(props.user !== undefined && (props.user.isSiteAdmin || props.user.isRegionalAdmin)) && <h4><a href="/admin-regional-enrol-participant">Enrol Participant</a></h4>}
                    </FlexCol>
                </Row>
            </StyledContainer>
        </div>
    );
}
 
export default AdminLandingPage;

const FlexCol = styled(Col)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const StyledContainer = styled(Container)`
    justify-content: center;
    background-color: white;
    border-radius: 10px;
    border: solid;
    border-color: #9780af #372844 #372844 #9780af;
    margin: auto;
    margin-top: 15px;
    box-shadow: 0 0.5rem 1rem #372844;
    padding: 50px;
    max-width: 1200px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #dddddd;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;