export const distanceranges = [
    {_id:"0", name:"Any distance"},
    {_id:"10", name:"10km"},
    {_id:"25", name:"25km"},
    {_id:"50", name:"50km"},
];

export const groupnames = [
    {_id: "unassigned", name: "unassigned"},
    {_id: "core", name: "core"},
    {_id: "secondary", name: "secondary"},
]