export const activities = [
    {_id:"Aerobics", name:"Aerobics"},
    {_id:"Cycling", name:"Cycling"},
    {_id:"Hiking", name:"Hiking"},
    {_id:"Stair Climbing", name:"Stair Climbing"},
    {_id:"Team Sports", name:"Team Sports"},
    {_id:"Cross-country skiing", name:"Cross-country skiing"},
    {_id:"Dancing", name:"Dancing"},
    {_id:"Running", name:"Running"},
    {_id:"Swimming", name:"Swimming"},
    {_id:"Walking", name:"Walking"},
];

export const fitnesslevel = [
    {_id: "N/A", name: ""},
    {_id:"never been active", name:"Never been active"},
    {_id:"used to be active but currently not active", name:"Used to be active but currently not active"},
    {_id:"a little active", name:"A little active"},
    {_id:"quite active", name:"Quite active"},
    {_id:"very active", name:"Very active"},
];

export const activereasons = [
    {_id:"Weight Loss", name:"Weight Loss"},
    {_id:"Reduce Fatigue", name:"Reduce Fatigue"},
    {_id:"Improve Physical Function", name:"Improve Physical Function"},
    {_id:"Improve cardiovascular health", name:"Improve cardiovascular health"},
    {_id:"Reduce stress", name:"Reduce stress"},
    {_id:"Social Support", name:"Social Support"},
    {_id:"Improve emotional health", name:"Improve emotional health"},
    {_id:"Sense of Accomplishment", name:"Sense of Accomplishment"},
    {_id:"Return to work / school", name:"Return to work / school"},
    {_id:"Improve cognitive function", name:"Improve cognitive function"},
];


export const activitylocation = [
    {_id: "Indoor", name: "Indoor"},
    {_id: "Outdoor", name: "Outdoor"},
    {_id: "Home", name: "Home"},
    {_id: "Gym", name: "Gym"},
    {_id: "Community Center", name: "Community Center"},
    {_id: "Other", name: "Other"},
];
/*
{_id:"Home", name:"Home"},
{_id:"Gym", name:"Gym"},
{_id:"Outdoors", name:"Outdoors"},
{_id:"No preference", name:"No preference"},
{_id:"Other - Describe in About You question below", name:"Other - Describe in About You question below"},
*/

export const activitytime = [
        {_id: "Morning", name: "Morning"},
        {_id: "Afternoon", name: "Afternoon"},
        {_id: "Evening", name: "Evening"},
        {_id: "Weekdays", name: "Weekdays"},
        {_id: "Weekends", name: "Weekends"},
];


export const personality = [
    {_id: "N/A", name: ""},
    {_id:"Extroverted", name:"Extroverted"},
    {_id:"Calm, emotionally stable", name:"Calm, emotionally stable"},
    {_id:"Open to new experiences", name:"Open to new experiences"},
    {_id:"Reserved, quiet", name:"Reserved, quiet"},
];

export const worksituation = [
    {_id: "N/A", name: ""},
    {_id:"Currently working full time", name:"Currently working full time"},
    {_id:"Currently working part time", name:"Currently working part time"},
    {_id:"Currently not working", name:"Currently not working"},
];