import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { DeleteUser, GetUserInformation, UpdateUserInformation } from '../../services/firestore';
import { auth, db } from '../../services/firebase';
import blankUser from '../../images/BlankUser.jpg';
import styled from 'styled-components';
import { convertUTCDateToLocalDate } from '../../services/helper';
import emailjs, { init } from 'emailjs-com';
import { groupnames } from '../../libraries/filters';
import ProfileSelect from './profileEditFields/profileSelect';

const Profile = () => {

    init("user_8Qt3ZJv9dlnGhiJefkhBh");
    
    const [currentUser, setCurrentUser] = useState({});
    const [userProfile, setProfile] = useState({});
    const [userProfileId, setUserProfileId] = useState(useParams().id);
    const [age, setAge] = useState("");
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [adminPrompt, setAdminPrompt] = useState(false);
        
    const toggle = () => setModal(!modal);

    var userRef = db.collection("users");

     useEffect(() => {
        GetUserInformation(auth.currentUser.uid, findCurrentUser);
      }, []);

    const findCurrentUser = (currentuser) => {
        setCurrentUser(currentuser);
        populateProfile(currentuser);
     }

    const populateProfile = (currentuser) =>
    {
        GetCurrentlyViewedProfileInformation(userProfileId, mapToViewModel, currentuser);
        if(userProfileId === currentuser.uid){
            window.location = "/personal";
        }
    }

    const GetCurrentlyViewedProfileInformation = (userid, successcallback, currentuser) => {
        userRef.doc(userid).get().then(function(doc) {
        if (doc.exists) {
            db.collection("users").doc(userid)
            .onSnapshot((doc) => {
                successcallback(doc.data(), currentuser);
            });
        } else {
            window.location = "/not-found"
        }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }

    const mapToViewModel = (user, currentuser) => {
        if(!currentuser.isAdmin && (currentuser.group != user.group)){ return window.location ="/not-found" }
        
        if(user.modules === undefined){
            user.modules = [];
        }

        setProfile(user);
        
        var dateofbirth = "N/A";

        if(user.dateofbirth){
            dateofbirth = new Date(user.dateofbirth.year, user.dateofbirth.month, user.dateofbirth.day);
            setAge(calculateAge(dateofbirth));
        }    
     }

    const calculateAge = (dob) => {     
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms);

        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    const makeAdmin = (profileId) => {
        if(!currentUser.isAdmin){ return; }
        var newProperties = { isAdmin: true }
        UpdateUserInformation(profileId, newProperties);
        toggle();
    }

    const acceptAccount = (user) => {
        var newProperties = { isAccountAccepted:true, isArchived: false }
        UpdateUserInformation(user.uid, newProperties);
        if(!user.isArchived){
            emailjs.send("service_jk915bb", "template_xyrjsbs", {
                to_name: user.username,
                email: user.email,
            });
        }
    }

    const changeGroup = (user) => {
        var newProperties = { group: user.group }
        UpdateUserInformation(userProfile.uid, newProperties);
    }

    const PromptUserArchive = () => {
        setAdminPrompt(false);
        setModalTitle("Delete " + userProfile.username + " Account?");
        setModalMessage("They will no longer be able to access the HIMALAYAS services");
        toggle();
    }

    const PromptUserAdmin = () => {
        setAdminPrompt(true);
        setModalTitle("Make " + userProfile.username + " an Administrator?");
        setModalMessage("They will have administrator capabilities");
        toggle();
    }

    const ArchiveAccount = (id) => {
        DeleteUser(id);
        toggle();
    }

    return (
        <React.Fragment>
                <HeightDiv>
                    <ParentDiv>
                        <StyledBackgroundImage />
                    </ParentDiv>   
                </HeightDiv>
            <MarginDiv>
                <StyledContainer>
                    <br />
                    <StyledModal isOpen={modal}>
                        <ModalHeader>{modalTitle}</ModalHeader>
                            <ModalBody>
                                {modalMessage}
                            </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggle}>Cancel</Button>
                            {!adminPrompt && <Button color="primary" onClick={() => ArchiveAccount(userProfile.uid)}>Confirm</Button>}
                            {adminPrompt && <Button color="primary" onClick={() => makeAdmin(userProfile.uid)}>Admin</Button>}
                        </ModalFooter>
                    </StyledModal>
                    <Row>
                        <AccountCol lg="4" md="6" xs="12">
                            {/*
                            {userProfile.imageurl && <ProfileImage src={userProfile.imageurl} alt={userProfile.username} />}
                            {!userProfile.imageurl && <div><br /><ProfileImage src={blankUser} alt={userProfile.username} /></div>}
                            */}
                            <div><br /><ProfileImage src={blankUser} alt={userProfile.username} /></div>
                            <h1><br />{userProfile.username}</h1>
                            {age && <StyledParagraph>{userProfile.username} is {age} years old</StyledParagraph>}

                            {userProfile.isAdmin && <div><br /><StyledParagraph>HIMALAYAS Administrator</StyledParagraph></div>}
                            {(currentUser.isAdmin && userProfile.isAccountAccepted && !userProfile.isAdmin) && <div><br /><StyledParagraph>Has been accepted for HIMALAYAS</StyledParagraph></div>}
                            {(currentUser.isAdmin && userProfile.isArchived) && <div><br /><StyledParagraph>Profile has been archived</StyledParagraph></div>}
                            <h3>Account Actions</h3>
                            <hr />
                            {currentUser.isAdmin && 
                                <ProfileSelect
                                user={userProfile}
                                propertyName="group"  
                                label="Group:" 
                                value={userProfile.group} 
                                name="group"
                                editFunction={changeGroup}
                                options={groupnames} />
                            }
                            <StyledButton href={"/messageRoom/"+ userProfileId} color="primary">Message {userProfile.username}</StyledButton>
                            <br />
                            <br />
                            {(currentUser.isAdmin && !userProfile.isAdmin) && <StyledButton onClick={PromptUserAdmin} color="primary">Make Admin</StyledButton> } {/*TODO needs to send an email */}
                            <br />
                            <br />
                            {(currentUser.isAdmin && (!userProfile.isAccountAccepted || userProfile.isArchived)) && <StyledButton onClick={() => acceptAccount(userProfile)} color="primary">Accept Account</StyledButton> }
                            {(currentUser.isAdmin && !userProfile.isArchived) && <StyledButton onClick={PromptUserArchive} color="danger">Archive Account</StyledButton>}
                        </AccountCol>
                        <Col>
                            <Container fluid={true}>
                                <hr />
                                <Row>
                                    <Col lg="6">
                                        <h4>Recent Achievement:</h4>
                                        {!userProfile.recentachievement && <StyledParagraph>Not Answered</StyledParagraph>}
                                        {userProfile.recentachievement && <StyledParagraph>{userProfile.recentachievement}</StyledParagraph>}
                                        <br />
                                    </Col>
                                    <Col>
                                        <h4>{userProfile.username}'s Self Description:</h4>
                                        {userProfile.aboutuser && <StyledParagraph>{userProfile.aboutuser}</StyledParagraph>}
                                        {!userProfile.aboutuser && <StyledParagraph>Not Answered</StyledParagraph>}
                                        <br />
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                <h3><u>Exercise History</u></h3>
                                    <Col lg="6">
                                        <h4>Reason For Wanting Partner:</h4>
                                        {userProfile.reasonforpartner && userProfile.sharePartnerReason && <StyledParagraph>{userProfile.reasonforpartner}</StyledParagraph>}
                                        {(!userProfile.reasonforpartner || !userProfile.sharePartnerReason) && <StyledParagraph>Not Answered</StyledParagraph>}

                                        <h4>Personality:</h4>
                                        {(!userProfile.personality || !userProfile.sharePartnerReason) && <StyledParagraph>Not Answered</StyledParagraph>}
                                        {userProfile.sharePartnerReason && <StyledParagraph>{userProfile.personality}</StyledParagraph>}

                                        {userProfile.activities && userProfile.shareActivities && <div><h4>Activities:</h4><ul>{Object.entries(userProfile.activities).map((activity, i) => {
                                            return  <StyledListItem key={i}>{activity}</StyledListItem>
                                        })}</ul></div>}
                                
                                        <h4>Other Activities:</h4>
                                        {(!userProfile.otherfavorites || !userProfile.shareActivities) && <StyledParagraph>Not Answered</StyledParagraph>}
                                        {userProfile.shareActivities && <StyledParagraph>{userProfile.otherfavorites}</StyledParagraph>}
                                        
                                        <h4>Benefits of Having an Exercise Partner:</h4>
                                        {(!userProfile.activereason || !userProfile.shareReasons) && <StyledParagraph>Not Answered</StyledParagraph>}
                                        {userProfile.activereason && userProfile.shareReasons && <ul>{Object.entries(userProfile.activereason).map((reason, i) =>{
                                            return <StyledListItem key={i}>{reason}</StyledListItem>
                                        })}</ul>}

                                    </Col>
                                    <Col>
                                        <h4>Fitness Level:</h4>
                                        {userProfile.fitnesslevel && userProfile.shareFitness && <StyledParagraph>{userProfile.fitnesslevel}</StyledParagraph>}
                                        {(!userProfile.fitnesslevel || !userProfile.shareFitness) && <StyledParagraph>Not Answered</StyledParagraph>}

                                        <h4>Exercise Location Preference:</h4>
                                        {userProfile.fitnesslocation && userProfile.shareLocation && <ul>{Object.entries(userProfile.fitnesslocation).map((location, i) =>{
                                            return <StyledListItem key={i}>{location}</StyledListItem>
                                        })}</ul>}
                                        {(!userProfile.fitnesslocation || !userProfile.shareLocation) && <StyledParagraph>Not Answered</StyledParagraph>}

                                        <h4>Preferred Exercise Time:</h4>
                                        {userProfile.fitnesstime && userProfile.shareTime && <ul>{Object.entries(userProfile.fitnesstime).map((time, i) =>{
                                            return <StyledListItem key={i}>{time}</StyledListItem>
                                        })}</ul>}  
                                        {(!userProfile.fitnesstime || !userProfile.shareTime) && <StyledParagraph>Not Answered</StyledParagraph>}
                                        <br />
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <h3><u>Cancer History</u></h3>
                                    <Col lg="6">
                                        <h4>Recent Cancer Diagnosis:</h4>
                                        {userProfile.cancerLocation && userProfile.shareCancerLocation && <StyledParagraph>{userProfile.cancerlocation}</StyledParagraph>}
                                        {(!userProfile.cancerlocation || !userProfile.shareCancerLocation) && <StyledParagraph>Not Answered</StyledParagraph>}

                                        <div>
                                            <h4>Previous Diagnosis:</h4>
                                            {userProfile.otherlocation && userProfile.shareOtherLocation && <StyledParagraph>{userProfile.otherlocation}</StyledParagraph>}
                                            {(!userProfile.otherlocation || !userProfile.shareOtherLocation) && <StyledParagraph>Not Answered</StyledParagraph>}
                                        </div>

                                        <h4>Status of Cancer:</h4>
                                        {userProfile.cancerStatus && userProfile.shareCancerStatus && <StyledParagraph>{userProfile.cancerstatus}</StyledParagraph>}
                                        {(!userProfile.cancerstatus || !userProfile.shareCancerStatus) && <StyledParagraph>Not Answered</StyledParagraph>}

                                    </Col>
                                    <Col>
                                        <h4>Cancer Treatments:</h4>
                                        {userProfile.treatmentdescription && userProfile.shareTreatmentDescription && <StyledParagraph>{userProfile.treatmentdescription}</StyledParagraph>}
                                        {(!userProfile.treatmentdescription || !userProfile.shareTreatmentDescription) && <StyledParagraph>Not Answered</StyledParagraph>}
                                        
                                        {false && <div>
                                            <h4>Cancer Support Name:</h4>
                                            <StyledParagraph>{userProfile.supportgroupname}</StyledParagraph>
                                            {!userProfile.supportgroupname && <StyledParagraph>Not Answered</StyledParagraph>}
                                        </div>}
                                    </Col>
                                </Row>
                                {(currentUser.isAdmin && userProfile.modules !== undefined) && <Row><hr /><h4><u>Module Progress</u></h4>
                                    <ul>{userProfile.modules.map((module, i) => {
                                        var startTime = new Date(1970, 0, 1);
                                        startTime.setSeconds(module.timeStarted.seconds);
                                        startTime = convertUTCDateToLocalDate(startTime);

                                        var endTime;
                                        if(module.timeCompleted !== ""){
                                            endTime = new Date(1970, 0, 1);
                                            endTime.setSeconds(module.timeCompleted.seconds);
                                            endTime = convertUTCDateToLocalDate(endTime);
                                        }
                                        else{
                                            endTime = "";
                                        }
                                        return  <StyledListItem key={i}>{module.moduleName} |
                                            Status: {module.completed ? "Completed" : "Incomplete"} |
                                            Date Started: {startTime.toLocaleString()} |
                                            Date Completed: {endTime.toLocaleString()}</StyledListItem>
                                        })}
                                    </ul>
                                </Row>}
                            </Container>
                        </Col>
                    </Row>
                </StyledContainer>
            </MarginDiv>
        </React.Fragment>
    );
}
 
export default Profile;

const AccountCol = styled(Col)`
    text-align: center;
`

const MarginDiv = styled.div`
    margin-top: 35px;
    margin-left: 38px;
    margin-right: 38px;
`

const StyledParagraph = styled.p`
    font-size: large;
`

const StyledListItem = styled.li`
    font-size: large;
`

const StyledModal = styled(Modal)`
    position: absolute;
    left: 40%;
    top: 40%;
`

const StyledButton = styled(Button)`
    min-width: 200px;
    border-style: solid;
    border-width: 1.5px;
    border-color: black;
`

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #372844;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 1500px;
    margin-top: 15px;
    margin-bottom: 90px;
`

const ProfileImage = styled.img`
    box-shadow: 0 0.5rem 1rem #372844;
    width: 100%;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #dddddd;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;