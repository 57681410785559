import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components'

const ProjectCarousel = (props) => {
    const [autoBool, setAutoBool] = useState(true);
    const [items, setItems] = useState([]);

    useEffect(() => {
        setAutoBool(true);
        setItems(props.items);
    }, [props.items]);

    return (
        <StyledCarousel autoPlay={true} infiniteLoop={true} interval={props.timer} showStatus={false}>
            {props.items.map((entry, i) => (
                <div key={i}>
                    {entry.imageUrl && <StyledImg src={entry.imageUrl} />}
                    <StyledTitle>{entry.title}</StyledTitle>
                    {entry.content && <StyledContent>{entry.content}</StyledContent>}
                    <br />
                </div>
            ))}  
        </StyledCarousel>
    );
};

export default ProjectCarousel;

const StyledCarousel = styled(Carousel)`
  max-height: auto;
  max-width: ${props => props.carouselWidth};
  background-color: #372844;
  color: white;
`

const StyledImg = styled.img`
`

const StyledTitle = styled.h4`
    padding-top: 10px;
`

const StyledContent = styled.p`
    padding-left: 30px;
    padding-right: 30px;
`