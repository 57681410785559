import React, { useState, useEffect } from 'react';
import { auth } from '../../services/firebase';
import { UpdateUserInformation, GetUserInformation, UploadImageToStorage, DeleteFromStorage } from '../../services/firestore';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components'
import groupImage from "../../images/MorningRun.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageUploadAndCrop from '../common/formComponents/imageUploadAndCrop';

const PhotoUpload = () => {
    const [profileImage, setProfileImage] = useState("");
    const [profileImageFile, setProfileImageFile] = useState();
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [crop, setCrop] = useState({unit: '%',
    width: 30,
    aspect: 1/1,});

    const toggle = () => setModal(!modal);

    useEffect(() => {
      GetUserInformation(auth.currentUser.uid, setUrl);
    }, []);

    const setUrl = (user) => {
      if(user.imageurl){
        setProfileImage(user.imageurl);
      } 
    }

    const GoToProfile = () => {
      window.location = "/personal";
    }

    const RetrieveImage = (image, url) => {
      setProfileImageFile(image);
      setProfileImage(url);
      doSubmit(image);
    }

    const doSubmit =(imageFile) => {
      var pathString = `images/${auth.currentUser.uid}/`
      UploadImageToStorage(imageFile, pathString, "profilepicture", SuccessUpload)

    };

    const SuccessUpload = (url) => {
      var newProperties = { imageurl: url }
      UpdateUserInformation(auth.currentUser.uid, newProperties, GoToProfile);
  }

    const RemoveImagePrompt = () => {
      setModalTitle("Delete Picture");
      setModalMessage("Are you sure you want to delete your picture?");
      toggle();
    }

    const RemoveImage = () => {
      DeleteFromStorage(`images/${auth.currentUser.uid}/`, "profilepicture", SetNullImageRef);
    }

    const SetNullImageRef = () => {
      setProfileImageFile("");
      setProfileImage("");
      var newProperties = { imageurl: "" }
      UpdateUserInformation(auth.currentUser.uid, newProperties);
      toggle();
    }

    return (
        <React.Fragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage src={groupImage} alt="Keep at it!" />
                </ParentDiv>   
            </HeightDiv>
            {!profileImage &&<CropContainer><ImageUploadAndCrop maxWidth="400px" submitFunction={RetrieveImage} crop={crop} descriptionText="Upload Profile Image" /></CropContainer>}
            {profileImage && <StyledContainer>
                <Col><br />
                    <StyledProfileImage src={profileImage} alt="" />
                    <StyledButton onClick={() => RemoveImagePrompt()} color="danger">
                        <FAIconButton icon={["fas", "times"]}></FAIconButton>
                    </StyledButton>
                </Col>
                <br />
                <Row>
                  <Col>
                    <StyledModal isOpen={modal}>
                        <ModalHeader>{modalTitle}</ModalHeader>
                            <ModalBody>
                                {modalMessage}
                            </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggle}>Cancel</Button>
                            <Button color="primary" onClick={RemoveImage}>Delete</Button>
                        </ModalFooter>
                    </StyledModal>
                  </Col>
                </Row>
            </StyledContainer>}
        </React.Fragment>
    );
  }

export default PhotoUpload;

const StyledModal = styled(Modal)`
    position: absolute;
    left: 40%;
    top: 40%;
`

const CropContainer = styled(Container)`
    justify-content: center;
    margin: auto;
    margin-top: 100px;
    width: fit-content;
`

const StyledProfileImage = styled.img`
    padding: 5px;
    margin-right: 30px;
    box-shadow: 0 0.5rem 1rem #9780af;
    max-width: 250px;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;

const StyledButton = styled(Button)`
    margin-right: 15px;
`

const StyledContainer = styled(Container)`
    justify-content: center;
    background-color: white;
    border-radius: 10px;
    border: solid;
    border-color: #9780af #372844 #372844 #9780af;
    margin: auto;
    margin-top: 100px;
    box-shadow: 0 0.5rem 1rem #9780af;
    padding: 20px;
    width: fit-content;
    margin-bottom: 1000px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;