import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { db } from '../../../services/firebase';
import styled from 'styled-components'
import SingleInput from './singleInput';

const GlobalPageForm = () => {
    const [primaryText, setPrimaryText] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");

    const toggle = () => setModal(!modal);
    const docRef = db.collection("webPages").doc("global")

    useEffect(() => {
        ReturnGlobalPageInformation();   
    }, []);

    const ReturnGlobalPageInformation = () =>{
        docRef.onSnapshot((doc) => {
            const data = doc.data();
            if(doc.data()){
                if(data.primaryText){
                    setPrimaryText(data.primaryText);
                }
            }
            else{
                docRef.set({ primaryText })
            }
        });
    }

    const UpdatePrimaryText = (text) => {
        docRef.update({ primaryText: text }).then(() => {
            setModalTitle("Text Content Updated");
            setModalMessage("Successfully updated text for Global Information");
            toggle();
        })
        .catch((error) => {
            setModalTitle("Something went wrong");
            setModalMessage("An error occurred when trying to update text");
            toggle();
        });
    }

    return (
        <Container>
            <Row>
                <Col>
                    <StyledModal isOpen={modal}>
                        <ModalHeader>{modalTitle}</ModalHeader>
                            <ModalBody>
                                {modalMessage}
                            </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={toggle}>Done</Button>
                        </ModalFooter>
                    </StyledModal>
                </Col>
            </Row>
            <h2>City Options</h2>
            <SingleInput returnDataCallback={UpdatePrimaryText} objectList={primaryText} />
            <br />
        </Container>
     );
}
 
export default GlobalPageForm;

const StyledModal = styled(Modal)`
    position: absolute;
    left: 40%;
    top: 40%;
`