import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/storage";

/* live data base */ 

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyDbQpYiRIC01WoSHJ-bYJNIYu5Qdyfbi_c",
    authDomain: "activematch-4fd06.firebaseapp.com",
    projectId: "activematch-4fd06",
    storageBucket: "activematch-4fd06.appspot.com",
    messagingSenderId: "757939360683",
    appId: "1:757939360683:web:686ac30968d8cfcabe38ee",
    measurementId: "G-DPS0HXG1MQ"
});

const firebaseAppForCreatingUsers = firebase.initializeApp({
  apiKey: "AIzaSyDbQpYiRIC01WoSHJ-bYJNIYu5Qdyfbi_c",
  authDomain: "activematch-4fd06.firebaseapp.com",
  projectId: "activematch-4fd06",
  storageBucket: "activematch-4fd06.appspot.com",
  messagingSenderId: "757939360683",
  appId: "1:757939360683:web:686ac30968d8cfcabe38ee",
  measurementId: "G-DPS0HXG1MQ"
}, "Secondary");

/* TESTING DATABASE *
const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAkVgFi71uYa0bfaDtPkIgrltno4SA9Mao",
  authDomain: "himilayas-test2.firebaseapp.com",
  projectId: "himilayas-test2",
  storageBucket: "himilayas-test2.appspot.com",
  messagingSenderId: "322659744501",
  appId: "1:322659744501:web:7dc9742257d8092ceba3bc",
  measurementId: "G-XP6DKPH3CB"
}); */

export const authForCreatingUsers = firebaseAppForCreatingUsers.auth();

const db = firebaseApp.firestore();
db.settings({ experimentalForceLongPolling: true });
const storage = firebase.storage();

export const auth = firebase.auth();
export { db };
export { storage };