import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Input, Label, Button } from 'reactstrap';
import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { groupnames } from '../../../libraries/filters';

const GroupNoteUpdate = (props) => {
    const [list, setList] = useState([]);
    const [urls, setUrls] = useState([]);

    useEffect(() => {
        setList([...props.objectList]);  
    }, [props.objectList]);

    const UpdatePrimaryText = (text, id, property) =>{
        var newText = list;
        newText[id][property] = text;
        setList([...newText]);
    }

    const DeleteEntry = (entry) => {
        var newText = list;
        newText.splice(entry, 1);
        setList([...newText]);
        props.returnDataCallback(newText);
    }

    const newEntry = {
        title: "",
        content: "",
        index: "",
    }

    const AddNewEntry = () =>{
        var newText = list;
        list.push(newEntry);
        setList([...newText]);
    }

    const TriggerSubmit = () => {
        props.returnDataCallback(list);
    }
//TODO I need to add a drop down with the group names
    return ( 
        <Container fluid={true}>    
        {list.map((entry, i) => (
            <div key={i}>
                <Row>
                    <Col lg="6">
                        <Label>Title:</Label>
                        <StyledInput  name="title" id="title" placeholder="Title..." value = {entry.title} onChange={e => UpdatePrimaryText(e.target.value, i, "title")}/>
                    </Col>
                    <Col lg="2">
                        <Label>Group Name:</Label>

                        <StyledInput type="select" name="group" id="group" value={entry.group} onChange={e => UpdatePrimaryText(e.target.value, i, "group")}>
                            {groupnames.map((name) => (
                                <option  key={name._id} value={name._id}>
                                    {name.name}
                                </option>
                            ))}
                        </StyledInput>
                    </Col>
                    <TrashButtonCol lg="2">
                        <StyledButton onClick={() => DeleteEntry(i)} color="danger">
                            <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                        </StyledButton> 
                    </TrashButtonCol>
                </Row>
                <Row>
                    <Col>
                        <Label>Content:</Label>
                        <br />
                        <StyledInput  name="content" id="content" type="textarea" placeholder="Content..." value = {entry.content} onChange={e => UpdatePrimaryText(e.target.value, i, "content")}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label>URL:</Label>
                        <p>In order to open a "send email" link, just add: mailto: and then the email address</p>
                        <StyledInput  name="url" id="url" placeholder="URL..." value = {entry.url} onChange={e => UpdatePrimaryText(e.target.value, i, "url")}/>
                    </Col>
                </Row>
                <br />
                <hr />
                <br />
            </div>
            ))}
            <Row>
                <Col>
                    <StyledButton onClick={() => AddNewEntry()} color="primary">
                        <FAIconButton  icon={["fas", "plus"]}></FAIconButton> Add Entry
                    </StyledButton>
                </Col>
                <Col>
                    <StyledButton onClick={() => TriggerSubmit()} color="primary">
                                    <FAIconButton  icon={["fas", "check"]}></FAIconButton> Save Details
                    </StyledButton>
                </Col>
            </Row>
            <hr />
        </Container> );
}
 
export default GroupNoteUpdate;

const StyledInput = styled(Input)`
&:focus{
    border-color: #372844;
    box-shadow: 0 0.5rem 1rem #9780af;
}
`

const TrashButtonCol = styled(Col)`
    margin-top: 25px;
`

const StyledProfileImage = styled.img`
    padding: 5px;
    margin-right: 30px;
    box-shadow: 0 0.5rem 1rem #9780af;
    max-width: 250px;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 0px;
    margin-left: 0px;
    
`;

const StyledButton = styled(Button)`
    max-width: 175px;
    max-height: 35px;
    margin-left: 5px;
`;