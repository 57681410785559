import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Button } from 'reactstrap';

const AdminNav = () => {
   return (<React.Fragment>
       <Container>
           <Row>
               <Col>
                    <RightMarginButton color="primary" href="/admin-home">Back to Admin Panel</RightMarginButton>
               </Col>
           </Row>
           <hr />
       </Container>
   </React.Fragment>);
}

export default AdminNav;

const RightMarginButton = styled(Button)`
    margin-right: 25px;
`