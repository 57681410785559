import React, { useState, useEffect } from 'react';
import { DeleteUser, ReturnUsers, listenToCollection } from '../../../services/firestore';
import { db } from '../../../services/firebase';
import { Container, Row, Col, Input, Label } from 'reactstrap';
import styled from 'styled-components'

import UserProfileList from './admin-regional-user-profile-list';
import AdminNav from '../adminComponents/adminNav';

const AdminRegionalUserControl = (props) => {

//const [fullUsers, setFullUsers] = useState([]);
    //const [filteredUsers, setFilteredUsers] = useState([]);
    const [filter, setFilter] = useState("");
    //const [keywordFilter, setKeywordFilter] = useState("");

    const [users, setUsers] = useState([])

    const filters = [
        {_id:"", name:""},
        {_id:"Pending", name:"Pending"},
        {_id:"Accepted", name:"Accepted"},
        {_id:"Admin", name:"Admin"},
        {_id:"Unverified Email", name:"Unverified Email"},
        {_id:"Archived", name:"Archived"},
    ];

    useEffect(() => {
        var unsubscribe = listenToCollection(db, "users", setUsers);
        return unsubscribe;
    }, []);

    const Filter = (value) =>{
        switch (filter) {
            case "Pending":
                return (!value.isAccountAccepted && value.emailVerified && !value.isArchived);
            case "Accepted":
                return (value.isAccountAccepted && value.emailVerified && !value.isArchived);
            case "Admin":
                return (value.isAdmin && !value.isArchived);
            case "Archived":
                return (value.isArchived);
            case "Unverified Email":
                return (!value.emailVerified && !value.isArchived);
            default:
                return (!value.isArchived);
        }
    }
/*
    useEffect(() => {
        getUsers();
      }, []);
    
    const getUsers = () =>{
        ReturnUsers(handleList);
     }

     const handleList = (users) => {
        var finalUserList = [];
        for(var i = 0; i < users.length; i++){
            finalUserList.push(users[i]);
        }
        finalUserList = AddCompletedModules(finalUserList);
        setFullUsers(finalUserList);
        setFilteredUsers(finalUserList);
     }

     const filterSearch = (value) => {
        setKeywordFilter(value);
        filterUsers(filter, value);
     }

     const filterUsers = (value, keyword = keywordFilter) => {
         setFilter(value);
         var newFilteredList = [];
         var keywordList = [];
         if(keyword !== ""){
            for(let i = 0; i < fullUsers.length; i++){
                if(fullUsers[i].username.toLowerCase().includes(keyword.toLowerCase())){
                    keywordList.push(fullUsers[i]);
                }
            }
         }
         else{
             keywordList = fullUsers;
         }

         if(value === "Pending"){
             for(let i = 0; i < keywordList.length; i++){
                 if(!keywordList[i].isAccountAccepted && keywordList[i].emailVerified){
                     newFilteredList.push(keywordList[i]);
                 }
             }
         }
        else if(value === "Accepted"){
            for(let i = 0; i < keywordList.length; i++){
                if(keywordList[i].isAccountAccepted && !keywordList[i].isArchived){
                    newFilteredList.push(keywordList[i]);
                }
            }
        }
        else if(value === "Admin"){
            for(let i = 0; i < keywordList.length; i++){
                if(keywordList[i].isAdmin){
                    newFilteredList.push(keywordList[i]);
                }
            }
        }
        else if(value === "Unverified Email"){
            for(let i = 0; i < keywordList.length; i++){
                if(!keywordList[i].emailVerified){
                    newFilteredList.push(keywordList[i]);
                }
            }
        }
        else if(value === "Archived"){
            for(let i = 0; i < keywordList.length; i++){
                if(keywordList[i].isArchived){
                    newFilteredList.push(keywordList[i]);
                }
            }
        }
        else{
            newFilteredList = keywordList;
        }
        setFilteredUsers(newFilteredList);
     }
*/
     const AddCompletedModules = (userList) => {
        for(let i = 0; i < userList.length; i++){
             var moduleNum = 0;
             if(userList[i].modules !== undefined){
                for(let y = 0; y < userList[i].modules.length; y++){
                    if(userList[i].modules[y].completed){
                        moduleNum += 1;
                    }
                }
             }
            userList[i].completedModules = moduleNum;
         }
         return userList;
     }

     function paginate (arr, size) {
        return arr.reduce((acc, val, i) => {
          let idx = Math.floor(i / size)
          let page = acc[idx] || (acc[idx] = [])
          page.push(val)
      
          return acc
        }, [])
    }
    
    return ( 
        <React.Fragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage />
                </ParentDiv>   
            </HeightDiv>
            <StyledContainer>
                <AdminNav />
                <Row>
                    <Col>
                        <h1>Participant List</h1>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        {/*
                        <Label>Filter</Label>
                        <StyledInput type="select" name="filter" id="filter" onChange={e => setFilter(e.target.value)}>
                                {filters.map((filter) => (
                                    <option  key={filter._id} value={filter._id}>
                                        {filter.name}
                                    </option>
                                ))}
                        </StyledInput>
                        <StyledInput type="select" name="filter" id="filter" onChange={e => filterUsers(e.target.value)}>
                                {filters.map((filter) => (
                                    <option  key={filter._id} value={filter._id}>
                                        {filter.name}
                                    </option>
                                ))}
                        </StyledInput>
                                */}
                    </Col>
                    {/*
                    <Col>
                        <Label>Keyword</Label>
                        <StyledInput   name="keyword" id="keyword" onChange={e => filterSearch(e.target.value)}/>
                    </Col>
                    */}
                </Row>
                <br />
                <Row>
                    {/*<UserProfileList getUsers={getUsers} users={paginate(filteredUsers, 10)} />*/}
                    {/*<UserProfileList users={paginate(users.filter(Filter), 10)} />*/}
                    <UserProfileList user={props.user} users={users} />
                </Row>
            </StyledContainer>
        </React.Fragment>
    );
}

export default AdminRegionalUserControl;

const StyledInput = styled(Input)`
max-width: 250px;
appearance: menulist;
&:focus{
    border-color: #372844;
    box-shadow: 0 0.5rem 1rem #9780af;
}
`

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #372844;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 1200px;
    margin-top: 15px;
    margin-bottom: 80px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #dddddd;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;