import React, { useState, useEffect } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Col,
    Row,
    Container
} from 'reactstrap';
import logoImage from "../../images/LOGO.png"
import { auth } from '../../services/firebase'
import styled from 'styled-components'
import { GetUserInformation, GetUserMessages, ReturnUsers } from '../../services/firestore';

const Navigationbar = ({ user }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState("zzz");
    const [numberOfMessages, setNumberOfMessages] = useState("0");

    useEffect(() => {
        var urlStrings = window.location.href.split('/');
        if(urlStrings[3] !== ""){
            setCurrentPage(urlStrings[3]);
        }
        if(user !== undefined){
            ReturnUsers(UseUsersToFindUnreadMessages)   
        } 
      }, [user]);

      const UseUsersToFindUnreadMessages = (users) => {
        GetUserMessages(user.uid, ReturnUnreadMessageNumber, users);
      }

      const ReturnUnreadMessageNumber = (unreadMessages) => {
        setNumberOfMessages(unreadMessages);
      }

    const toggle = () => setIsOpen(!isOpen);
    return (
        <StyledNavbar light expand="xl">
            <StyledNavBrand href="/">
                <img src={logoImage} alt="HIMALAYAS Trial" height="75px" />
            </StyledNavBrand>
            <StyledNavToggler onClick={toggle} className="mr-2" />
            <Collapse isOpen={isOpen} navbar>
                <Nav navbar>
                        <React.Fragment>
                            <StyledNavItem><StyledNavlink comparison={currentPage} href="/about-us">STUDY INFO & TEAM</StyledNavlink></StyledNavItem>
                        </React.Fragment>

                        <StyledNavItem><StyledNavlink comparison={currentPage} href="/ethicsAndStudy">ETHICS & STUDY CONTACTS</StyledNavlink></StyledNavItem>
                        <StyledNavItem><StyledNavlink comparison={currentPage} href="/frequently-asked-questions">FAQ</StyledNavlink></StyledNavItem>
                </Nav>
                <RightAlignedNav navbar>
                    {user === undefined || user === "Guest" && (
                        <React.Fragment> 
                            <StyledNavItem><StyledNavlink comparison={currentPage} href="/login">LOGIN</StyledNavlink></StyledNavItem>
                            {/*<StyledNavItem><StyledNavlink comparison={currentPage} href="/register">REGISTER</StyledNavlink></StyledNavItem>*/}
                        </React.Fragment>
                    )}
                    {user !== undefined && user !== "Guest" && (
                    <React.Fragment> 
                        <StyledNavItem>
                            {auth.currentUser &&<StyledNavlink comparison={currentPage} href="/personal">
                                {user.username && user.username.toUpperCase()}
                            </StyledNavlink>}
                        </StyledNavItem>
                        <StyledNavItem><StyledNavlink comparison={currentPage} href="/exercise-groups">EXERCISE GROUPS</StyledNavlink></StyledNavItem>
                        <StyledNavItem><StyledNavlink comparison={currentPage} href="/profile-list">BROWSE PROFILES</StyledNavlink></StyledNavItem>
                        <StyledNavItem><StyledNavlink comparison={currentPage} href="/messages">MESSAGES {numberOfMessages}</StyledNavlink></StyledNavItem>
                        <StyledNavItem><StyledNavlink href="/logout">LOGOUT</StyledNavlink></StyledNavItem>
                    </React.Fragment>)}

                    {user !== undefined && (user.isAdmin || user.isRegionalAdmin || user.isStudyAdmin) && (
                            <React.Fragment> 
                                <StyledNavItem><StyledNavlink comparison={currentPage} href="/admin-home">ADMIN PANEL</StyledNavlink></StyledNavItem>     
                            </React.Fragment>              
                    )}
                </RightAlignedNav>
            </Collapse>
        </StyledNavbar>
    )
}

const RightAlignedNav = styled(Nav)`
    justify-content: end;
`

const StyledNavToggler = styled(NavbarToggler)`
margin-right: 3%
`

const StyledNavBrand = styled(NavbarBrand)`
padding-left: 3%;
`

const StyledNavItem = styled(NavItem)`
padding-left: 5px;
padding-right: 5px;
`

const StyledNavlink = styled(NavLink)`
    font-weight: 600;
    text-align: center;
    width: 100%;
    color: black;
    border-radius: 10px;
    &:hover {
        background: #9780af;
        border-radius: 10px;
        box-shadow: 0 0.25rem .5rem #9780af;
        color: white;
}
border-radius: ${props => props.href.includes(props.comparison) ? "10px" : ""};
box-shadow: ${props => props.href.includes(props.comparison) ? "0 0.25rem .5rem lightgray" : ""};
background: ${props => props.href.includes(props.comparison) ? "lightgray" : ""};
`

const StyledNavbar = styled(Navbar)`
    position: fixed;
    z-index: 10000;
    width: 100%;
    border-bottom-style: solid;
    border-color: #372844;
    background-color: rgba(255, 255, 255, 1);
`

export default Navigationbar;