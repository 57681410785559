import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Fade, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap';
import styled from 'styled-components';
import { TicketStrings } from '../adminPages/tickets/ticketStrings';
import { OpenTicket } from '../../services/firestore';

const Footer = ({ user }) => {
    const [show, setShow] = useState(false);
    const [reportedFeedback, setReportedFeedback] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [feedback, setFeedback] = useState("");
    const toggle = () => setModal(!modal);

    const controlFooter = () => {
        var d = document.documentElement;
        var offset = d.scrollTop + window.innerHeight;
        var height = d.offsetHeight;

        if (offset >= height) {
            setShow(true);
        }
        else{
            setShow(false);
        }
    }

    useEffect(() => {
        controlFooter();
        window.addEventListener('scroll', controlFooter)
        return () => {
            window.removeEventListener('scroll', controlFooter);
        }
      }, [document.documentElement.offsetHeight]);

    const FeedbackPrompt = () => {
        setModalTitle("Feedback");
        setModalMessage("Please enter any feedback you have for us");
        setReportedFeedback(false);
        toggle();
    }

    const SendFeedback = () =>{  
        OpenTicket(user.username, user.uid, user.email, TicketStrings.feedback, feedback, false, FollowUpTicketSubmission)
    }

    const FollowUpTicketSubmission = (success) => {
        setReportedFeedback(true);
        if(success){
            setModalTitle("Feedback Received");
            setModalMessage("Thanks so much for your feedback!");
        }
        else{
            setModalTitle("Something went wrong");
            setModalMessage("Unable to send in feedback please follow up with an administrator at HIMALAYAStrial@gmail.com");
        }
    }

    return (
        <React.Fragment>
            <FooterSpacer />
            <StyledModal isOpen={modal}>
                <ModalHeader>{modalTitle}</ModalHeader>
                <ModalBody>
                    {modalMessage}
                    {!reportedFeedback && <hr />}
                    {!reportedFeedback && <StyledInput type="textarea" name="feedback" id="feedback" onChange={e => setFeedback(e.target.value)}/>}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>Close</Button>
                    {!reportedFeedback && <Button color="primary" onClick={SendFeedback}>Submit</Button>} 
                </ModalFooter>
            </StyledModal>
            <StyledContainer fluid={true}>
                    <StyledRow>
                        <Col>
                            <StyledButton color="primary" href="/frequently-asked-questions">FAQ</StyledButton>
                            <StyledButton color="primary" href="/terms">Terms of Use</StyledButton>
                            {user.isAccountAccepted && <StyledButton type="button" color="primary" onClick={FeedbackPrompt}>Submit Feedback</StyledButton>}
                        </Col>
                    </StyledRow>
                    <Row>
                        <Col>&#169; 2021 HIMALAYAS Trial</Col>
                    </Row>
            </StyledContainer>
        </React.Fragment>
    );
}

export default Footer;

const FooterSpacer = styled.div`
    margin-top: 50px;
`

const StyledButton = styled(Button)`
    margin-left: 5px;
    margin-righT: 5px;
`

const StyledRow = styled(Row)`
    margin-top: 10px;
`

const StyledContainer = styled(Container)`
    text-align: center;
    bottom: 0px;
    z-index: 10000;
    width: 100%;
    border-top-style: solid;
    border-color: #372844;
    background-color: rgba(255, 255, 255, 0.97);

    z-index: 10000;
    margin-bottom: -17px;
    padding-top: 10px;
`

const StyledModal = styled(Modal)`
    position: absolute;
    left: 30%;
    top: 40%;
    min-width: 30%;
`

const StyledInput = styled(Input)`
margin-left: 10px;
border-color: black;
&:focus{
    border-color: #372844;
    box-shadow: 0 0.5rem 1rem #9780af;
}
`