export const TicketStrings = {
    message: "Message Concern",
    feedback: "General Feedback",
    bug: "Bug",
    modulefeedback: "Module Feedback"
}

export const TicketTypeArray = [
    {_id:"", name:""},
    {_id:TicketStrings.message, name:TicketStrings.message},
    {_id:TicketStrings.feedback, name:TicketStrings.feedback},
    {_id:TicketStrings.bug, name:TicketStrings.bug},
    {_id:TicketStrings.modulefeedback, name:TicketStrings.modulefeedback},
]

export const TicketResolvedArray = [
    {_id:"", name:""},
    {_id: "false", name:"False"},
    {_id: "true", name:"True"},
]