import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Input, Button, Label, Alert, FormFeedback, FormGroup, Form } from 'reactstrap';
import * as aboutme from "../../libraries/aboutme"
import styled from 'styled-components';
import groupImage from "../../images/MorningRun.png";
import { updateDocumentProperty } from '../../services/firestore';
import { db, auth } from '../../services/firebase';
import PopUpText from '../common/popUpText';
import CheckBox from '../common/formComponents/checkBox';
import { days, years, months } from '../../libraries/dates';
import { GetLongituteAndLatitute } from '../../services/helper';
import { update } from 'react-spring';

const FirstTimePassword = (props) => {
    const [username, setUsername] = useState("");
    const [currentName, setCurrentName] = useState(props.user.username);
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [password, setPassword] = useState({value: "", validity: "empty"});
    const [confirmPassword, setConfirmPassword] = useState({value: "", validity: "empty"});
    const [errorMessage, setErrorMessage] = useState("");
    const [confirmMessage, setConfirmMessage] = useState(false);

    const UpdateUserName = () => {
        if (username === ""){
            return;
        }
        console.log("About to update name.");
        updateDocumentProperty(db, "users", props.user.uid, "username", username);
        setCurrentName(username);
    }

    const validatePassword = (value) => {
        setErrorMessage("");
        if(value.length > 5 && value.match(/\d+/g)){
            setPassword({value: value, validity: "valid"});
        }
        else if(value === ""){
            setPassword({value: value, validity: "empty"});
        }
        else{
            setPassword({value: value, validity: "invalid"})
        }
    }

    const validateComparePassword = (value) =>{
        setErrorMessage("");
        if(value === password.value){
            setConfirmPassword({value: value, validity: "valid"});
        }
        else if(value ===""){
            setConfirmPassword({value: value, validity: "empty"});
        }
        else{
            setConfirmPassword({value: value, validity: "invalid"});
        }
    }

    const validateForm = () => {
        if(password.validity !== "valid"){
            return false;
        }
        else if(confirmPassword.validity !== "valid"){
            return false;
        }
        return true;
    }

     const doSubmit = () => {
        if(!validateForm) { return; }

        if(auth.currentUser){
            auth.currentUser.updatePassword(password.value)
            .then((userCredential) => {
                setConfirmMessage(true);
                setPasswordChanged(true);
                updateDocumentProperty(db, "users", props.user.uid, "passwordHasChanged", true);
            })
            .catch((error) => {
                var errorMessage = error.message;
                setErrorMessage(errorMessage);
            });    
        }
    }

    return (
        <StyledFragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage src={groupImage} alt="Keep at it!" />
                </ParentDiv>   
            </HeightDiv>
            <StyledContainer>
                {/*}
                    
                    <br />
                    <hr />
                */}
                <Row>
                    <h1 style={{textAlign:"center"}}>Password Reset</h1>
                    <ExplainingP>You must change your password before accessing the site. It must be at least 6 characters and contain at least one number.</ExplainingP>
                </Row>
                <Row>
                    <Col>
                        <Label>Password</Label>
                        <StyledInput valid={password.validity === "valid"} invalid={password.validity === "invalid"} type="password" name="password" id="password" placeholder="Password..." onChange={e => validatePassword(e.target.value)} />
                        {password.validity === "invalid" && <FormFeedback invalid>Password must be at least 6 characters long and contain a number</FormFeedback>}
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label>Confirm Password</Label>
                        <StyledInput valid={confirmPassword.validity ==="valid"} invalid={confirmPassword.validity === "invalid"} type="password" name="confirmPassword" id="confirmPassword" placeholder="Confirm Password..." onChange={e => validateComparePassword(e.target.value)} />
                        {confirmPassword.validity === "invalid" && <FormFeedback invalid>Passwords must match</FormFeedback>}
                        <br />
                    </Col>
                </Row>
                <Row>
                    {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                    {confirmMessage && <Alert color='success'>Password changed successfully.</Alert>}
                    <Button disabled={!validateForm()} color="primary" onClick={() => {doSubmit()}}>Change Password</Button>
                    <br />
                </Row>
            </StyledContainer>
        </StyledFragment>
    );
}

export default FirstTimePassword;

const StyledP = styled.p`
    margin: 0px;
`;

const BoldP = styled.p`
    font-weight: bold;
    font-size: 16px;
    color: #372844;
    margin-top: 0px;
    margin-bottom: 0px;
`;

const ExplainingP = styled.p`
    font-size: 14px;
    color: #372844;
    margin-top: 0px;
    margin-bottom: 0px;
    font-style: italic;
`;

const RedText = styled.b`
    color:Tomato;
`

const StyledInput = styled(Input)`
    margin-bottom: 10px;
    &:focus{
        border-color: #372844;
        box-shadow: 0 0.5rem 1rem #9780af;
    }
`

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #9780af;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 500px;
    margin-top: 15px;
`

const StyledFragment = styled.div`
    height: 100%;
    min-height: ${window.innerHeight - 255}px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;
 