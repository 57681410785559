import React, {useState} from 'react';
import { Container, Row, Col, Label, Button, Input } from 'reactstrap';
import { auth } from '../../services/firebase';
import { UpdateUserInformation } from '../../services/firestore';
import * as options from '../../libraries/cancerhistory'
import CheckBox from '../common/formComponents/checkBox';
import groupImage from "../../images/MorningRun.png";
import styled from 'styled-components';
import PopUpText from '../common/popUpText';

const CancerHistory = () => {
     const [cancerlocation, setCancerLocation] = useState("");
     const [otherlocation, setOtherLocation] = useState("");
     const [cancerstatus, setCancerStatus] = useState("");
     const [treatmentdescription, setTreatmentDescription] = useState("");
     const [supportgroup, setSupportGroup] = useState("");
     const [supportgroupname, setSupportGroupName] = useState("");
     const [learnaboutus, setLearnAboutUs] = useState([]);
     const [testNumber, setTestNumber] = useState(0);

     const doSubmit = () => {
        var newProperties = {
            cancerlocation: cancerlocation,
            otherlocation: otherlocation,
            cancerstatus: cancerstatus,
            treatmentdescription: treatmentdescription,
            hasbeenpartofsupportgroup: supportgroup,
            supportgroupname: supportgroupname,
            howdidtheylearnaboutus: learnaboutus,
            secondPageComplete: true
        }
        UpdateUserInformation(auth.currentUser.uid, newProperties, moveToNextWindow)
    };

    const HandleLearnAboutCheckbox = (value, checked) =>{
        var learnArray = learnaboutus;

         
        const match = learnArray.find(element => {
            if (element.includes(value)) {
              return true;
            }
          });

         if(!match){
            learnArray.push(value);
         }
         else{
             for(let i = 0; i < learnArray.length; i++){
                 if(learnArray[i] === value){
                    learnArray.splice(i, 1);
                 }
             }
         }

         setLearnAboutUs(learnArray);
         setTestNumber(testNumber + 1);
    }

    const moveToNextWindow = () => {
        window.location = "/photo-upload";
    }

    return ( 
        <React.Fragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage src={groupImage} alt="Keep at it!" />
                </ParentDiv>
            </HeightDiv>
            <MarginDiv>
                <StyledContainer>
                <br />
                <h1>My Public Info - Cancer History</h1>
                <Row>
                    <Col>
                        <p>
                        This information will be displayed on your profile to other users. Please do complete this section as it 
                        allows other members to know more about how similar you are in your cancer history and treatment status.
                        Women have told us that sometimes this information is important to them.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col lg="7" md="7">
                    <Label>Your most recent cancer diagnosis? (if other, fill in below)<PopUpText text="What was your most recent cancer diagnosis?" /></Label>
                        <StyledInput type="select" name="cancerlocation" id="cancerlocation" onChange={e => setCancerLocation(e.target.value)}>
                            {options.cancerlocation.map((location) => (
                                <option  key={location._id} value={location._id}>
                                    {location.name}
                                </option>
                            ))}
                        </StyledInput>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col lg="6" md="6">
                        <Label>If you have had another cancer diagnosis, please list it here:</Label>
                        <StyledInput maxlength="280" name="cancerlocation" id="cancerlocation" placeholder="other location..." onChange={e => setOtherLocation(e.target.value)} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col lg="8">
                    <Label>Which of the following best describes you now?</Label>
                        <StyledInput type="select" name="cancerstatus" id="cancerstatus" onChange={e => setCancerStatus(e.target.value)}>
                            {options.cancerstatus.map((status) => (
                                <option  key={status._id} value={status._id}>
                                    {status.name}
                                </option>
                            ))}
                        </StyledInput>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col lg="6" md="9">
                        <Label>Please briefly describe your cancer treatments:<PopUpText text="Please list and describe the treatments you received for your most recent cancer diagnosis." /></Label>
                        <StyledInput maxlength="280" name="treatmentdescription" id="treatmentdescription" placeholder="Treatment..." onChange={e => setTreatmentDescription(e.target.value)} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col lg="7" md="8">
                    <Label>What, if any, cancer support organizations have you worked with?<PopUpText text="Please list any cancer support services (e.g. exercise, psychosocial, art, music) you have participated in and the organization that provided them." /></Label>
                        <StyledInput type="select" name="supportgroup" id="supportgroup" onChange={e => setSupportGroup(e.target.value)}>
                            {options.binaryanswer.map((status) => (
                                <option  key={status._id} value={status._id}>
                                    {status.name}
                                </option>
                            ))}
                        </StyledInput>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col lg="6" md="8">
                    <Label>If yes, what program? (list the name and locaiton if possible, for example: HEALTH program, Toronto Rehabilitation Institute or WE-Can program, ELLICSR - Princess Margaret Cancer Centre)</Label>
                        <StyledInput maxlength="100" name="supportgroupname" id="supportgroupname" placeholder="Support Group..." onChange={e => setSupportGroupName(e.target.value)} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col lg="6" md="8">
                        <Label>How did you learn about us?</Label>
                        <CheckBox 
                            useranswers={learnaboutus} 
                            name="learnaboutus"
                            id="learnaboutus"
                            options={options.learnaboutus} 
                            onChange={e => HandleLearnAboutCheckbox(e.target.value)} 
                        />
                    </Col>
                </Row>
                <br />
                <Button href="/about-me" color="secondary">Back</Button>
                <Button color="primary" onClick={() => {doSubmit()}}>Save & Continue</Button>
            </StyledContainer>
        </MarginDiv>
    </React.Fragment> );
}

export default CancerHistory;

const MarginDiv = styled.div`
    margin-left: 15px;
    margin-right: 15px;
`

const StyledInput = styled(Input)`
&:focus{
    border-color: #372844;
    box-shadow: 0 0.5rem 1rem #9780af;
}
`

const StyledContainer = styled(Container)`
    background-color: white;
    border-radius: 10px;
    border: solid;
    border-color: #9780af #372844 #372844 #9780af;
    margin: auto;
    margin-top: 15px;
    box-shadow: 0 0.5rem 1rem #9780af;
    padding: 20px;
    max-width: 1000px;
    margin-bottom: 70px;
`
const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;