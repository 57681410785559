import { Component } from 'react';
import { auth } from '../../services/firebase'

class Logout extends Component {
    async componentDidMount() {
        await auth.signOut();
        window.location = "/";
    }

    render() { 
        return null;
    }
}
 
export default Logout;