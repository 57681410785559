import { db, storage } from './firebase.js';
import firebase from "firebase/app";
import { calculateAge } from './helper.js';

const userRef = db.collection("users");
const teamRef = db.collection("teamMembers").doc("TeamList");
const generalUserList = db.collection("userList");

export const updateDocumentProperty = (database, collection, document, property, value, successCallback, failureCallback) => {
    database.collection(collection).doc(document).update({
        [property]: value
    })
    .then(() => {
        if (successCallback !== undefined) {
            successCallback();
        }
    })
    .catch((error) => {
        if(failureCallback){
            console.log(error);
            failureCallback(error);
        }
    })
};

export const updateDocument = (database, collection, document, value, successCallback, failureCallback) => {
    database.collection(collection).doc(document).update(
        value
    )
    .then(() => {
        if (successCallback !== undefined) {
            successCallback();
        }
    })
    .catch((error) => {
        if(failureCallback){
            console.log(error);
            failureCallback(error);
        }
    })
};

export const listenToDocument = (database, collection, document, successCallback, failureCallback) => {
    const observer = database.collection(collection).doc(document).onSnapshot(docSnapshot => {
        if (docSnapshot.exists) {
            successCallback(docSnapshot.data());
            console.log("Current data: ", docSnapshot.data());
        } else {
            console.log("No such document!");
        }
    }, err => {
        console.log(`Encountered error: ${err}`);
    }
    );
    return observer;
}

export const listenToCollection = (database, collection, successCallback, failureCallback) => {
    const query = database.collection(collection).where("uid","!=","");
    const observer = query.onSnapshot(querySnapshot => {
        var values = [];
        querySnapshot.forEach((doc) => {
            values.push(doc.data());
        })
        successCallback(values);
        /*
        querySnapshot.docChanges().forEach(change => {
            if (change.type === 'added') {
                console.log('Added User: ', change.doc.data());
              }
              if (change.type === 'modified') {
                console.log('Modified User: ', change.doc.data());
              }
              if (change.type === 'removed') {
                console.log('Removed User: ', change.doc.data());
              }
        })
        */
    })
    return observer;
}; 

export function GetUserInformation(userid, successcallback){
        userRef.doc(userid).get().then(function(doc) {
        if (doc.exists) {
            successcallback(doc.data());
        } else {
            console.log("No such document!");
        }
    }).catch(function(error) {
        console.log("Error getting document:", error);
    });
}

export function GetUserMessages(userid, successcallback, userlist){
    var unreadMessages = 0;
    db.collection("users").doc(userid).collection("messages").get().then((querySnapshot) => {    
        querySnapshot.forEach((doc) => {
            const conversation = doc.data();
            const user = userlist.find(obj => obj.uid === conversation.recipientid);

            if(conversation.messages.length === 0) {return 0;}

            if(user !== undefined){
                if(!user.isArchived && !conversation.messages[conversation.messages.length - 1].read &&
                    conversation.messages[conversation.messages.length - 1].sender !== userid){
                    unreadMessages += 1;
                }
            }
        });
        successcallback(unreadMessages);
    })
}

export function GetTeamMemberInformation(userid, followupfunction){
    teamRef.get().then(function(doc) {
        var teamList = doc.data();
    if (teamList[userid]) {
        followupfunction(teamList[userid]);
    } else {
        console.log("No such document!");
        window.location = "/not-found"
    }
}).catch(function(error) {
    console.log("Error getting document:", error);
});
}

export function DeleteTeamMember(memberId, followUpFunction){
    db.collection("teamMembers").doc("TeamList").get().then(function(doc) {
        var teamList = doc.data();
        if (teamList[memberId]) {
            delete teamList[memberId];
            db.collection("teamMembers").doc("TeamList").set(teamList)
            .then(function(){
                if(followUpFunction){
                    followUpFunction();
                }
            })

        } else {
            console.log("Unable to find TeamList");
        }
    }).catch(function(error) {
        console.log("Error getting document:", error);
    });
}

export function RemoveUserFromGeneralList(profileId, followUpFunction){
    generalUserList.get().then(function(doc) {
        var userList = doc.data();
        if (userList[profileId]) {
            delete userList[profileId];
            generalUserList.set(userList)
            .then(function(){
                if(followUpFunction){
                    followUpFunction();
                }
            })
        } else {
            console.log("Unable to find General User List");
        }
    }).catch(function(error) {
        console.log("Error getting document:", error);
    });
}

export function UpdateUserInformation(profileId, properties, followUpFunction){
    return db.runTransaction((transaction) => {
        var userInformation;
        var userList;
        return transaction.get(userRef.doc(profileId)).then((doc) =>{
            userInformation = doc.data();
          return transaction.get(generalUserList.doc("General")).then((doc) => {
                userList = doc.data();
                transaction.update(userRef.doc(profileId), properties);

                if(userInformation.isAccountAccepted || properties.isAccountAccepted !== undefined){
                    var userListData = {
                        uid: userInformation.uid,
                        username: userInformation.username,
                        city: userInformation.city,
                        cancerlocation: userInformation.cancerlocation,
                        dateofbirth: userInformation.dateofbirth,
                        coordinates: userInformation.coordinates,
                        imageurl: userInformation.imageurl,
                        isAccountAccepted: userInformation.isAccountAccepted,
                        isArchived: userInformation.isArchived,
                        fitnesslevel: userInformation.fitnesslevel,
                        fitnesslocation: userInformation.fitnesslocation,
                        fitnesstime: userInformation.fitnesstime,
                        group: userInformation.group,
                        activities: properties.activities,
                    }
                    Object.entries(properties).map(item => {
                        if(userListData[item[0]] !== undefined){
                            userListData[item[0]] = item[1];
                        }  
                    })

                    Object.entries(userListData).map(item => {
                        if(item[1] === undefined){
                            delete userListData[item[0]];
                        }
                    })

                    userList[profileId] = userListData;
                    transaction.update(generalUserList.doc("General"), userList);
                }
            })
        })
    }).then(function() {
        if(followUpFunction){
            followUpFunction(true);
        }
    }).catch(function(error) {
        console.log("Something went wrong when updating the user: " + error);
    });
}

//Need a way to check if the user has their email validated here
export function ReturnUsers(followUpFunction){
    var tempusers = [];
    db.collection("users").get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) { //TODO currently if the user has any undefined properties here it will crash, handle that
            const user = doc.data();
            if(user){
                var dateofbirth = "N/A";
                var age = "N/A"
                if(user.dateofbirth !== undefined){
                    dateofbirth = new Date(user.dateofbirth.year, user.dateofbirth.month, user.dateofbirth.day);
                    age = calculateAge(dateofbirth);
                }

                var profileurl = "/profile/" + user.uid;
                var tempuser = {
                    username: user.username, 
                    age: age, 
                    imageurl: user.imageurl, 
                    postalcode: user.postalcode, 
                    uid: user.uid, 
                    cancerlocation: user.cancerlocation,
                    fitnesslevel: user.fitnesslevel, 
                    profileurl: profileurl,
                    isAccountAccepted: user.isAccountAccepted,
                    emailVerified: user.emailVerified,
                    isArchived: user.isArchived,
                    isAdmin: user.isAdmin,
                    city: user.city,
                    email: user.email,
                    modules: user.modules,
                    studyId: user.studyId,
                    group: user.group,
                };     
            }
            tempusers.push(tempuser);
        });
        if(followUpFunction){
            followUpFunction(tempusers);
        }
    })
}

export function ArchiveUser(profileId, followUpFunction){
    return db.runTransaction((transaction) => {
        var userInformation;
        var userList;
        var properties = { isArchived: true }
        return transaction.get(userRef.doc(profileId)).then((doc) =>{
            userInformation = doc.data();
            return transaction.get(generalUserList.doc("General")).then((doc) => {
                    userList = doc.data();
                    transaction.update(userRef.doc(profileId), properties);

                    delete userList[profileId];
                    transaction.set(generalUserList.doc("General"), userList);
                    
            })
        })
    }).then(function() {
        if(followUpFunction){
            followUpFunction();
        }
    }).catch(function(error) {
        console.log("Something went wrong when deleting the user: " + error);
    });
}

export function DeleteUser(profileId, followUpFunction){
    return db.runTransaction((transaction) => {
        var userInformation;
        var userList;
        var properties = { isArchived: true }
        return transaction.get(userRef.doc(profileId)).then((doc) =>{
            userInformation = doc.data();
          return transaction.get(generalUserList.doc("General")).then((doc) => {
                userList = doc.data();
                transaction.update(userRef.doc(profileId), properties);

                delete userList[profileId];
                transaction.set(generalUserList.doc("General"), userList);
                
            })
        })
    }).then(function() {
        if(followUpFunction){
            followUpFunction();
        }
    }).catch(function(error) {
        console.log("Something went wrong when deleting the user: " + error);
    });
}

export const UploadImageToStorage = (imgSource, path, child, successcallback, failcallback, id="Null") => { 
    const uploadTask = storage.ref(path + child).put(imgSource);
    uploadTask.on(
    "state_changed",
    snapshot => {
        const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
    },
    error => {
        console.log(error);
        if(failcallback){
            failcallback(error);
        }
    },
    () => 
    {
        storage
        .ref(path)
        .child(child)
        .getDownloadURL()
        .then(url => {
            successcallback(url, id);
        });
    });   
};

export const OpenTicket = (username, userId, email, type, context, resolved, followUpFunction, message = "", personInvolved = "") => {
    var docRef = db.collection("tickets").doc(db.collection("tickets").length);
    
    var ticket = {
        username: username,
        userId: userId,
        email: email,
        timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
        type: type,
        context: context,
        resolved: resolved,
    }
    if(message !== ""){
        ticket.messagehistory = message;
        ticket.personinvolved = personInvolved;
    }

    docRef.set(ticket).then(function() {
        followUpFunction(true);
    }).catch(function(error) {
        followUpFunction(false);
    });
}

export const DeleteFromStorage = (storageRef, child, successcallback) => {
    var storageReference = storage.ref();
    var ref = storageReference.child(storageRef + child);
    // Delete the file
    ref.delete().then(() => {
        if(successcallback){
            successcallback();
        }
    }).catch((error) => {
    // Uh-oh, an error occurred!
    });
}

