import React, {useState, useEffect} from 'react';
import { Container, Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ContactInput from './contactInput';
import { db } from '../../../services/firebase';
import styled from 'styled-components'

const ContactPageForm = () => {
    const [primaryText, setPrimaryText] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");

    const toggle = () => setModal(!modal);
    const docRef = db.collection("webPages").doc("contact")

    useEffect(() => {
        ReturnContactPageInformation();   
    }, []);

    const ReturnContactPageInformation = () =>{
        docRef.onSnapshot((doc) => {
            if(doc.data()){
                const data = doc.data();
                if(data.primaryText){
                    setPrimaryText(data.primaryText);
                }
            }
            else{
                docRef.set({ primaryText })
            }
        });
    }

    const UpdatePrimaryText = (text) => {
        docRef.update({ primaryText: text }).then(() => {
            setModalTitle("Text Content Updated");
            setModalMessage("Successfully updated text for Contact Page");
            toggle();
        })
        .catch((error) => {
            setModalTitle("Something went wrong");
            setModalMessage("An error occurred when trying to update text");
            toggle();
        });
    }

    return ( <Container>
        <Row>
            <Col>
                <h1>Ethics and Study Content</h1>
            </Col>
        </Row>
        <Row>
            <Col>
                <h2>Contacts: </h2>
                <ContactInput returnDataCallback={UpdatePrimaryText} objectList={primaryText} />
            </Col>
        </Row>
        <Row>
            <Col>
                <StyledModal isOpen={modal}>
                    <ModalHeader>{modalTitle}</ModalHeader>
                        <ModalBody>
                            {modalMessage}
                        </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggle}>Done</Button>
                    </ModalFooter>
                </StyledModal>
            </Col>
        </Row>
    </Container> );
}
 
export default ContactPageForm;

const StyledModal = styled(Modal)`
    position: absolute;
    left: 40%;
    top: 40%;
`