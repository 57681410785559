import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { db } from '../../services/firebase';
import styled from 'styled-components'

const EthicsAndStudy = () => {
    const [primaryText, setPrimaryText] = useState([]);

    const docRef = db.collection("webPages").doc("contact")

    useEffect(() => {
        ReturnContactPageInformation();   
    }, []);

    const ReturnContactPageInformation = () =>{
        docRef.onSnapshot((doc) => {
            setPrimaryText(doc.data().primaryText);
        });
    }

    return (
        <React.Fragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage />
                </ParentDiv>   
            </HeightDiv>
            <StyledContainer>
                <Row><h3>Ethic and Study Contacts</h3></Row>
                <br />
                <Row>
                    <Col>
                        <Container>
                            {primaryText.map((entry, i) => (
                                <StyledContactRow key={i}>
                                    <Col lg="2">
                                        <h4>{entry.city}</h4>
                                     </Col>
                                     <Col lg="10">
                                         <Container>
                                         {entry.contactName &&<Row>
                                                <Col md="6" xs="12">
                                                    <h5><b>Study Coordinators: </b></h5>
                                                </Col>
                                                <Col md="6" xs="12">
                                                    <ContactText>{entry.contactName}</ContactText>
                                                </Col>
                                            </Row>}
                                            {entry.investigators && <Row>
                                                <Col md="6" xs="12">
                                                    <h5><b>Principal Investigator:</b></h5>
                                                </Col>
                                                <Col md="6" xs="12">
                                                    <ContactText>{entry.investigators}</ContactText>
                                                </Col>
                                            </Row>}
                                            {entry.ethicsboard && <Row>
                                                <Col md="6" xs="12">
                                                    <h5><b>Research and Ethics Board:</b></h5>
                                                </Col>
                                                <Col md="6" xs="12">
                                                    <ContactText>{entry.ethicsboard}</ContactText>
                                                </Col>
                                            </Row>}
                                        </Container>
                                    </Col>
                                </StyledContactRow>
                            ))}
                        </Container>
                    </Col>
                </Row>
            </StyledContainer>
        </React.Fragment>
    );
}
 
export default EthicsAndStudy;

const ContactText = styled.p`
    font-size: large;
`

const StyledContactRow = styled(Row)`
    padding-top: 10px;
    border-bottom: 2px solid #372844;
    padding: 15px;
`

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #372844;
    border-radius: 10px;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 1000px;
    margin-top: 15px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #dddddd;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;