import React, { useState, useEffect } from 'react';
import ProfileCards from './profileComponents/profileCards';
import { Container, Row, Col } from 'reactstrap';
import Select from '../common/formComponents/select';
import Input from '../common/formComponents/input';
import * as options from '../../libraries/cancerhistory'
import * as aboutMeOptions from "../../libraries/aboutme";
import * as filters from "../../libraries/filters"
import { auth, db } from '../../services/firebase';
import styled from 'styled-components';
import { calculateAge, getDistanceFromLatLonInKm } from '../../services/helper';
import { GetUserInformation } from '../../services/firestore';

 const ProfileList = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [city, setCity] = useState("");
    const [cancerlocation, setCancerLocation] = useState("");

    const [activities, setActivities] = useState("");
    const [fitnessLevel, setFitnessLevel] = useState("");
    const [fitnessLocation, setFitnessLocation] = useState("");
    const [fitnessTime, setFitnessTime] = useState("");

    const [minage, setMinAge] = useState("18");
    const [maxage, setMaxAge] = useState("");
    const [cities, setCities] = useState([]);
    const [distance, setDistance] = useState("");

    const cityRef = db.collection("webPages").doc("global")

   const ReturnGlobalPageInformation = () =>{
       cityRef.onSnapshot((doc) => {
           const data = doc.data();
           if(doc.data()){
               if(data.primaryText){
                   setCities(data.primaryText);
               }
           }
       });
   }

    useEffect(() => {
        GetUserInformation(auth.currentUser.uid, getUsers);
        ReturnGlobalPageInformation();   
    }, []);

    const getUsers = (currentUser) => {
        let usersRef = db.collection("users");
      
        // Start with a reference to the collection
        let queryRef = usersRef; 
      
        // Adjust the query based on the current user's role and region
        if (currentUser.isSiteAdmin) {
          // Site admins get all users without filtering by region
          queryRef = usersRef;
        } else if (currentUser.region !== undefined && currentUser.region !== "Region") {
          // Regional admins or specific non-admin roles get users by region
          queryRef = usersRef.where("region", "==", currentUser.region)
                            .where("isArchived", "==", false)
                            .where("firstTimeLogin", "==", false)
                            .where("secondPageComplete", "==", true);
        } else {
          // Add logic for other roles as needed, potentially defaulting to no operation
          console.log("No operation for the current user's role in fetching users");
          return;
        }
      
        // Execute the query
        queryRef.get().then((querySnapshot) => {
          let tempUsers = [];
          querySnapshot.forEach((doc) => {
            const userData = doc.data();
            
            // Check if the user is not staff or doesn't have the isStaff field at all
            if (userData.isStaff === false || !userData.hasOwnProperty('isStaff')) {
              tempUsers.push({
                ...userData,
                uid: doc.id, // Assuming uid is the document ID
                // Any additional processing or transformation you need
              });
            }
          });
      
          // Handle the filtered users (e.g., update state, render UI)
          handleUsers(tempUsers);
        }).catch((error) => {
          console.error("Error fetching users:", error);
        });
    };

      /*
    const getusers = (currentUser) =>{
        var tempUsers = [];
        console.log("Current User");
        console.log(currentUser.region);
        console.log(currentUser);
        db.collection("userList").doc("General").get().then(function(doc) {
            if (doc.exists) {
                var userList = doc.data();
                var usersCoordinates = userList[auth.currentUser.uid].coordinates;

                for (const user in userList) {
                    var compareCoordinates = userList[user].coordinates;
                    var dateofbirth = "N/A"
                    if(userList[user].dateofbirth){
                        dateofbirth = new Date(userList[user].dateofbirth.year, userList[user].dateofbirth.month, userList[user].dateofbirth.day);
                    }
                    var profileurl = "/profile/" + userList[user].uid;
                    
                    var age = "N/A";
                    if(dateofbirth !== "N/A"){
                        age = calculateAge(dateofbirth);
                    }
                    
                    var distance;
                    if(!compareCoordinates || !usersCoordinates || compareCoordinates === "N/A" || usersCoordinates ==="N/A"){
                        distance = "N/A";
                    }
                    else{
                        distance = getDistanceFromLatLonInKm(usersCoordinates["lat"], usersCoordinates["lng"], compareCoordinates["lat"], compareCoordinates["lng"]);
                        distance =  distance.toFixed(0);
                    }
                    var tempuser = {
                        username: userList[user].username,
                        age: age, 
                        imageurl: userList[user].imageurl, 
                        postalcode: userList[user].postalcode, 
                        uid: userList[user].uid, 
                        cancerlocation: userList[user].cancerlocation,
                        profileurl: profileurl,
                        city: userList[user].city,
                        distance: distance,
                        fitnesslevel: userList[user].fitnesslevel,
                        fitnesslocation: userList[user].fitnesslocation,
                        fitnesstime: userList[user].fitnesstime,
                        activities: userList[user].activities,
                        region: userList[user].region,
                    };     
                    //case statement based on user type
                    if (!currentUser.isAdmin){
                        if((tempuser.region !== undefined && currentUser.region !== undefined && tempuser.region === currentUser.region && currentUser.region !== "Undefined" && !tempuser.isAdmin)){
                            tempUsers.push(tempuser);
                        }
                    }
                    else if (currentUser.isSiteAdmin){
                        tempUsers.push(tempuser);
                    }
                    else if (currentUser.isRegionalAdmin){
                        if((tempuser.region !== undefined && currentUser.region !== undefined && tempuser.region === currentUser.region && currentUser.region !== "Undefined")){
                            tempUsers.push(tempuser);
                        }
                    }
                    else if (currentUser.isStaff){
                        //Staff don't see anyone, but they can be seen. Does that work as we think it will?
                    }
                }
                handleUsers(tempUsers);
            } else {
                console.log("Unable to find General User List");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }
    */

    const handleUsers = (users) => {
        var tempUsers = [];
        for(var i = 0; i < users.length; i++){
            if(auth.currentUser.uid !== users[i].uid){
                tempUsers.push(users[i]);
            }
        }
        setUsers(tempUsers);
        setFilteredUsers(tempUsers);
    }

    const handleKeywordChange = (value) => {
        setKeyword(value);
        setFilteredUsers(filterResults(value, "keyword"));
    };

    const handleCityChange = (value) => {
        setCity(value);
        setFilteredUsers(filterResults(value, "city"));
    }

    const handleCancerLocationChange = (value) => {
        setCancerLocation(value);
        setFilteredUsers(filterResults(value, "cancerlocation"));
    }

    const handleMinAgeChange = (value) => {
        setMinAge(value);
        setFilteredUsers(filterResults(value, "minage"));
    }

    const handleMaxAgeChange = (value) => {
        setMaxAge(value);
        setFilteredUsers(filterResults(value, "maxage"));
    }

    const handleDistanceChange = (value) => {
        setDistance(value);
        setFilteredUsers(filterResults(value, "distance"));
    }

    const handleActivityChange = (value) => {
        setActivities(value);
        setFilteredUsers(filterResults(value, "activities"));
    }

    const handleFitnessLevelChange = (value) => {
        setFitnessLevel(value);
        setFilteredUsers(filterResults(value, "fitnesslevel"));
    }

    const handleFitnessLocationChange = (value) => {
        setFitnessLocation(value);
        setFilteredUsers(filterResults(value, "fitnesslocation"));
    }

    const handleFitnessTimeChange = (value) => {
        setFitnessTime(value);
        setFilteredUsers(filterResults(value, "fitnesstime"));
    }

    const filterResults = (newValue, valueType) =>{
        var filteredResults = users;
        var compareValue;

        filteredResults = FilterProperty(filteredResults, valueType, cancerlocation, newValue, "cancerlocation");
        filteredResults = FilterProperty(filteredResults, valueType, city, newValue, "city");
        filteredResults = FilterProperty(filteredResults, valueType, fitnessLevel, newValue, "fitnesslevel");
        filteredResults = FilterProperty(filteredResults, valueType, fitnessLocation, newValue, "fitnesslocation");
        filteredResults = FilterProperty(filteredResults, valueType, fitnessTime, newValue, "fitnesstime");

        compareValue = keyword;
        if(valueType === "keyword"){
            compareValue = newValue;
        }

        if(compareValue !== "")
        {
            var newFilteredResults = [];
            for(var i = 0; i < filteredResults.length; i++)
            {
                if(filteredResults[i].username.toLowerCase().includes(compareValue.toLocaleLowerCase()))
                {
                    newFilteredResults.push(filteredResults[i]);
                }
            }
            filteredResults = newFilteredResults;
        }

        compareValue = activities;
        if(valueType === "activities"){
            compareValue = newValue;
        }

        if(compareValue !== "" && compareValue !== "0"){
            var newFilteredResults = [];

            for(var i = 0; i < filteredResults.length; i++)
            {
                if(filteredResults[i].activities !== undefined)
                {
                    if(filteredResults[i].activities.includes(compareValue))
                    {
                        newFilteredResults.push(filteredResults[i]);
                    }
                }
            }
            filteredResults = newFilteredResults;
        }

        compareValue = distance;
        if(valueType === "distance"){
            compareValue = newValue;
        }

        if(compareValue !== "" && compareValue !== "0"){
            var newFilteredResults = [];

            for(var i = 0; i < filteredResults.length; i++)
            {
                if(filteredResults[i].distance < compareValue)
                {
                    newFilteredResults.push(filteredResults[i]);
                }
            }
            filteredResults = newFilteredResults;
        }

        compareValue = minage;
        if(valueType === "minage"){
            compareValue = newValue;
        }

        if(compareValue !== ""){
            var newFilteredResults = [];
            for(var i = 0; i < filteredResults.length; i++){
                if(filteredResults[i].age >= compareValue){
                    newFilteredResults.push(filteredResults[i]);
                }
            }
            filteredResults = newFilteredResults;
        }

        compareValue = maxage;
        if(valueType === "maxage"){
            compareValue = newValue;
        }

        if(compareValue !== ""){
            var newFilteredResults = [];
            for(var i = 0; i < filteredResults.length; i++){
                if(filteredResults[i].age <= compareValue){
                    newFilteredResults.push(filteredResults[i]);
                }
            }
            filteredResults = newFilteredResults;
        }
        return filteredResults;
    }

    const FilterProperty = (filteredResults, valueType, compareValue, newValue, valueString) => {
        if(valueType === valueString){
            compareValue = newValue;
        }
        if(compareValue !== "" && compareValue !== "N/A")
        {
            var newFilteredResults = [];
            for(var i = 0; i < filteredResults.length; i++)
            {
                if(filteredResults[i][valueString] === compareValue)
                {
                    newFilteredResults.push(users[i]);
                }
            }
            return newFilteredResults;
        }
        return filteredResults;
    }

    function paginate (arr, size) {
        return arr.reduce((acc, val, i) => {
          let idx = Math.floor(i / size)
          let page = acc[idx] || (acc[idx] = [])
          page.push(val)
      
          return acc
        }, [])
    }

    return (
        <MainDiv>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage />
                </ParentDiv>   
            </HeightDiv>
            <MarginDiv>
                <StyledContainer>
                    <Row>
                        <Col>
                            <h4>Connecting with Exercise Partners</h4>
                            <p>HIMALAYAS exercise groups partners connect in lots of different ways. Here are a few ways you and your exercise partners can support each other:</p>
                            <ul>
                                <li>Exchange numbers so that you can text each other on a regular schedule to offer motivation and support</li>
                                <li>Connect over the phone (e.g., to strategize about building more exercise into your days)</li>
                                <li>‘Meet up’ virtually for walks (to keep each other company and support one another in sticking to a walking schedule)</li>
                                <li>Find a new physical activity that you can try together</li>
                                <li>Share exercise tips and strategies that you’ve each found helpful</li>
                                <li>Sign up for a virtual class or exercise program</li>
                            </ul>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        {/*
                        <Col md="3">
                            <h4>How do you want to connect?</h4>
                            <Input
                                name={"keyword"}
                                value={keyword}
                                label={"Username"}
                                onChange={e => handleKeywordChange(e.target.value)}
                            />
                            {false && <Select 
                                name={"city"} 
                                value={city} 
                                label={"City"} 
                                options={cities} 
                                onChange={e => handleCityChange(e.target.value)} 
                            />}
                            {false && <Select 
                                name={"distance"} 
                                value={distance} 
                                label={"Distance"} 
                                options={filters.distanceranges} 
                                onChange={e => handleDistanceChange(e.target.value)} 
                            />}
                            <Input
                                name={"minage"}
                                value={minage}
                                label={"Minimum Age"}
                                onChange={e => handleMinAgeChange(e.target.value)}
                            />
                            <Input
                                name={"maxage"}
                                value={maxage}
                                label={"Maximum Age"}
                                onChange={e => handleMaxAgeChange(e.target.value)}
                            />
                            <Select 
                                name={"cancerlocation"} 
                                value={cancerlocation} 
                                label={"Recent Cancer Diagnosis"} 
                                options={options.cancerlocation} 
                                onChange={e => handleCancerLocationChange(e.target.value)} 
                            />
                            <Select 
                                name={"activities"} 
                                value={activities} 
                                label={"Favorite Activities"} 
                                options={aboutMeOptions.activities} 
                                onChange={e => handleActivityChange(e.target.value)} 
                            />
                            <Select 
                                name={"fitnesslevel"} 
                                value={fitnessLevel} 
                                label={"Exercise / Activity Level"} 
                                options={aboutMeOptions.fitnesslevel} 
                                onChange={e => handleFitnessLevelChange(e.target.value)} 
                            />
                            <Select 
                                name={"fitnesslocation"} 
                                value={fitnessLocation} 
                                label={"Preferred Exercise Location"} 
                                options={aboutMeOptions.activitylocation} 
                                onChange={e => handleFitnessLocationChange(e.target.value)} 
                            />
                            <Select 
                                name={"fitnesstime"} 
                                value={fitnessTime} 
                                label={"Preferred Exercise Time"} 
                                options={aboutMeOptions.activitytime} 
                                onChange={e => handleFitnessTimeChange(e.target.value)} 
                            />
                        </Col>
                        */}
                        <Col>
                            {filteredUsers && <ProfileCards users={paginate(filteredUsers, 16)} />}
                            {filteredUsers.length === 0 && <h3>No results found</h3>}
                        </Col>
                    </Row>    
                </StyledContainer>
            </MarginDiv>
        </MainDiv>
    );
}
 
export default ProfileList;

const MarginDiv = styled.div`
    margin-left: 15px;
    margin-right: 15px;
`

const MainDiv = styled.div`
    min-height: ${window.innerHeight - 255}px;
`

const StyledContainer = styled(Container)`
    box-shadow: 0 0.5rem 1rem #372844;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 1800px;
    margin-top: 15px;
    margin-bottom: 90px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #dddddd;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;