export function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime());

    var currentDate = Date.now();
    currentDate = new Date(currentDate);
    var offset = currentDate.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
}

export function ReturnPostalCodeDistance(postalCode1, postalCode2){

}

export function GetLongituteAndLatitute(userProperties, followUpFunction){
    const url ='https://secure.geonames.org/postalCodeLookupJSON';
    const code = '?postalcode=' + userProperties.postalCode;
    const country = '&country=CA';
    const userName = '&username=deleptual';
    
    fetch(url+ code + country + userName)
    .then(data=>{return data.json()})
    .then(res=>{followUpFunction(res, userProperties)});
}

export const calculateAge = (dob) =>{     
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
 }

 export function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
    var radiusOfEarth = 6371;
    var dLat = deg2rad(lat2-lat1);
    var dLon = deg2rad(lon2-lon1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var distance = radiusOfEarth * c;
    return distance;
  }
  
  function deg2rad(deg) {
    return deg * (Math.PI/180)
  }