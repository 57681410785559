import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { db } from '../../../services/firebase';
import ImageUploadAndCrop from '../../common/formComponents/imageUploadAndCrop';
import ContentListUpdate from './contentListUpdate';
import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UploadImageToStorage } from '../../../services/firestore';

const HomePageForm = () => {
    const [primaryText, setPrimaryText] = useState([]);
    const [partnershipList, setPartnershipList] = useState([]);
    const [studyAnnouncementList, setStudyAnnouncementList] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [backgroundImageUrl, setBackgroundImageUrl] = useState();
    const [backgroundImageFile, setBackgroundImageFile] = useState();
    
    const toggle = () => setModal(!modal);
    const docRef = db.collection("webPages").doc("home")

    useEffect(() => {
        ReturnHomePageInformation();   
    }, []);

    const ReturnHomePageInformation = () =>{
        docRef.onSnapshot((doc) => {
            const data = doc.data();
            if(doc.data()){
                if(data.primaryText){
                    setPrimaryText(data.primaryText);
                }
                if(data.partnershipCarousel){
                    setPartnershipList(data.partnershipCarousel);
                }
    
                if(data.studyAnnouncementCarousel){
                    setStudyAnnouncementList(data.studyAnnouncementCarousel);
                }
                
                if(data.backgroundImageUrl){
                    setBackgroundImageUrl(data.backgroundImageUrl)
                }
            }
            else{
                docRef.set({ primaryText, partnershipCarousel: [],  studyAnnouncementCarousel: [], backgroundImageUrl: [] })
            }

        });
    }

    const UpdatePrimaryText = (text) => {
        docRef.update({ primaryText: text }).then(() => {
            setModalTitle("Text Content Updated");
            setModalMessage("Successfully updated text for Home Page");
            toggle();
        })
        .catch((error) => {
            setModalTitle("Something went wrong");
            setModalMessage("An error occurred when trying to update text");
            toggle();
        });
    }

    const UpdatePartnershipCarousel = (list) => {
         docRef.update({ partnershipCarousel: list }).then(() => {
             setModalTitle("Text Content Updated");
             setModalMessage("Successfully updated partnership carousel");
             toggle();
         })
         .catch((error) => {
             setModalTitle("Something went wrong");
             setModalMessage("An error occurred when trying to update carousel");
             toggle();
         });
    }

    const UpdateStudyCarousel = (list) => {
        docRef.update({ studyAnnouncementCarousel: list }).then(() => {
            setModalTitle("Text Content Updated");
            setModalMessage("Successfully updated study anouncement carousel");
            toggle();
        })
        .catch((error) => {
            setModalTitle("Something went wrong");
            setModalMessage("An error occurred when trying to update carousel");
            toggle();
        });
   }

    const UploadBackgroundImage = (file, url) =>{
        setBackgroundImageUrl(url);
        setBackgroundImageFile(file);
    }

    const doSubmit =() => {
        UploadImageToStorage(backgroundImageFile, "sitePages/home/", "background", SuccessUpload)
    }

    const SuccessUpload = (url) => {
        docRef.update({backgroundImageUrl: url})
        setModalTitle("Image Uploaded");
        setModalMessage("Background Image Uploaded Successfully");
        toggle();
    }

    const RemoveImage = () => {
        setBackgroundImageUrl("");
        setBackgroundImageFile("");
      }

    return (
        <Container fluid={true}>
            <h1>Home Page Details</h1>
            <h2>Background Image</h2>
            {!backgroundImageUrl &&<ImageUploadAndCrop descriptionText="Choose Background Image" maxWidth="2000px" submitFunction={UploadBackgroundImage} />}
            {backgroundImageUrl && <Container fluid={true}>
                    <Row>
                        <Col><br />
                            <StyledProfileImage src={backgroundImageUrl} alt="" />
                            <StyledButton onClick={() => RemoveImage()} color="danger">
                                <FAIconButton icon={["fas", "times"]}></FAIconButton>
                            </StyledButton>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            {(backgroundImageUrl && backgroundImageFile) && <StyledButton color="primary" onClick={() => {doSubmit()}}>Save Picture</StyledButton>} 
                        </Col>
                        <StyledModal isOpen={modal}>
                            <ModalHeader>{modalTitle}</ModalHeader>
                                <ModalBody>
                                    {modalMessage}
                                </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={toggle}>Done</Button>
                            </ModalFooter>
                        </StyledModal>
                    </Row>
                </Container>}
                <br />
                {/* <h2>Primary Text</h2>
                 <ContentListUpdate returnDataCallback={UpdatePrimaryText}hasImageOnList={true} objectList={primaryText} contentName="PrimaryText" /> */}

                <br />
                <h2>Partnership Carousel</h2>
                <ContentListUpdate returnDataCallback={UpdatePartnershipCarousel} objectList={partnershipList} hasImageOnList={true} contentName="PartnershipCarousel" />
                <br />
                <h2>Study Announcements Carousel</h2>
                <ContentListUpdate hasUrlLink={true} returnDataCallback={UpdateStudyCarousel} objectList={studyAnnouncementList} hasImageOnList={true} contentName="StudyCarousel" />
        </Container>
     );
}
 
export default HomePageForm;

const StyledModal = styled(Modal)`
    position: absolute;
    left: 40%;
    top: 40%;
`

const StyledProfileImage = styled.img`
    padding: 5px;
    margin-right: 30px;
    box-shadow: 0 0.5rem 1rem #9780af;
    max-width: 250px;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;

const StyledButton = styled(Button)`
    margin-right: 15px;
`