import React, { useState } from 'react';
import { Nav, NavItem, NavLink, Container, Row } from 'reactstrap';
import styled from 'styled-components'
import HomePageForm from './pageForms/homepageform';
import GlobalPageForm from './pageForms/globalpageform';
import TeamPageForm from './pageForms/teampageform';
import ContactPageForm from './pageForms/contactpageform';
import TermsPageForm from './pageForms/termspageform';
import AdminNav from './adminComponents/adminNav';
import ManageExerciseGroupContent from './pageForms/manage-exercise-group-content';

const PageControl = () => {
    const pages = {
        global: <GlobalPageForm />,
        home: <HomePageForm />,
        team: <TeamPageForm />,
        contacts: <ContactPageForm />,
        terms: <TermsPageForm />,
        exercise: <ManageExerciseGroupContent />
    }
    
    const [currentPage, setCurrentPage] = useState(pages.global);
    const [currentKey, setCurrentKey] = useState();
    
    const setPage = (page) =>{
        setCurrentPage(page);
        Object.keys(pages).find(
            key => pages[key] === page ?  setCurrentKey(key) : "")
    }

    return ( 
        <React.Fragment>
             <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage />
                </ParentDiv>   
            </HeightDiv>
            <StyledContainer fluid={true}>
                <AdminNav />
                <Row>
                    <h1>Page Control</h1>
                    <br />
                </Row>
                <Row>
                <Nav tabs>
                    <NavItem>
                        <NavLink onClick={() => setPage(pages.global)} active={currentKey === "global" || !currentKey} href="#">Cities</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => setPage(pages.home)} active={currentKey == "home"} href="#">Home</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => setPage(pages.team)} active={currentKey === "team"} href="#">Study Info & Team/CORE</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => setPage(pages.contacts)} active={currentKey === "contacts"} href="#">Ethics/Study/Contacts</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => setPage(pages.exercise)} active={currentKey === "youtube"} href="#">Exercise Group</NavLink>
                    </NavItem>
                </Nav>
                </Row>
                <Row>
                    {currentPage}  
                </Row>
            </StyledContainer>
        </React.Fragment>
     );
}
 
export default PageControl;

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #372844;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 1700px;
    margin-top: 15px;
    margin-bottom: 100px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #9780af;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;