export const location = [
    {_id:"Toronto", name:"Toronto"},
    {_id:"Edmonton", name:"Edmonton"},
    {_id:"Halifax", name:"Halifax"},
    {_id:"Montréal", name:"Montréal"},
    {_id:"Vancouver", name:"Vancouver"},
];

//TODO abstract this out, it should be from a list they update/read from the database
export const specialization = [
    {_id:"Cardiology; Endocrinology", name:"Cardiology; Endocrinology"},
    {_id:"Medical & Radiation Oncology", name:"Medical & Radiation Oncology"},
    {_id:"Cardiac Rehabilitation", name:"Cardiac Rehabilitation"},
    {_id:"Exercise Oncology; Behavioural Science", name:"Exercise Oncology; Behavioural Science"},
    {_id:"Knowledge Translation &Implementation", name:"Knowledge Translation &Implementation"},
    {_id:"Statistics", name:"Statistics"},
    {_id:"Epidemiology", name:"Epidemiology"},
    {_id:"Digital Health", name:"Digital Health"},
    {_id:"Survivor Advisory Panel", name:"Survivor Advisory Panel"},
];