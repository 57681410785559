import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Input, Button, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import firebase from "firebase/app";
import { auth, db } from '../../services/firebase';
import { GetUserInformation, OpenTicket } from '../../services/firestore';
import styled from 'styled-components'
import blankUser from '../../images/BlankUser.jpg';
import { isBrowser,  MobileView } from 'react-device-detect';
import { TicketStrings } from '../adminPages/tickets/ticketStrings';
import { convertUTCDateToLocalDate } from '../../services/helper';
import emailjs, { init } from 'emailjs-com';

const MessageRoom = () => {

    init("user_8Qt3ZJv9dlnGhiJefkhBh");

    const [sender, setSender] = useState([]);
    const [recipientId, setRecipientId] = useState(useParams().id);
    const [newMessage, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const [recipientsname, setRecipientsName] = useState([]);
    const [recipientImageUrl, setRecipientImageUrl] = useState(blankUser);
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [issueDescription, setIssueDescription] = useState("");
    const [reportedIssue, setReportedIssue] = useState(false);

    const toggle = () => setModal(!modal);

    useEffect(() => {
        InitiateMessageRoom();  
      }, []);

    const InitiateMessageRoom = () =>{
        auth.onAuthStateChanged(() => {
            const userId = auth.currentUser.uid;
            const userRef = db.collection("users").doc(recipientId);

            userRef.get().then((doc) => {
                if (!doc.exists || userId === recipientId) {
                   window.location = "/not-found";
                } else {
                    checkIfThereIsAMessageObject(userId, recipientId);
                    checkIfThereIsAMessageObject(recipientId, userId);
                    setRecipientsName(doc.data().username);
                    if(doc.data().imageurl){
                        setRecipientImageUrl(doc.data().imageurl);
                    }
                    db.collection("users").doc(userId).collection("messages").doc(recipientId)
                    .onSnapshot((doc) => {
                        getMessages();
                    });
                }
            }).catch((error) => {
                console.log("Could not find recipient", error);
            });
          })     
     }

     const checkIfThereIsAMessageObject = (ID1, ID2) => {
        const messageRef = db.collection("users").doc(ID1).collection("messages").doc(ID2);
        //TODO look into making this a transaction
        messageRef.get().then((docSnapshot) => {
            if(!docSnapshot.exists){
                db.collection("users").doc(ID2).get().then(function(doc) {
                    if (doc.exists) {
                        createMessageObject(messageRef, doc.data());
                    } else {
                        console.log("Could not find recipient");
                    }
                }).catch(function(error) {
                    console.log("Error getting document:", error);
                });
            }
        })
     }

    const createMessageObject = (messageRef, RecipientUser) => {
        messageRef.set({
            messages: [],
            recipientname: RecipientUser.username,
            recipientid: RecipientUser.uid,
        }, db.SetOptions)
        .then(function() { 
        })
        .catch(function(error) {
            console.error("Error creating message object in DB", error);
        });
    }
   
    const getMessages = () =>{
         try {
             const userId = auth.currentUser.uid;
             GetUserInformation(userId, mapMessages);    
         }
         catch(ex){
             console.log(ex);
         }
     }

    const mapMessages = (user) => {
        var currentMessages = [];
        setSender(user);
        const messageRef = db.collection("users").doc(user.uid).collection("messages").doc(recipientId);

        messageRef.get().then((doc) => {
            if (doc.exists) {
                currentMessages = doc.data().messages; 
                setMessages(currentMessages);
                
                var chatWindow = document.getElementById("chatWindow");
                chatWindow.scrollTop = chatWindow.scrollHeight;

                var messagesRead = currentMessages;
                for(let i = 0; i < messagesRead.length; i++){
                    messagesRead[i].read = true;
                }
                db.collection("users").doc(user.uid).collection("messages").doc(recipientId).update({
                    messages: messagesRead,
                }, db.SetOptions)
                .catch(function(error) {
                    console.error("Error Updating Messages: ", error);
                });
            } else {
                console.log("Could not find message object for mapping");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
     }
 
    const doSubmit = async (event) =>{
        event.preventDefault();
        if(newMessage === ""){ return; }       
        const user = auth.currentUser;

        var message = { sender: user.uid,
            recipient: recipientId,
            contents: newMessage, 
            sendername: user.displayName,
            recipientname: recipientsname,
            timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
            read: false,
        }

        messages.push(message);
        setMessage(""); //TODO if there is an error sending the message the messages will still get updated for the person, they may think it sent when it didn't
         
        db.collection("users").doc(user.uid).collection("messages").doc(recipientId).update({
            messages: messages,
        }, db.SetOptions)
        .then(function() {})
        .catch(function(error) {
            console.error("Error sending message: ", error);
        });

        db.collection("users").doc(recipientId).collection("messages").doc(user.uid).update({
            messages: messages,
        }, db.SetOptions)
        .then(function() {})
        .catch(function(error) {
            console.error("Error sending message: ", error);
        });
    }

    const IssuePrompt = () => {
        setModalTitle("Report Issue");
        setModalMessage("Please enter you issue in the area below. Please note that your chat history with this individual will be shared with the administrators for proper investigation");
        setReportedIssue(false);
        toggle();
    }

    const ReportIssue = () =>{  
        OpenTicket(sender.username, sender.uid, sender.email, TicketStrings.message, issueDescription, false, ReportingFollowUp, messages, recipientsname)
        emailjs.send("service_3nzmm0t", "template_wq4w62a", {
            to_name: "Krishna",
            reported_name: sender.username,
            reporter_name: recipientsname,
            message: TicketStrings.message,
        });
        //EmailJS: template_wq4w62a
    }

    const ReportingFollowUp = (success) => {
        setReportedIssue(true);
        if(success){
            setModalTitle("Report Sent");
            setModalMessage("Your issue has been reported, an administrator should follow up with you shortly");
        }
        else{
            setModalTitle("Something went wrong");
            setModalMessage("Unable to send in ticket please follow up with an administrator at HIMALAYAStrial@gmail.com");
        }
    }
  
    return (
        <React.Fragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage />
                </ParentDiv>   
            </HeightDiv>
            <StyledContainer browser={isBrowser}>
                <Row>
                    {isBrowser && 
                        <Col lg="3">
                            <Button color="primary" href="/messages">Back</Button>
                            <h4><br />{recipientsname}</h4>
                            <ProfileImage src={recipientImageUrl} alt="Recipients Image" />
                            <br />
                        </Col>}
                    {!isBrowser &&<MobileView>
                        <Container>
                            <Row>
                                <Col>
                                    <MobileImage src={recipientImageUrl} alt="Recipients Image" />
                                </Col>
                                <Col><MobileUsername><br />{recipientsname}</MobileUsername></Col>
                            </Row>
                        </Container>
                    </MobileView>}
                    <Col>
                        <MessageWindow browser={isBrowser} id="chatWindow">
                        {messages && messages.map((message, i) => {
                            var time = new Date(1970, 0, 1);
                            time.setSeconds(message.timestamp.seconds);
                            time = convertUTCDateToLocalDate(time);

                            return(<React.Fragment key={i}><br />
                               {message.sender === recipientId && <RecipientsMessageStyle key={i}>
                                        <StyledText>
                                            {message.contents}
                                        </StyledText>
                                        <LeftTimeBox>
                                            {time.toLocaleString()}
                                        </LeftTimeBox>
                                    </RecipientsMessageStyle>} 
                                    {message.sender !== recipientId && <SendersMessageStyle key={i}>
                                        <StyledText>
                                            {message.contents}
                                        </StyledText>
                                        <RightTimeBox>
                                            {time.toLocaleString()}
                                        </RightTimeBox>
                                    </SendersMessageStyle>}
                                </React.Fragment>)
                            })} 
                        </MessageWindow>
                    </Col>
                </Row>
                <Form onSubmit={doSubmit}>
                <br />
                    <Row>
                        <Col xs="9">
                            <StyledInput maxlength="280" name="message" id="message" value={newMessage} onChange={e => setMessage(e.target.value)}/>
                        </Col>
                        <Col xs="2">
                            <Button type="button" color="primary" onClick={doSubmit}>Send</Button>
                        </Col>
                        <Col xs="1">
                            <Button type="button" color="danger" onClick={IssuePrompt}>Report</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <StyledModal isOpen={modal}>
                                <ModalHeader>{modalTitle}</ModalHeader>
                                <ModalBody>
                                    {modalMessage}
                                    {!reportedIssue && <hr />}
                                    {!reportedIssue && <StyledInput type="textarea" name="issuedescription" id="issuedescription" onChange={e => setIssueDescription(e.target.value)}/>}
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={toggle}>Close</Button>
                                    {!reportedIssue && <Button color="primary" onClick={ReportIssue}>Report</Button>} 
                                </ModalFooter>
                            </StyledModal>
                        </Col>
                    </Row>
                <br />                    
                </Form>
            </StyledContainer>
        </React.Fragment>
    );
}
 
export default MessageRoom;

const StyledModal = styled(Modal)`
    position: absolute;
    left: 40%;
    top: 40%;
`

const MobileImage = styled.img`
    width: 50px;
    border-radius: 30px;
    padding-top: 5px;
`

const MobileUsername = styled.h4`
    margin-bottom: 10px;
`

const StyledText = styled.p`
    color: white;
    padding: 3px;
    align-items: center;
    font-family: Segoe UI;
    font-size: 20px;
`

const SendersMessageStyle = styled(Container)`
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: 0 0.2rem .5rem #372844;
    background-color: #9780af;
    width: fit-content;
    max-width: 70%;
    margin-right: 5px;
`

const RecipientsMessageStyle = styled(Container)`
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: 0 0.2rem .5rem black;
    background-color: #808080;
    width: fit-content;
    max-width: 70%;
    margin-left: 5px;
`

const ProfileImage = styled.img`
    width: 220px;
    justify-content: center;
    border-radius: 15px;
    box-shadow: 0 0.5rem 1rem #372844;
`

const MessageWindow = styled.div`
    overflow: scroll;
    overflow-x: hidden;
    height: ${props => props.browser ?  "550px" : "460px"};
    width: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    padding-bottom: 20px;
`

const RightTimeBox = styled.span`
    color: #999;
    font-size: 15px;
    color: white;
`

const LeftTimeBox = styled.span`
    color: white;
    font-size: 15px;
    margin-left: 00px;
`

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #372844;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: ${props => props.browser ? "30px" : "0px"};
    max-width: 1200px;
    margin-top: 5px;
    margin-bottom: 90px;
`

const StyledInput = styled(Input)`
margin-left: 10px;
border-color: black;
&:focus{
    border-color: #372844;
    box-shadow: 0 0.5rem 1rem #9780af;
}
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #dddddd;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;