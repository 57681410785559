import React from 'react';
import { Input, FormGroup, Label } from 'reactstrap';
//TODO I need to add a way to check the boxes off from an outside array coming in
const CheckBox = ({name, label, options, useranswers, error, ...rest}) => {
    return ( 
        <FormGroup id={name}>
            {options.map((option, i) => {

                    var match = false;

                    match = useranswers.find(element => {
                        if (element.includes(option.name)) {
                          return true;
                        }
                      });

                      if(match === undefined){
                          match = false;
                      }
                return(
                    <div key={option._id}>
                        <Input type="checkbox" checked={match} value={option.name} id={option.name} name={name} {...rest}/>
                        <Label className="form-check-label" htmlFor={option.name}>
                            &nbsp;&nbsp;{option.name}
                        </Label>
                    </div>)
            })}

        </FormGroup>
     );
}
 
export default CheckBox;