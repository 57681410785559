import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import AdminStudyUpdateDocument from './admin-study-update-document';

const AdminStudyUpdateDocuments = () => {

    const monthlies = [
        { 
            name: 'Month 1',
            file: 'Month-1.pdf',
        },
        {
            name: 'Month 2',
            file: 'Month-2.pdf',
        },
        {
            name: 'Month 3',
            file: 'Month-3.pdf',
        },
        {
            name: 'Month 4',
            file: 'Month-4.pdf',
        },
        {
            name: 'Month 5',
            file: 'Month-5.pdf',
        },
        {
            name: 'Month 6',
            file: 'Month-6.pdf',
        },
        {
            name: 'Month 7',
            file: 'Month-7.pdf',
        },
        {
            name: 'Month 8',
            file: 'Month-8.pdf',
        },
        {
            name: 'Month 9',
            file: 'Month-9.pdf',
        },
        {
            name: 'Month 10',
            file: 'Month-10.pdf',
        },
        {
            name: 'Month 11',
            file: 'Month-11.pdf',
        },
        {
            name: 'Month 12',
            file: 'Month-12.pdf',
        },
    ];

    const specifics = [
        {
            name: 'Exercise Benefits',
            file: 'Exercise-Benefits.pdf',
        },
        {
            name: 'CVD',
            file: 'CVD.pdf',
        },
        {
            name: 'Exercise Prescription',
            file: 'Exercise-Prescription.pdf',
        },
        {
            name: 'Getting and Staying Active',
            file: 'Getting-and-Staying-Active.pdf',
        },
        {
            name: 'Decision Balance',
            file: 'Decision-Balance.pdf',
        },
        {
            name: 'Activity Environment',
            file: 'Activity-Environment.pdf',
        },
        {
            name: 'Fun with Physical Activity',
            file: 'Fun-with-Physical-Activity.pdf',
        },
        {
            name: 'Goal Setting',
            file: 'Goal-Setting.pdf',
        },
        {
            name: 'Self Monitoring',
            file: 'Self-Monitoring.pdf',
        },
        {
            name: 'Social Support',
            file: 'Social-Support.pdf',
        },
        {
            name: 'Motivation Regulation',
            file: 'Motivation-Regulation.pdf',
        },
        {
            name: 'Self Talk',
            file: 'Self-Talk.pdf',
        },
        {
            name: 'Stimulus Control',
            file: 'Stimulus-Control.pdf',
        },
        {
            name: 'Time Management',
            file: 'Time-Management.pdf',
        },
    ];

    return ( 
        <Container>
            <Row>
                <h2>Study Documents</h2>
            </Row>
            <Row>
                <Col>
                    <p>These should all be .pdf files</p>
                </Col>
            </Row>
            <Row>
                <h3>Monthly Documents</h3>
                {monthlies.map((monthly) => (
                    <AdminStudyUpdateDocument folderPath='docs/monthly-behavioural-support-modules/' title={monthly.name} fileName={monthly.file} />
                ))}
            </Row>
            <br />
            <Row>
                <h3>Specific Documents</h3>
                {specifics.map((specific) => (
                    <AdminStudyUpdateDocument folderPath='docs/topic-specific-modules/' title={specific.name} fileName={specific.file} />
                ))}
            </Row>
        </Container>
    );
}
 
export default AdminStudyUpdateDocuments;
