import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { db } from '../../services/firebase';
import TeamMemberList from '../common/teamMemberList';
import styled, { keyframes } from 'styled-components'
import * as teamNames from '../../libraries/teammemberinfo'
import BlankUser from "../../images/BlankUser.jpg";
import { isBrowser } from 'react-device-detect';

const StudyInfoAndTeam = () => {
    const [teams, setTeams] = useState([]);
    const [currentTeam, setCurrentTeam] = useState([]);
    const [primaryText, setPrimaryText] = useState([]);
    const [coreText, setCoreText] = useState([]);

    const docRef = db.collection("webPages").doc("team");

    const coreRef = db.collection("webPages").doc("Core")

    useEffect(() => {
        ReturnTeamPageInformation();
        ReturnCorePageInformation();
        ShowAllTeamMembers();
      }, []);

      const ReturnCorePageInformation = () =>{
        coreRef.onSnapshot((doc) => {
            setCoreText(doc.data().primaryText);
        });
    }

      const ReturnTeamPageInformation = () =>{
        docRef.onSnapshot((doc) => {
            setPrimaryText(doc.data().primaryText);
        });
    }

    const ShowAllTeamMembers =() =>{
        var tempmembers = [];
        db.collection("teamMembers").doc("TeamList").get().then(function(doc) {
            if (doc.exists) {
                var teamList = doc.data();
                for (const teamMember in teamList) {
                    var tempmember = {
                        name: teamList[teamMember].name,
                        location: teamList[teamMember].location,
                        specialization: teamList[teamMember].specialization,
                        imageUrl: teamList[teamMember].imageUrl,
                    }
                    tempmembers.push(tempmember);
                }

                OrganizeMembers(tempmembers);
            } else {
                console.log("Unable to find TeamList");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }



    const OrganizeMembers = (members) => {
        const totalmembers = members;
        var teams = []
        for(var i = 0; i < teamNames.location.length; i++){
            var teamobj = {
                name: teamNames.location[i].name,
                members: [],
                selected: false
            }
            teams.push(teamobj);
        }
       
        teams.sort(function(a, b) {
            var textA = a.name.toUpperCase();
            var textB = b.name.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

        for(var i = 0; i < totalmembers.length; i++){
            for(var x = 0; x < teams.length; x++){
                if(totalmembers[i].location === teams[x].name){
                    teams[x].members.push(totalmembers[i]);
                }
            }
        }

        for(var i = 0; i < teams.length; i++){
            teams[i].members.sort(function(a, b) {
                var textA = a.name.toUpperCase();
                var textB = b.name.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
        }

        setTeams(teams);
    }

    const SetNewTeam = (newMembers, teamName) => {
        setCurrentTeam(newMembers);

        for(var i = 0; i < teams.length; i++){
            if(teams[i].name === teamName){
                teams[i].selected = true;
            }
            else{
                teams[i].selected = false;
            }
        }
        setTeams(teams);
    }

    return (
        <React.Fragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage />
                </ParentDiv>   
            </HeightDiv>
            <Container fluid={true}>
                {/* TODO this is really bad implementation, but at the moment I need the bottom article to be displaced elsewhere */}
                <Row>
                    <TopCol lg="6" xs="12">
                    {primaryText.length > 0 && <StyledContainer fluid={true}>
                        <h1>About Us</h1>
                        <hr />
                        <Row>
                            <Col>
                                <h3>{primaryText[0].title}</h3>
                                <BodyText>{primaryText[0].content}</BodyText>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col>
                                <h3>{primaryText[1].title}</h3>
                                <BodyText>{primaryText[1].content}</BodyText>
                            </Col>
                        </Row>
                        </StyledContainer>}
                    </TopCol>
                    <Col>
                        <TopContainer fluid={true}>
                            {coreText.length > 0 && <Row>
                                <MarginCol xs="12">
                                    <InformationBox delay={0}>
                                        <h2>{coreText[0].title}</h2>
                                        <BodyText>
                                            {coreText[0].content}
                                        </BodyText>
                                    </InformationBox>
                                </MarginCol>
                                <MarginCol xs="12">
                                    <InformationBox delay={.25}>
                                    <h2>{coreText[1].title}</h2>
                                        <BodyText>
                                            {coreText[1].content}
                                        </BodyText>
                                    </InformationBox>
                                </MarginCol>
                                <MarginCol xs="12">
                                    <InformationBox delay={.5}>
                                    <h2>{coreText[2].title}</h2>
                                        <BodyText>
                                            {coreText[2].content}
                                        </BodyText>
                                    </InformationBox>
                                </MarginCol>
                            </Row>}
                        </TopContainer>
                    </Col>
                </Row>
            </Container>
            <PaddingDiv>
            <StyledContainer fluid={true}>
                {primaryText.length > 0 && <Row>
                    <Col>
                        <h2>{primaryText[2].title}</h2>
                        <BodyText>{primaryText[2].content}</BodyText>
                    </Col>
                </Row>}
                <ButtonSection>
                    {teams.map((value, index) => {
                        return <TeamMemberList key={index} members={value.members} teamname={value.name} showMembersFunction={SetNewTeam} selected={value.selected} />
                    })}
                </ButtonSection>
                <hr />
                <Row>
                {currentTeam.map((value, index) => {
                    return <Col key={index} lg ="6">
                        <Container>
                            <Row>
                                <Col lg = "3" md ="4" sm="5">
                                    {value.imageUrl && <StyledProfileImage src={value.imageUrl} alt={value.name} />}
                                    {!value.imageUrl && <StyledProfileImage src={BlankUser} alt={value.name} />}
                                </Col>
                                <Col>
                                    <h5>{value.name}</h5>
                                    <StyledParagraph>{value.specialization}</StyledParagraph>
                                    
                                 </Col>
                            </Row>
                            <hr />
                        </Container>
                    </Col>          
                })}
                </Row>
            </StyledContainer>
            </PaddingDiv>
        </React.Fragment>
    );
}

const ButtonSection = styled(Row)`
    margin: auto;
`

const PaddingDiv = styled.div`
    padding-left: 15px;
    padding-right: 15px;
`

const TopCol = styled(Col)`
    padding-left: 15px;
    padding-right: 15px;
`

const StyledParagraph = styled.p`
    font-size: large;
`

const TopContainer = styled(Container)`
    margin-top: 15px;
    padding-left: 0px;
    padding-right: 0px;
`

const StyledProfileImage = styled.img`
    height: 80px;
    border-radius: 50px;
`

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #372844;
    border-radius: 10px;
    border: solid;
    background-color: white;
    padding: 20px;
    margin-top: 15px;
`

const StyledBackgroundImage = styled.div`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #9780af;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;


const MarginCol = styled(Col)`
    margin-bottom: 50px;
    padding-left: 15px;
    padding-right: 15px;
`

const BodyText = styled.p`
    font-size: large;
`

const fadeInAnimation = keyframes`
    from {
        opacity: 0;
    }
    to {
       
        opacity: 1;
    }
`;

const InformationBox = styled.div`
    background-color: white;
    border: solid;
    min-height: 100%;
    justify-content: center;
    padding: 10px;
    animation: ${fadeInAnimation} 0.5s ${props => props.delay + "s"} both;
    box-shadow: 0 0.5rem 1rem #372844;
    border-radius: 10px;
    padding: 15px;
`
 
export default StudyInfoAndTeam;