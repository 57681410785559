import React, { useState } from 'react';
import AWS from 'aws-sdk';


const FileUploadButton = (props) => {
  const [file, setFile] = useState(null);

  const s3Client = new AWS.S3({
    forcePathStyle: false,
    endpoint: "https://nyc3.digitaloceanspaces.com",
    region: "us-east-1",
    credentials: {
        accessKeyId: process.env.REACT_APP_DO_SPACES_KEY,
        secretAccessKey: process.env.REACT_APP_DO_SPACES_SECRET,
    },
  });

  

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const uploadFile = () => {
    if (!file) return;

    const params = {
      Bucket: process.env.REACT_APP_DO_SPACES_ID,
      Key: (props.folderPath + props.fileName),
      Body: file,
      ACL: 'public-read', // or another ACL as per your requirements
    };

    s3Client.upload(params, function(err, data) {
      if (err) {
        props.updateMessage('Error uploading file:' + err);
      } else {
        props.updateMessage('File uploaded successfully');
      }
    });
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <button onClick={uploadFile}>
        Upload
      </button>
    </div>
  );
};

export default FileUploadButton;
