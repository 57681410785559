import React from 'react';
import styled from 'styled-components';
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";

const HomeCarousel = (props) => {
    const responsive = {
        superLargeDesktop: {

          breakpoint: { max: 4000, min: 1400 },
          items: 3
        },
        desktop: {
          breakpoint: { max: 1400, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 800, min: 0 },
          items: 1
        }
      };

   return (<React.Fragment>
        <Carousel 
            responsive={responsive} 
            infinite={true}
            swipeable={true}
            draggable={true}
            showDots={true}
            autoPlaySpeed={2000}
            autoPlay={true}
            arrows={false}
            pauseOnHover={false}
        >
            {props.items.map((item, i) => (
                <CarouselItem key={i}>
                    <ItemImage src={item.imageUrl} alt={item.title + " " + item.content} />
                </CarouselItem>
            ))}
        </Carousel>
   </React.Fragment>)
}
export default HomeCarousel;

const CarouselItem = styled.div`
padding-left: 10px;
padding-right: 10px;
margin-bottom: 50px; 
`

const ItemImage = styled.img`
    width: 100%;
    height: auto;
`