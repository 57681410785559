import React, {useEffect} from "react";
import { Route, Redirect } from "react-router-dom";
import { PageView } from "../../../utils/analytics";

const AdminRoute = ({ path, title, user, component: Component, render, ...rest }) => {

  useEffect(() => {
    document.title = title;
    PageView(title, path);
  },[]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user !== undefined){
          console.log(user);
          if((user.isAdmin || user.isRegionalAdmin || user.isStudyAdmin) && !user.isArchived){
            return Component ? <Component user={user} {...props} /> : render(props);
          }
          else if(user.isArchived){
            return(<Redirect
              to={{ pathname: "/archived-account", state: { from: props.location } }}
            />)
          }
          else{
            return (
              <Redirect
                to={{ pathname: "/not-found", state: { from: props.location } }}
              />
            );
          }
        }
        else{
          return (
            <Redirect
            to={{ pathname: "/not-found", state: { from: props.location } }}
          />
          )}      
      }}
    />
  );
};

export default AdminRoute;