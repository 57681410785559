import React, { useState } from 'react';
import { Row, Col, Button, Input } from 'reactstrap';
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { groupnames } from '../../../libraries/filters';
import { updateDocumentProperty } from '../../../services/firestore';
import { db } from '../../../services/firebase';

const AdminUserProfileRow = ({user, acceptFunction, deleteFunction}) => {
    const [group, setGroup] = useState(user.group);
    const [userName, setUserName] = useState(user.username);
    const [region, setRegion] = useState(user.region);
    const regions = [
        'Undefined',
        'Toronto',
        'Vancouver',
        'Montreal',
        'Calgary',
        'Edmonton'
    ]

    const ChangeUserName = (value) => {
        setUserName(value);
    }

    const UpdateName = () => {
        updateDocumentProperty(db, "users", user.uid, "username", userName);
    }

    const ResetName = () => {
        setUserName(user.username);
    }

    const UpdateRegion = (target) => {
        setRegion(target.value);
        updateDocumentProperty(db, "users", user.uid, "region", target.value);
    }

    const UpdateStaffField = (value) => {
        updateDocumentProperty(db, "users", user.uid, "isStaff", value);
    }

    return ( 
        <StyledRow key={user.id}>
            <Col xs="4">
                <p>
                    <b>Username: </b>
                    <Input onChange={(event) => ChangeUserName(event.target.value)} value={userName}></Input>
                    {userName !== undefined && userName !== user.username && <StyledButton onClick={() => UpdateName()} color="primary">
                            <FAIconButton  icon={["fas", "check"]}></FAIconButton>
                    </StyledButton>}
                    {userName !== undefined && userName !== user.username && <StyledButton onClick={() => ResetName()} color="primary">
                            <FAIconButton  icon={["fas", "ban"]}></FAIconButton>
                    </StyledButton>}
                </p>
                <p><b>Email: </b>{user.email}</p>
                <p><b>ID:</b> {user.uid}</p>
            </Col>
            <Col xs="3">
                <div>
                    <b>Status: </b>
                    <p>
                        {[
                            user.isStaff && 'Staff',
                            user.isSiteAdmin && 'Site Admin',
                            user.isRegionalAdmin && 'Regional Admin',
                            user.isStudyAdmin && 'Study Admin',
                            user.isArchived && 'Archived',
                        ].filter(Boolean).join(', ')}
                    </p>
                </div>
                <Button onClick={() => UpdateStaffField(true)}>Set Staff to True</Button>
                <Button onClick={() => UpdateStaffField(false)}>Set Staff to False</Button>
                {false && (user.isAccountAccepted) && <div><b>Group: </b><p>{user.group}</p></div>}
                {(user.isAccountAccepted) && <div><b>Region: </b></div>}
                <StyledInput value={region} type="select" name="region" id="region" onChange={(e) => UpdateRegion(e.target)}>
                    {regions.map((region) => (
                        <option  key={region} value={region}>
                            {region}
                        </option>
                    ))}
                </StyledInput>
                {(!user.isAccountAccepted && !user.isArchived) && <StyledInput type="select" name="group" id="group" value={group} onChange={e => setGroup(e.target.value)}>
                    {groupnames.map((option) => (
                        <option  key={option._id} value={option._id}>
                            {option.name}
                        </option>
                    ))}
                </StyledInput>}
            </Col>

            <Col xs="3"><p><b>Modules Completed:</b> {user.completedModules}</p></Col>
            <Col >
                <div>
                    {(!user.isAccountAccepted || user.isArchived) && <StyledButton onClick={() => acceptFunction(user, group)} color="primary">
                        <FAIconButton  icon={["fas", "check"]}></FAIconButton>
                    </StyledButton>}
                    <StyledButton href={user.profileurl} color="warning">
                        <FAIconButton  icon={["fas", "eye"]}></FAIconButton>
                    </StyledButton>
                    {!user.isArchived && <StyledButton onClick={() => deleteFunction(user.uid)} color="danger">
                        Archive
                    </StyledButton>}
                    <br />

                </div>
            </Col>
            <hr />
        </StyledRow> );
}

export default AdminUserProfileRow;

const StyledRow = styled(Row)`
    height: auto;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;

const StyledButton = styled(Button)`
    margin-bottom: 3px;
    margin-left: 5px;
    max-height: 36px;
    border-style: solid;
    border-width: 1.5px;
    border-color: black;
`;

const StyledInput = styled(Input)`
&:focus{
    border-color: #372844;
    box-shadow: 0 0.5rem 1rem #9780af;
}

appearance: menulist;
`