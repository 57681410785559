import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Button, Label, Input } from 'reactstrap';
import { db } from '../../services/firebase';
import { DeleteTeamMember, GetTeamMemberInformation, UploadImageToStorage } from "../../services/firestore.js";
import * as options from '../../libraries/teammemberinfo'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from 'styled-components'
import ImageUploadAndCrop from '../common/formComponents/imageUploadAndCrop.jsx';
import { v4 as uuidv4 } from 'uuid';

const EditTeamProfile = () => {
    const [name, setName] = useState("");
    const [specialization, setSpecialization] = useState("");
    const [location, setLocation] = useState(options.location[0]._id);
    const [params] = useState(useParams())
    const [profileImage, setProfileImage] = useState("");
    const [profileImageFile, setProfileImageFile] = useState();
    const [crop] = useState({unit: '%',
    width: 30,
    aspect: 1/1,});

    useEffect(() => {
        if(params.id === "new") {return;}
        GetTeamMemberInformation(params.id, populateProfile) 
      }, []);


    const populateProfile = (user) =>{
        setName(user.name);
        setSpecialization(user.specialization);
        setLocation(user.location);
        setProfileImage(user.imageUrl);
     }

    const doSubmit = () => {
        const data = {
            name: name,
            specialization: specialization,
            location: location,
            imageUrl: profileImage,
        }
        if(params.id === "new"){
            var teamList;
            
            db.collection("teamMembers").doc("TeamList").get().then(function(doc) {
                if (doc.exists) {
                    teamList = doc.data();
                    var newId = uuidv4();
                    teamList[newId] = {name: data.name, specialization: data.specialization, location: data.location, imageUrl: ""};
                    db.collection("teamMembers").doc("TeamList").update(teamList)
                    .then(function(){
                        if(profileImage){
                            uploadPhoto(newId);
                        }
                        else{
                            window.location = "/team-list";
                        }
                    }  
                )} else {
                    console.log("Unable to find TeamList");
                }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
            return;
        }
        else{
            db.collection("teamMembers").doc("TeamList").get().then((doc) =>{
                var teamList = doc.data();
                if(teamList[params.id]){     
                    teamList[params.id] = {name: data.name, specialization: data.specialization, location: data.location, imageUrl: data.imageUrl}; 
                    db.collection("teamMembers").doc("TeamList").update(teamList)
                    .then(function(){
                        if(profileImageFile){
                            uploadPhoto(params.id);
                        }
                        else{
                            window.location = "/team-list";
                        }  
                    })
                }
            })
        }
    };

    const uploadPhoto = (id) => {
        var pathString = "teamImages/"
        var child = id;
        UploadImageToStorage(profileImageFile, pathString, child, SuccessUpload, FailedUpload, id);
    };

    const SuccessUpload = (url, id) =>{
        var data = {imageUrl: url}
        const docRef = db.collection("teamMembers").doc("TeamList");
        UpdateTeamProfile(docRef, data, id);
    }

    const FailedUpload = (error) =>{
        console.log("Something failed to upload!");
    }

    const UpdateTeamProfile = (docRef, data, id) => {
        docRef.get().then((doc) =>{
            if(doc.exists){
                var teamList = doc.data();
                teamList[id]["imageUrl"] = data.imageUrl;
                db.collection("teamMembers").doc("TeamList").update(teamList)
                .then(function(){
                    window.location = "/team-list"; 
                })
            }
        })
    }

    const RetrieveImage = (image, url) => {
        setProfileImageFile(image);
        setProfileImage(url);
    }

    const RemoveImage = () => {
        setProfileImageFile("");
        setProfileImage("");
    }

    const GoBack = () => {
        window.location = "/team-list"
    }

    return (
        <React.Fragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage />
                </ParentDiv>   
            </HeightDiv>
            <StyledContainer>
                <Row>
                    <Col>
                        <h1>{name}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label>Team Member Name:</Label>
                        <StyledInput name="name" id="name" value={name} placeholder="name..." onChange={e => setName(e.target.value)} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Label>Specialization:</Label>
                        <StyledInput name="specialization" id="specialization" value={specialization} placeholder="Specialization..." onChange={e => setSpecialization(e.target.value)} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                    <Label>Location:</Label>
                        <StyledInput type="select" value={location} name="location" id="location" onChange={e => setLocation(e.target.value)}>
                            {options.location.map((location) => (
                                <option  key={location._id} value={location._id}>
                                    {location.name}
                                </option>
                            ))}
                        </StyledInput>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        {!profileImage &&<ImageUploadAndCrop maxWidth="2000px" submitFunction={RetrieveImage} crop={crop} descriptionText="Upload Member Profile Image" />}
                        {profileImage && <Container>
                            <Col><br />
                                <StyledProfileImage src={profileImage} alt="" />
                                <StyledButton onClick={() => RemoveImage()} color="danger">
                                    <FAIconButton icon={["fas", "times"]}></FAIconButton>
                                </StyledButton>
                            </Col>
                        </Container>}
                    </Col>
                </Row>
                <br />
                <StyledButton color="secondary" href="/team-list">Back</StyledButton>
                <StyledButton color="primary" onClick={() => {doSubmit()}}>Save Team Member</StyledButton>
                {(params.id !== "new") && <StyledButton onClick={() => DeleteTeamMember(params.id, GoBack)} color="danger">
                    <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                </StyledButton>} 
            </StyledContainer>
        </React.Fragment>
    );
}
 
export default EditTeamProfile;

const StyledProfileImage = styled.img`
    padding: 5px;
    margin-right: 30px;
    box-shadow: 0 0.5rem 1rem #9780af;
    max-width: 250px;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
`;

const StyledButton = styled(Button)`
    margin-right: 15px;
`

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #372844;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 1000px;
    margin-top: 15px;
    margin-bottom: 60px;
`

const StyledBackgroundImage = styled.div`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #9780af;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

const StyledInput = styled(Input)`
&:focus{
    border-color: #372844;
    box-shadow: 0 0.5rem 1rem #9780af;
}
`