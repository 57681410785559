import React, { useState } from 'react';
import { Col, Input, Row, Button, Container, FormGroup, Label } from 'reactstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PopUpText from '../../common/popUpText';

const ProfileCheck = ({user, propertyName, label, value, name, options, editFunction, limit = 15, tooltip = false, tooltipText}) => {
    const [hovered, setHovered] = useState(false);
    const [editing, setEditing] = useState(false);
    const [newValue, setNewValue] = useState(value);
    const [testNumber, setNumber] = useState(0);

    const acceptFunction = (newValue) => {
        //TODO Property validation
        user[propertyName] = newValue;
        editFunction(user);
        setEditing(false);
    }

    const OpenEdit = () => {
        if(!value){
            setNewValue([]);
        }
        else{
            setNewValue(value);
        }
        
        setEditing(true);
    }

    const CancelEdit = () =>{
        setNewValue(value);
        setEditing(false);
    }

    const HandleCheckbox = (incomingValue) =>{
        var newProperties = newValue;

        var match = false;

        match = newProperties.find(element => {
            if (element.includes(incomingValue)) {
              return true;
            }
          });

          if(match === undefined){
              match = false;
          }

        if(!match){
            if(newProperties.length < limit){
                newProperties.push(incomingValue);
            }
        }
        else{
            for(let i = 0; i < newProperties.length; i++){
                if(newProperties[i] === incomingValue){
                    newProperties.splice(i, 1);
                }
            }
        }
        setNewValue(newProperties);
        setNumber(testNumber + 1);
     }
    
    return (<StyledContainer onMouseEnter={() => setHovered(true)} onMouseLeave={()=> setHovered(false)}>
        <Row>
        <Col xs="8">
            <h4>{label}{tooltip && <PopUpText text={tooltipText} />}</h4>
        </Col>
        {(!editing) && <FlexCol >
            <StyledButton onClick={() => OpenEdit()} color="primary">
                <FAIconButton  icon={["fas", "edit"]}></FAIconButton>
            </StyledButton>
            </FlexCol>}
        {editing && <FlexCol>
            <StyledButton onClick={() => CancelEdit(false)} color="danger">
                <FAIconButton icon={["fas", "times"]}></FAIconButton>
            </StyledButton>
            <StyledButton onClick={() => acceptFunction(newValue)} color="primary">
                <FAIconButton  icon={["fas", "save"]}></FAIconButton>
            </StyledButton>
        </FlexCol>
        }
        </Row>
        <Row>
            {editing && <FormGroup id={name}>
            {options.map((option, i) => {
                return(
                    <div key={option._id}>
                        <Input type="checkbox" checked={newValue.includes(option.name)} value={option.name} id={option.name} name={name} onChange={e => HandleCheckbox(e.target.value, e.target.checked)} />
                        <Label className="form-check-label" htmlFor={option.name}>
                            &nbsp;&nbsp;{option.name}
                        </Label>
                    </div>)
            })}
        </FormGroup> }
            {(!editing && value && value.length > 0) && <div><ul>{value.map((unit, i) => {
                        return  <li key={i}>{unit}</li>
                })}</ul></div>}
        </Row>
        <br />

    </StyledContainer>)
}

export default ProfileCheck;

const StyledContainer = styled(Container)`
    padding-left: 0px;
    padding-right: 0px;
`

const FlexCol = styled(Col)`
    

`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 0px;
    margin-left: 0px;
`;

const StyledButton = styled(Button)`
    max-width: 40px;
    max-height: 35px;
    margin-left: 5px;
    margin-right:5px;
`;