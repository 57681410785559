import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CollapseSection = (props) => {
    const [open, setOpen] = useState(false);

   return (<React.Fragment>
        <StyledAnchor href onClick={() => setOpen(!open)}>
            <h2>
                <FAIconButton icon={open ? ["fas", "minus"] : ["fas", "plus"]} />
                {props.question}
            </h2>
        </StyledAnchor>
        <Collapse isOpen={open}>
            {props.answer}
        </Collapse>
   </React.Fragment>);
}   

export default CollapseSection;

const StyledAnchor = styled.a`
color: black;
text-decoration: none;

&:hover {
    cursor: pointer;
    color: #9780af;
}
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 8px;
    margin-left: 8px;
    color: #9780af;
`;