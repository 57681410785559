import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, ButtonGroup } from 'reactstrap';
import { auth, db } from '../../services/firebase';
import styled from 'styled-components';
import { GetUserInformation } from '../../services/firestore';
import { convertUTCDateToLocalDate } from '../../services/helper';

const Messages = () => {
    const [filteredMessagelist, setFilteredMessageList] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    var fullMessagelist = [];

    useEffect(() => {
        initiateMessageRoom();
      }, []);

    const initiateMessageRoom = () => {
        auth.onAuthStateChanged(() => {
            getMessageList(auth.currentUser);
        })
    }
    
    const getMessageList = (currentuser) => {
        var tempMessages = [];
        fullMessagelist = [];
        db.collection("users").doc(currentuser.uid).collection("messages").get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                const messages = doc.data();
                const conversation = messages.messages;
                if(conversation && conversation.length > 0){
                    var conversationRow = {
                        recipientname: messages.recipientname,
                        recipientid: messages.recipientid,
                        latestmessage: conversation[conversation.length - 1].contents,
                        latestmessagetimestamp: conversation[conversation.length - 1].timestamp,
                    }
                    var trimmedString = shorten(conversationRow.latestmessage, 50);
                    
                    if(conversationRow.latestmessage.length > 50){
                        trimmedString += "...";
                    }
                    
                    conversationRow.latestmessage = trimmedString;
                    tempMessages.push(conversationRow);
                }
            });
            tempMessages = insertionSort(tempMessages);
            fullMessagelist = tempMessages;
            RemoveArchivedUsers(tempMessages);
        })     
     }

     function shorten(text,max) {
        return text && text.length > max ? text.slice(0,max).split(' ').slice(0, -1).join(' ') : text
    }

     const RemoveArchivedUsers = (messageList) => {
         for(let i = 0; i < messageList.length; i++){
             GetUserInformation(messageList[i].recipientid, CheckUser);
         }
     }

     const CheckUser = (user) =>{
        var newUserInfoList = userInfo;
        newUserInfoList.push(user);
        setUserInfo(newUserInfoList);
        
        for(let i = 0; i < fullMessagelist.length; i++){
            if(fullMessagelist[i].recipientid === user.uid){
                var updatedList = filteredMessagelist;
                updatedList.push(fullMessagelist[i])
                setFilteredMessageList([...updatedList]);
            }
        }
     }

    const insertionSort = (inputArr) => {
        let n = inputArr.length;
            for (let i = 1; i < n; i++) {
                let current = inputArr[i];
                let j = i-1; 
                while ((j > -1) && (current.latestmessagetimestamp.seconds > inputArr[j].latestmessagetimestamp.seconds)) {
                    inputArr[j+1] = inputArr[j];
                    j--;
                }
                inputArr[j+1] = current;
            }
        return inputArr;
    }

    return (
        <React.Fragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage />
                </ParentDiv>   
            </HeightDiv>
            <StyledContainer>
                <Row>
                    <h2>Your Messages</h2>
                    <br />
                </Row>
                <ButtonGroupRow vertical={true}>
                        {filteredMessagelist && filteredMessagelist.map((conversation, i) => {
                            var time = new Date(1970, 0, 1);
                            time.setSeconds(conversation.latestmessagetimestamp.seconds);
                            time = convertUTCDateToLocalDate(time);
                            return(
                                <ButtonRow key={i} href={"/messageRoom/"+ conversation.recipientid}>
                                    <Row>
                                        <Col lg="7"><h5>{conversation.recipientname}</h5></Col>
                                        <Col lg="5">{time.toLocaleString()}</Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <StyledP>{conversation.latestmessage}</StyledP>
                                        </Col>
                                    </Row>
                            </ButtonRow>)})}
                    {filteredMessagelist.length === 0 && <p>You have no messages</p>}
                </ButtonGroupRow>
            </StyledContainer>
        </React.Fragment>
    );
}

export default Messages;

const StyledP = styled.p`
    text-align: left;
    font-size: large;
`

const ButtonRow = styled(Button)`
    background-color: white;
    color: black;
    border-radius: 15px;
    margin-bottom: 15px;
`

const ButtonGroupRow = styled(ButtonGroup)`
    display: inline;
`

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #372844;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    margin-top: 15px;
    margin-bottom: 90px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #dddddd;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;
 