import React from "react";
import { Input, FormGroup, Label } from 'reactstrap';
import styled from 'styled-components';

const Select = ({ name, label, options, description, error, ...rest }) => {
  return (
    <FormGroup>
      {label && <Label for={name}>{label}</Label>}
      {description && <p>{description}</p>}
      <StyledInput type="select" name={name}  id={name} {...rest} className="lightPurpleShadow form-control">
        <option value="" />
        {options.map((option) => (
          <option  key={option._id} value={option._id}>
            {option.name}
          </option>
        ))}
      </StyledInput>
      {error && <div className="alert alert-danger">{error}</div>}
    </FormGroup>
  );
};

export default Select;

const StyledInput = styled(Input)`
  appearance: menulist;
`
