import React, {useEffect} from "react";
import { Route, Redirect } from "react-router-dom";
import { Event, PageView } from "../../../utils/analytics";

const VerifiedRoute = ({ path, title, user, component: Component, render, ...rest }) => {

  useEffect(() => {
    document.title = title;
    PageView(title, path);
  },[]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user !== "Guest" && !user.isArchived){
          if((path !== "/first-time") && (!user.passwordHasChanged || !user.firstPageComplete || !user.secondPageComplete)){
            return (
              <Redirect
                to={{ pathname: "/first-time", state: { from: props.location } }}
              />
            );
          }
          else{
            return Component ? <Component {...props} /> : render(props);
            
          }
          
        }
        else if(user.isArchived){
          window.location = "/archived-account";
        }
        else{
          return(
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )}      
      }}
    />
  );
};

export default VerifiedRoute;