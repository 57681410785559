import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { db } from '../../../services/firebase';
import ContentListUpdate from './contentListUpdate';
import styled from 'styled-components'
import GroupNoteUpdate from './groupNoteUpdate';

const CorePageForm = () => {
    const [primaryText, setPrimaryText] = useState([]);
    const [coreList, setCoreList] = useState([]);
    const [groupNotes, setGroupNotes] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalTitle, setModalTitle] = useState("");

    const [coreIntroVideoURL, setCoreIntroVideoURL] = useState("");
    const [exerciseBarriersVideoURL, setExerciseBarriersVideoURL] = useState("");
    const [exerciseBenefitsVideoURL, setExerciseBenefitsVideoURL] = useState("");
    const [exercisePrescriptionVideoURL, setExercisePrescriptionVideoURL] = useState("");

    const toggle = () => setModal(!modal);
    const docRef = db.collection("webPages").doc("Core")

    useEffect(() => {
        ReturnCorePageInformation();   
    }, []);

    const ReturnCorePageInformation = () =>{
        docRef.onSnapshot((doc) => {
            if(doc.data()){
                const data = doc.data();
                if(data.primaryText){
                    setPrimaryText(data.primaryText);
                }
                if(data.coreList){
                    setCoreList(data.coreList);
                }
                if(data.groupNotes){
                    setGroupNotes(data.groupNotes);
                }
                if(data.coreIntroVideoURL){
                    setCoreIntroVideoURL(data.coreIntroVideoURL);
                }
                if(data.exerciseBarriersVideoURL){
                    setExerciseBarriersVideoURL(data.exerciseBarriersVideoURL);
                }
                if(data.exerciseBenefitsVideoURL){
                    setExerciseBenefitsVideoURL(data.exerciseBenefitsVideoURL);
                }
                if(data.exercisePrescriptionVideoURL){
                    setExercisePrescriptionVideoURL(data.exercisePrescriptionVideoURL);
                }
            }
            else{
                docRef.set({primaryText, coreList})
            }
        });
    }

    const UpdatePrimaryText = (text) => {
        docRef.update({ primaryText: text }).then(() => {
            setModalTitle("Text Content Updated");
            setModalMessage("Successfully updated text for Core Page");
            toggle();
        })
        .catch((error) => {
            setModalTitle("Something went wrong");
            setModalMessage("An error occurred when trying to update text");
            toggle();
        });
    }

    const UpdateCORECarousel = (list) => {
        docRef.update({ coreList: list }).then(() => {
            setModalTitle("Text Content Updated");
            setModalMessage("Successfully updated CORE carousel");
            toggle();
        })
        .catch((error) => {
            setModalTitle("Something went wrong");
            setModalMessage("An error occurred when trying to update carousel");
            toggle();
        });
   }

   const UpdateGroupNotes = (text) =>{
       docRef.update({groupNotes: text}).then(() =>{
        setModalTitle("Text Content Updated");
        setModalMessage("Successfully updated text for Core Page");
        toggle();
    })
    .catch((error) => {
        setModalTitle("Something went wrong");
        setModalMessage("An error occurred when trying to update text");
        toggle();
       });
   }

   const UpdateVideoURL = (video, url) =>{
    docRef.update({[video]: url}).then(() =>{
     setModalTitle("Video URL Updated");
     setModalMessage("Successfully updated embed ID for: " + video + " video to: " + url);
     toggle();
    })
    .catch((error) => {
        setModalTitle("Something went wrong");
        setModalMessage("An error occurred when trying to update youtube embed ID: " + error);
        toggle();
        });
    }
   
    return ( <Container>
        <Row>
            <Col>
                <h2>Right Side Entries</h2>
                 <ContentListUpdate returnDataCallback={UpdatePrimaryText} objectList={primaryText} />
            </Col>
        </Row>
        <Row>
            <Col>
                <h2>Core Page Details</h2>
            </Col>
        </Row>
        <br />
        <Row>
            <Col>
                <h2>CORE Carousel</h2>
                <ContentListUpdate returnDataCallback={UpdateCORECarousel} objectList={coreList} hasImageOnList={true} contentName="PartnershipCarousel" />
                <br />
            </Col>
        </Row>
        <Row>
            <Col>
                <h2>Group Notes</h2>
                <GroupNoteUpdate returnDataCallback={UpdateGroupNotes} objectList={groupNotes} contentName="GroupNotes" />
                <br />
            </Col>
        </Row>
        <Row>
            <Col>
                <h2>Youtube Embed IDs</h2>
                    <Container>
                        <Row>
                            <Col xs='5'>
                                <StyledP>Core Intro Video Embed ID:</StyledP>
                            </Col>
                            <Col>
                                <Input onChange={(e) => {setCoreIntroVideoURL(e.target.value)}} value={coreIntroVideoURL}></Input>
                            </Col>
                            <Col>
                                <Button onClick={() => { UpdateVideoURL("coreIntroVideoURL", coreIntroVideoURL)}}>Update</Button>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Col xs='5'>
                                <StyledP>Exercise Benefits Video Embed ID:</StyledP>
                            </Col>
                            <Col>
                                <Input onChange={(e) => {setExerciseBenefitsVideoURL(e.target.value)}} value={exerciseBenefitsVideoURL}></Input>
                            </Col>
                            <Col>
                                <Button onClick={() => { UpdateVideoURL("exerciseBenefitsVideoURL", exerciseBenefitsVideoURL)}}>Update</Button>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Col xs='5'>
                                <StyledP>Exercise Prescription Video Embed ID:</StyledP>
                            </Col>
                            <Col>
                                <Input onChange={(e) => {setExercisePrescriptionVideoURL(e.target.value)}} value={exercisePrescriptionVideoURL}></Input>
                            </Col>
                            <Col>
                                <Button onClick={() => { UpdateVideoURL("exercisePrescriptionVideoURL", exercisePrescriptionVideoURL)}}>Update</Button>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Col xs='5'>
                                <StyledP>Exercise Barrier Video Embed ID:</StyledP>
                            </Col>
                            <Col>
                                <Input onChange={(e) => {setExerciseBarriersVideoURL(e.target.value)}} value={exerciseBarriersVideoURL}></Input>
                            </Col>
                            <Col>
                                <Button onClick={() => { UpdateVideoURL("exerciseBarriersVideoURL", exerciseBarriersVideoURL)}}>Update</Button>
                            </Col>
                        </Row>
                    </Container>
                <br />
            </Col>
        </Row>
        <Row>
            <Col>
                <StyledModal isOpen={modal}>
                    <ModalHeader>{modalTitle}</ModalHeader>
                        <ModalBody>
                            {modalMessage}
                        </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={toggle}>Done</Button>
                    </ModalFooter>
                </StyledModal>
            </Col>
        </Row>
    </Container> );
}
 
export default CorePageForm;

const StyledModal = styled(Modal)`
    position: absolute;
    left: 40%;
    top: 40%;
`

const StyledP = styled.p`
    margin: 0px;
`;  