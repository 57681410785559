import React, { useState, useEffect } from 'react';
import { Container, Row, Button } from 'reactstrap';
import { db } from '../../services/firebase';
import styled from 'styled-components'
import TeamMemberRow from './adminComponents/adminTeamMemberRow';
import AdminNav from './adminComponents/adminNav';

const TeamProfiles = () => {
    const [teamMembers, setTeamMembers] = useState([]);

    useEffect(() => {
        populateTeamMemberList();
      }, []);

    const populateTeamMemberList = () =>{
        var tempmembers = [];
        db.collection("teamMembers").doc("TeamList").get().then(function(doc) {
            if (doc.exists) {
                var teamList = doc.data();
                for (const teamMember in teamList) {
                    var profileurl = "/edit-team-profile/" + teamMember;
                    var tempmember = {
                        name: teamList[teamMember].name,
                        location: teamList[teamMember].location,
                        specialization: teamList[teamMember].specialization,
                        imageUrl: teamList[teamMember].imageUrl,
                        profileurl: profileurl,
                        id: teamMember
                    }
                    tempmembers.push(tempmember);
                }

                tempmembers.sort(function(a, b) {
                    var textA = a.name.toUpperCase();
                    var textB = b.name.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });

                setTeamMembers(tempmembers);
            } else {
                console.log("Unable to find TeamList");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }

    return (
        <React.Fragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage />
                </ParentDiv>   
            </HeightDiv>
            <StyledContainer>
                <AdminNav />
                <h1>Team Members</h1>
                <Button color="primary" href="/edit-team-profile/new">New Team Member</Button>
                <br /><br />
                <Row>
                    {teamMembers.map((member, i) => {return(
                        <TeamMemberRow key={i} member={member} />)
                    })} 
                </Row>
            </StyledContainer>
        </React.Fragment>
    );
}
 
export default TeamProfiles;

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #372844;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 1200px;
    margin-top: 15px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #9780af;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;