import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components'
import YoutubeEmbed from '../common/youTubeEmbed';
import CollapseQuestion from './collapseSection';

const FrequentlyAskedQuestions = () => {
    return (
        <React.Fragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage />
                </ParentDiv>   
            </HeightDiv>
            <MarginDiv>
                <StyledContainer>
                    <h1>Frequently Asked Questions</h1>
                    {/* <h2>How do I get started?</h2> */}
                    <br />
                    <CollapseQuestion
                        question="How does the HIMALAYAS support site work?"
                        answer={<ul>
                            <StyledListItem>
                                The first step is to <a href="/register">sign up</a> online to join the HIMALAYAS community.  You will be asked to 
                                create a username and password (please don’t use your email as your username, as it will be 
                                visible to the other members!) and provide some basic information.
                            </StyledListItem>
                            <StyledListItem>
                                You will then be sent a confirmation email with a link.  Click the link to confirm your email address; 
                                the link will take you directly to the page(s) where you create your personal profile.
                            </StyledListItem>
                            <StyledListItem>
                                Creating your profile involves answering a few questions that will help other members get to know you 
                                (for example, your current level of activity, favourite activities, etc).  Please include a photo — this 
                                helps the other CORE participants connect with you and feel more comfortable.
                            </StyledListItem>
                            <StyledListItem>
                                Within one business day (likely much sooner) from the time you submit your profile, you will receive an email notification 
                                that your profile has been approved.  Click the link in the email or visit the login page of the HIMALAYAS website 
                                to log in with your username and password.
                            </StyledListItem>
                            <StyledListItem>
                                Once you have logged in, you can search for a partner by browsing all profiles or you can limit the profiles you see by age, 
                                city, distance from you, cancer diagnosis or preferred  activity.  Once you have found someone you would like to connect with, 
                                send them a message using the purple ‘Message‘ button and they will receive an email notification that you have contacted them.
                            </StyledListItem>
                            <StyledListItem>
                                Get to know each other and decide together if and how you want to begin exercising with and/or supporting one another (e.g in person or virtually).
                            </StyledListItem>
                        </ul>} />
                    <hr />
                    {/*
                    <h2>Is it easy to register to create a profile?</h2>
                    <StyledParagraph>Yes!  The video below offers a step-by-step guide to registering and creating your HIMALAYAS profile:</StyledParagraph>
                    <Row>
                        <StyledVideoCol lg="7">
                            <YoutubeEmbed embedId="dQw4w9WgXcQ" />
                        </StyledVideoCol>
                    </Row>
                    <hr />
                    */}
                    <CollapseQuestion 
                        question="Is this program available across Canada?"
                        answer={<div>
                        <StyledParagraph>
                            Yes it is.  During the HIMALAYAS study, the site will support connections for AYAs within and between Edmonton, Halifax, Montréal, Toronto, 
                            and Vancouver. We will use the information collected during the study to improve the site and hope to eventually expand it to AYAs throughout Canada. 
                        </StyledParagraph>
                        <StyledParagraph>
                            Soon after study launch, fewer people will be registered in the study and on the site. So, remember to check back often as more people join 
                            the study and the community. 
                        </StyledParagraph>
                        <StyledParagraph>
                            In addition to finding people in your city to exercise with, we highly encourage you to connect with one or more virtual exercise 
                            partners (i.e., people who can offer support via phone, text, email, video call, etc.). Together you can empower each other and take 
                            control of your health with exercise.
                        </StyledParagraph>
                        </div>}
                    />
                    <hr />
                    {/* <h2>Is the HIMALAYAS site safe and secure?</h2> */}
                    <CollapseQuestion 
                        question="Will my personal information be kept private?"
                        answer={<div>
                        <StyledParagraph>
                            This support website has multiple layers of safety and security (e.g., screening questions to detect fraudulent profiles).  The HIMALAYAS 
                            site is housed on a secure webspace that requires registration and approval by the HIMALAYAS administration team.  Personal information 
                            will not be visible to people searching the web or people who are not part of the exercise groups.  
                            Safeguards are in place to protect confidential information in compliance 
                            with all laws and regulations to prevent any unauthorized use or disclosure of data.
                        </StyledParagraph>
                        <StyledParagraph>
                            As a HIMALAYAS site member, you have a unique username associated with your profile, so you and other members can reach out to one another 
                            through the HIMALAYAS site messenger system (rather than needing to use your personal email).  Your contact information is kept confidential, 
                            so it is up to you whether you want to share any personal contact information with your potential exercise partners!
                        </StyledParagraph>
                        </div>}
                    />
                    <hr />

                    <CollapseQuestion 
                        question="Can I choose which information is displayed on my profile?"
                        answer={<div>
                        <StyledParagraph>
                            Only HIMALAYAS site members will see your profile.  The following information is displayed on your personal profile: Username, age, your 
                            ‘About You’ introduction, exercise history (i.e reasons for wanting an exercise partner, personality, favourite activities, fitness motivation, fitness level, and 
                            preferred exercise location), and cancer history(diagnosis, treatment status, treatment types, and engagement with cancer support resources).
                            Cancer survivors have previously identified this information as being helpful / important 
                            when choosing an exercise partner.
                        </StyledParagraph>
                        <StyledParagraph>
                            <b>Information that is NOT displayed or available to any users:</b> Your postal code and date of birth.  Your postal code is used to identify 
                            your proximity to potential matches, but it won’t be visible as part of your profile.
                        </StyledParagraph>
                        </div>}
                    />
                    <hr />


                    <CollapseQuestion 
                        question="Do I need to upload a photo to my profile?"
                        answer={<div>
                        <StyledParagraph>
                            HIMALAYAS site members are not required to upload a photo, but may do so if they feel comfortable as part of their profile.
                        </StyledParagraph>
                        </div>}
                    />
                    <hr />

                    <CollapseQuestion 
                        question="How do I update my profile and profile picture?"
                        answer={<div>
                        <StyledParagraph>You can update your profile and picture at any time.  Here’s how:</StyledParagraph>
                        <ol>
                            <StyledListItem>From www.HIMALAYAStrial.ca, click the <b>Login</b> button at the top of your screen.</StyledListItem>
                            <StyledListItem>Log in with your username and password.</StyledListItem>
                            <StyledListItem>Click on the <b>My Profile</b> button at the top of screen.</StyledListItem>
                            <StyledListItem>You will see purple buttons on the right side of the screen.</StyledListItem>
                            <StyledListItem>Select the <b>Edit My Profile</b> button to change or add to your profile. <b>Save Profile</b> when you are done.</StyledListItem>
                            <StyledListItem>Select the <b>Upload A Photo</b> button to add a picture or change your picture.</StyledListItem>
                        </ol>
                        </div>}
                    />
                    <hr />

                    <CollapseQuestion 
                        question="How do I connect with someone / find a match?"
                        answer={<div>
                        <StyledParagraph>
                            When you log in, you will see a display of all the profiles in the system.  You can use the filtering system to 
                            enter criteria (e.g., age).  When you click “Search”, the HIMALAYAS site will generate a list of possible matches.  
                            Click the name of the person whose profile you want to see in more detail.
                        </StyledParagraph>
                        <StyledParagraph>
                            If it seems like they might be a good exercise partner match for you, click “Message” to send a private message to that member!  
                            The member will get an email letting them know that someone from the HIMALAYAS site has messaged them.  You can continue to message 
                            each other through the system (or exchange contact info) and then decide together how to get started.
                        </StyledParagraph>
                        </div>}
                    />
                    <hr />
        
                    <CollapseQuestion 
                        question="I’ve found a match – How can we support one another?"
                        answer={<div>
                            <StyledParagraph>HIMALAYAS site partners connect in lots of different ways, including:</StyledParagraph>
                            <ul>
                                <StyledListItem>Exchanging numbers and texting each other</StyledListItem>
                                <StyledListItem>Connecting over the phone (e.g., to strategize about building more exercise into your days)</StyledListItem>
                                <StyledListItem>Meeting up to go for walks, bike rides, and runs</StyledListItem>
                                <StyledListItem>Finding new activities that you can try together</StyledListItem>
                                <StyledListItem>Sharing exercise tips and strategies you have found helpful</StyledListItem>
                                <StyledListItem>Locating and signing up for online classes or a structured exercise program</StyledListItem>
                            </ul>
                        </div>}
                    />
                    <hr />

                    <CollapseQuestion 
                        question="What if I don’t see anyone who would be a good fit for me?"
                        answer={<div>
                        <StyledParagraph>
                            We are working to promote the study to as many AYAs living with stage B heart failure as possible so that you can find your ideal match.
                            By the end of the study, about 170 exercise group members will be registered on the site. 
                            The more AYAs that sign up, the more choices there will be.  If you cannot find a great exercise partner near you initially, we encourage 
                            you to broaden your search criteria (e.g., increase age range and/or distance from you) and keep checking back often. 
                        </StyledParagraph>
                        <StyledParagraph>
                            If you cannot find anyone that lives nearby, we recommended connecting with someone as virtual exercise partner who can offer support and 
                            encouragement over the phone, through emails, and/or by text.  Having a virtual exercise partner can help keep you accountable to your exercise plans!
                        </StyledParagraph>
                        </div>}
                    />
                    <hr />
    
                    <CollapseQuestion 
                        question="What if I message someone and they don’t message back?"
                        answer={<div>
                        <StyledParagraph>
                            We’re sorry to hear that you’ve been kept wondering / waiting.  The member(s) you messaged might have missed the email notification that 
                            they have a new HIMALAYAS message waiting for them (sometimes the notifications get filtered to junkmail).  We hope you will
                            get a reply from the member soon, but if it has been a while and you have not heard back, please consider messaging someone else (i.e., another 
                            potential match) too.
                        </StyledParagraph>
                        <StyledParagraph>
                            If you have tried to connect with several members and have not heard anything back,
                            please send us an email at <a href="mailto:HIMALAYAStrial@uhn.ca">HIMALAYAStrial@uhn.ca</a> and we will try to help.
                        </StyledParagraph>
                        </div>}
                    />
                    <hr />

                    <CollapseQuestion 
                        question="What if I want to exercise with more than one person?"
                        answer={<div>
                        <StyledParagraph>
                            Great! If you want to put together a [virtual] exercise group, we encourage that too.  You can message as many people as you want 
                            through the system and when you are ready, you can exchange emails and other contact information and form your group.
                        </StyledParagraph>
                        </div>}
                    />
                    <hr />

                    <CollapseQuestion 
                        question="Is the HIMALAYAS site available in other languages?"
                        answer={<div>
                        <StyledParagraph>
                            The HIMALAYAS site is currently available only in English and French, but if you speak a language other than English and 
                            French and would like to find an exercise partner that speaks that same language, we encourage you to indicate that in the 
                            ‘About Me’ section of your profile (e.g., write your ‘About Me’ description in your preferred language!)
                        </StyledParagraph>
                        <StyledParagraph>
                            TIP: If your ‘About Me’ description is in a language other than English or French, be sure to include your description in English 
                            or French too if you would be interested in connecting with an English- or French-speaking exercise partner.
                        </StyledParagraph>
                        </div>}
                    />
                    <hr />

                    <CollapseQuestion 
                        question="What about if someone messages me in a way that’s inappropriate?"
                        answer={<div>
                        <StyledParagraph>
                            Please report any issues immediately to <a href="mailto:HIMALAYAStrial@uhn.ca">HIMALAYAStrial@uhn.ca</a> and we will take the appropriate steps to contact the participant 
                            and address the issue.  You can also go to a profile and click “Report Offensive Profile” and we will receive this notification immediately.
                        </StyledParagraph>
                        </div>}
                    />
                    <hr />

                    <CollapseQuestion 
                        question="I have a HIMALAYAS site account, but now I’m having trouble logging in.  What do I do?"
                        answer={<StyledParagraph>
                            Our <a href="/login">login</a> page has ‘forgot your username’ and ‘forgot your password’ options.  If that doesn’t work 
                            (and you’ve checked your junk folder in case the email notification went there!), 
                            please contact us at <a href="mailto:HIMALAYAStrial@uhn.ca">HIMALAYAStrial@uhn.ca</a>
                        </StyledParagraph>}
                    />
                    <hr />

                    <CollapseQuestion 
                        question="Does the HIMALAYAS site provide suggested exercises?"
                        answer={<div>
                        <StyledParagraph>
                            The HIMALAYAS site is focused on connecting you with the information and people you need to take control of your health with exercise.
                            We respect that everyone has different preferences, experiences, and goals when it comes to exercise. We also know that it can be tricky 
                            to know where to start with exercise. The exercise professionals you are working during your in-person HIIT sessions are a great source of 
                            information and support for you. If you are no longer attending weekly in-person sessions, or are looking for additional sources support and 
                            information, we suggest you revisit the:
                        </StyledParagraph>
                        <ul>
                            <StyledListItem>The HIMALAYAS behavioural support resources in the exercise groups section of this site</StyledListItem>
                        </ul>
                        </div>}
                    />
                    <hr />

                    <CollapseQuestion 
                        question="I still have a question!  Who do I contact?"
                        answer={<StyledParagraph>
                            If you have any other questions, please do not hesitate to contact <a href ="mailto:HIMALAYAStrial@uhn.ca">HIMALAYAStrial@uhn.ca</a>. 
                            We would love to hear from you!
                        </StyledParagraph>}
                    />
                    <hr />

                    <CollapseQuestion 
                        question="How are concerns about Coronavirus (COVID-19) being handled?"
                        answer={<div>
                        <StyledParagraph>
                            The HIMALAYAS team is committed to keeping you safe by closely following the national, provincial, and local (hospital-specific) 
                            guidelines and best practices. As restrictions change, so will the study’s COVID-19-related safety procedures. Please contact the 
                            Principle Investigator from your city if you have any questions or concerns about the study’s COVID-19-related safety policies and 
                            procedures. Click here to find the contact information for the Principle Investigator from your city.
                        </StyledParagraph>
                        <h3><b>Additional resources related to COVID-19:</b></h3>
                        <StyledParagraph>
                            The HIMALAYAS team is committed to the wellness of all participants.  In light of the public health concerns related to COVID-19, 
                            we would like to support our participants in accessing relevant information about how to protect themselves and others.
                        </StyledParagraph>
                        <StyledParagraph>
                            For up-to-date information about COVID-19, please refer to publications from the Public Health Agency of Canada and the World Health 
                            Organization (WHO):
                        </StyledParagraph>
                        <ul>
                            <StyledListItem><a href="https://www.canada.ca/en/public-health/services/diseases/coronavirus-disease-covid-19.html">
                                Public Health Agency of Canada – Coronavirus Information</a>
                            </StyledListItem>
                            <StyledListItem><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public">
                                World Health Organization (WHO) – Coronavirus-19 Advice for the Public</a>
                            </StyledListItem>
                            <StyledListItem><a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public/myth-busters">
                                World Health Organization (WHO) – Coronavirus-19 Advice for the Public: Myths and Facts</a>
                            </StyledListItem>
                        </ul>
                        <StyledParagraph>
                            For information about cancer and COVID-19, including individualized supports and information available to you, 
                            please visit the Canadian Cancer Society’s website:
                        </StyledParagraph>
                        <ul>
                            <StyledListItem><a href="https://action.cancer.ca/en/living-with-cancer/how-we-can-help/cancer-and-covid19">
                                Canadian Cancer Society – Cancer and COVID-19</a></StyledListItem>
                        </ul>
                        <StyledParagraph>Hygiene habits that help to prevent the spread of infections include:</StyledParagraph>
                        <ul>
                            <StyledListItem>Washing your hands often with soap and water for at least 20 seconds</StyledListItem>
                            <StyledListItem>Using alcohol-based hand sanitizer</StyledListItem>
                            <StyledListItem>Avoiding touching your eyes, nose, and mouth</StyledListItem>
                            <StyledListItem>Keeping a distance (3 ft or more) from anyone who is coughing or sneezing</StyledListItem>
                            <StyledListItem>Staying at home if you are feeling unwell</StyledListItem>
                        </ul>
                        <StyledParagraph>
                            Given that some HIMALAYAS participants have compromised immune systems, we ask that you please take extra precautions 
                            when it comes to meeting up with your exercise partner(s) or exercising in public spaces.  Please avoid using any public 
                            or shared equipment as part of your activities for the time being.
                        </StyledParagraph>
                        <StyledParagraph>
                            For those of you postponing or adapting exercise plans with your partner(s), please be reminded that you can continue to 
                            support one another’s exercise goals virtually (e.g., via phone, text, or videocall).
                        </StyledParagraph>
                        <StyledParagraph>
                            As you care for yourselves during this time, we hope that you will refer to resources that can help with managing fears and 
                            anxiety related to COVID-19 (e.g., Harvard has published a  
                            <a href="https://www.harvard.edu/coronavirus/wp-content/uploads/sites/5/2021/02/coronavirus_HUHS_managing_fears_A25.pdf">helpful resource</a>).
                        </StyledParagraph>
                        <StyledParagraph>
                            Please don’t hesitate to <a href="mailto:HIMALAYAStrial@gmail.com">contact us</a> if you have questions or concerns or if you would like to be directed to additional information.
                        </StyledParagraph>
                    </div>}
                    />
                </StyledContainer>
            </MarginDiv>
        </React.Fragment>
    );
}
 
export default FrequentlyAskedQuestions;

const MarginDiv = styled.div`
    margin-left: 30px;
    margin-right: 30px;
`

const StyledParagraph = styled.p`
    font-size: large;
`

const StyledListItem = styled.li`
    font-size: large;
`

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #372844;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 1500px;
    margin-top: 15px;
    margin-bottom: 90px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #dddddd;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;