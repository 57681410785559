import React, { useState } from 'react';
import { Container, Row, Col, Label, Input, Button } from 'reactstrap';
import { auth } from '../../services/firebase'
import styled from 'styled-components'
import groupImage from "../../images/MorningRun.png"

//TODO I need to make another page that the website redirects the user to so they can reset their password
const LostPassword = () => {
    const [email, setEmail] = useState("");
    const [sentEmail, setSentEmail] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const doSubmit = async () => {
        const data = {
            email: email,
        }
        await auth.sendPasswordResetEmail(data.email)
        .then(() => {
          console.log("Password email reset sent");
          setSentEmail(true);
      })
        .catch((error) => {
          var errorMessage = error.message;
          console.log("Something went wrong" + errorMessage);
          setErrorMessage("Invalid Email Address");
        });    
    };

    return ( <React.Fragment>
        <HeightDiv>
            <ParentDiv>
                <StyledBackgroundImage src={groupImage} alt="Keep at it!" />
            </ParentDiv>   
        </HeightDiv>
        {!sentEmail && <StyledContainer>
            <Row>
                <Col>
                    <h1>Password Reset</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Please enter your email for password reset</Label>
                    <StyledInput name="email" id="email" placeholder="Email..." onChange={e => setEmail(e.target.value)} />
                    <br />
                </Col>
            </Row>
            <Row>
                <Col>{errorMessage && <div className="alert alert-danger">{errorMessage}</div>}</Col>
            </Row>
            <Row>
            <Button color="primary" onClick={() => {doSubmit()}}>Reset Password</Button>
            </Row>
            <br />
            < Row>
                <p><a href="/register">Need an account</a>?</p>
            </Row>
        </StyledContainer> }
        {sentEmail && <StyledContainer>
            <Row>
                <Col>
                    <h2>Password Reset Email Sent</h2>
                </Col>
            </Row>
        </StyledContainer> }
    </React.Fragment>);
}
 
export default LostPassword;

const StyledInput = styled(Input)`
&:focus{
    border-color: #372844;
    box-shadow: 0 0.5rem 1rem #9780af;
}
`

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #9780af;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 500px;
    margin-top: 15px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;