import React, { useEffect, useState }  from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import styled from 'styled-components'
import blankUser from '../../../images/BlankUser.jpg';

const ProfileCards = ({ users }) => {
    const [currentListSpot, setCurrentListSpot] = useState(0);

    useEffect(() => {
        setCurrentListSpot(0);
      }, [users]);

    const SetPage = (number) => {
        setCurrentListSpot(number);
    }

    function CalculateAge(birthday) {
        // Destructure the day, month, and year from the birthday object
        if (birthday === undefined) {
            return 0;
        }
        const { day, month, year } = birthday;
      
        // Create a date object for the user's birthday
        // Adjust the month by subtracting 1 since JavaScript's Date months are 0-indexed
        const birthDate = new Date(year - 1, month - 1, day);
      
        // Get the current date
        const currentDate = new Date();
      
        // Calculate the age
        let age = currentDate.getFullYear() - birthDate.getFullYear();
        const m = currentDate.getMonth() - birthDate.getMonth();
      
        // If the current month is before the birth month or
        // it's the same month but the current day is before the birth day, subtract one year from the age
        if (m < 0 || (m === 0 && currentDate.getDate() < birthDate.getDate())) {
          age--;
        }
      
        return age;
    }

    function GenerateProfileURL(user) {
        //console.log(user);
        return `/profile/${user.uid}`;
    }

    return (
    <StyledContainer fluid={true}>
        <Row>
            {users[currentListSpot] && users.length > 0 && users[currentListSpot].map((user, i) => { return(<ProfileCardCol key={user.uid} xs="12" sm="6" lg="4" xl="3">
                <ProfileCard>
                    {user.imageurl && <a href={user.profileurl}><CardImage src={user.imageurl} alt={user.username} /></a>}
                    {!user.imageurl && <a href={user.profileurl}><CardImage src={blankUser} alt={user.username} /></a>}
                    
                    <br />
                    <a href={GenerateProfileURL(user)}>{user.username}</a>
                    <p>Age: {CalculateAge(user.dateofbirth)}<br /></p>
                </ProfileCard>
            </ProfileCardCol>)
        })} 
        </Row>
        <br />
        <Row>
            {users.length > 1 && <Col> 
                <StyledButton disabled={currentListSpot === 0} onClick={() => SetPage(0)}>First</StyledButton>
               {currentListSpot > 1 && <StyledButton  onClick={() => SetPage(currentListSpot - 2)}>{currentListSpot - 1}</StyledButton>}
               {currentListSpot > 0 && <StyledButton  onClick={() => SetPage(currentListSpot - 1)}>{currentListSpot}</StyledButton>}
                <StyledButton color="primary" onClick={() => SetPage(currentListSpot)}>{currentListSpot + 1}</StyledButton>
               {currentListSpot < users.length - 1 && <StyledButton  onClick={() => SetPage(currentListSpot + 1)}>{currentListSpot + 2}</StyledButton>}
               {currentListSpot < users.length - 2 && <StyledButton  onClick={() => SetPage(currentListSpot + 2)}>{currentListSpot + 3}</StyledButton>}
                <StyledButton disabled={!(currentListSpot < users.length - 1)} onClick={() => SetPage(users.length - 1)}>Last</StyledButton>
            </Col>}
        </Row>
    </StyledContainer>)}
 
export default ProfileCards;

const StyledContainer = styled(Container)`

`

const StyledButton = styled(Button)`
    margin-left: 2px;
    margin-right: 3px;
`

const ProfileCardCol = styled(Col)`
display: grid;
justify-items: center;
`

const ProfileCard = styled.div`
    border-style: solid;
    border-width: 1px;
    border-color: black;
    text-align: center;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 20px;
    border-radius: 15px;
    box-shadow: 0 0.2rem .6rem #372844;
    max-width: 300px;
    padding: 10px;
    padding-left: 25px;
    padding-right: 25px;
`
const CardImage = styled.img`
    height: 150px;
    width: 150px;
    padding-top: 10px;
`