import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { getParameterByName } from '../../services/helper';

const ActionUrl = () => {
    var action = getParameterByName("mode");
    var actionCode = getParameterByName("oobCode");

    return ( <Route
        render={(props) => {
          if (action === "verifyEmail"){
              return(<Redirect
                to={{ pathname: "/first-time", state: { from: props.location, actionCode: actionCode } }}  />)
          }
          else if(action === "resetPassword"){
            return (<Redirect
                to={{ pathname: "/password-reset", state: { from: props.location, actionCode: actionCode } }} />
            )}      
        }} /> );
}
 
export default ActionUrl;