import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Input, Label, Button } from 'reactstrap';
import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContactInput = (props) => {
    const [list, setList] = useState([]);

    useEffect(() => {
        setList([...props.objectList]);  
    }, [props.objectList]);

    const UpdatePrimaryText = (text, id, property) =>{
        var newText = list;
        newText[id][property] = text;
        setList([...newText]);
    }

    const DeleteEntry = (entry) => {
        var newText = list;
        newText.splice(entry, 1);
        setList([...newText]);
        props.returnDataCallback(newText);
    }

    const newEntry = {
        city: "",
        contactName: "",
        investigators: "",
    }

    const AddNewEntry = () =>{
        var newText = list;
        list.push(newEntry);
        setList([...newText]);
    }

    const TriggerSubmit = () => {
        props.returnDataCallback(list);
    }

    return ( 
        <Container fluid={true}>    
        {list.map((entry, i) => (
            <div key={i}>
                <Row>
                    <Col lg="8">
                        <Label>City:</Label>
                        <StyledInput  name="city" id="city" placeholder="City..." value = {entry.city} onChange={e => UpdatePrimaryText(e.target.value, i, "city")}/>
                    </Col>
                    <TrashButtonCol lg="2">
                        <StyledButton onClick={() => DeleteEntry(i)} color="danger">
                            <FAIconButton icon={["fas", "trash"]}></FAIconButton>
                        </StyledButton> 
                    </TrashButtonCol>
                </Row>
                <Row>
                    <Col>
                        <Label>Study Coordinators:</Label>
                        <br />
                        <StyledInput  name="contactName" id="contactName" placeholder="Contact..." value = {entry.contactName} onChange={e => UpdatePrimaryText(e.target.value, i, "contactName")}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label>Principal Investigator:</Label>
                        <br />
                        <StyledInput  name="investigators" id="investigators" placeholder="Investigators..." value = {entry.investigators} onChange={e => UpdatePrimaryText(e.target.value, i, "investigators")}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label>Research and Ethics Board:</Label>
                        <br />
                        <StyledInput  name="ethicsboard" id="ethicsboard" placeholder="Ethics Board..." value = {entry.ethicsboard} onChange={e => UpdatePrimaryText(e.target.value, i, "ethicsboard")}/>
                    </Col>
                </Row>
                <hr />
                <br />
            </div>
            ))}
            <br />
            <Row>
                <Col>
                    <StyledButton onClick={() => AddNewEntry()} color="primary">
                        <FAIconButton  icon={["fas", "plus"]}></FAIconButton> Add Entry
                    </StyledButton>
                </Col>
                <Col>
                    <StyledButton onClick={() => TriggerSubmit()} color="primary">
                                    <FAIconButton  icon={["fas", "check"]}></FAIconButton> Save Details
                    </StyledButton>
                </Col>
            </Row>
            <hr />
        </Container> );
}
 
export default ContactInput;

const StyledInput = styled(Input)`
&:focus{
    border-color: #372844;
    box-shadow: 0 0.5rem 1rem #9780af;
}
`

const TrashButtonCol = styled(Col)`
    margin-top: 25px;
`

const FAIconButton = styled(FontAwesomeIcon)`
    margin-right: 0px;
    margin-left: 0px;
    
`;

const StyledButton = styled(Button)`
    max-width: 175px;
    max-height: 35px;
    margin-left: 5px;
`;