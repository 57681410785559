import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, ButtonGroup, Label, Input } from 'reactstrap';
import { db } from '../../../services/firebase';
import styled from 'styled-components';
import { TicketResolvedArray, TicketStrings, TicketTypeArray } from './ticketStrings';
import { convertUTCDateToLocalDate } from '../../../services/helper';
import AdminNav from '../adminComponents/adminNav';

const TicketListings = () => {
    const [filteredTicketList, setFilteredTicketList] = useState([]);
    const [completeTicketList, setCompleteTicketList] = useState([]);
    const [typeFilter, setTypeFilter] = useState("");
    const [keywordFilter, setKeywordFilter] = useState("");
    const [resolvedFilter, setResolvedFilter] = useState("false");

    useEffect(() => {
        getTickets();
      }, []);

    const getTickets = () => {
        var ticketList = [];

        db.collection("tickets").get().then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                const ticket = doc.data();
                ticket.id = doc.id;
                ticketList.push(ticket);
            });
            ticketList = insertionSort(ticketList);
            setCompleteTicketList(ticketList);
            filterTickets(typeFilter, keywordFilter, resolvedFilter, ticketList);
        })
    }

    const keywordFilterSearch = (keyword) => {
        setKeywordFilter(keyword);
        filterTickets(typeFilter, keyword, resolvedFilter);
     }

     const resolveFilterSearch = (resolved) => {
         setResolvedFilter(resolved);
         filterTickets(typeFilter, keywordFilter, resolved);
     }

    const filterTickets = (type, keyword = keywordFilter, resolved = resolvedFilter, fullTicketList = completeTicketList) => {
        setTypeFilter(type);
        var newFilteredList = [];
        var keywordList = [];
        if(keyword !== ""){
           for(let i = 0; i < fullTicketList.length; i++){
               if(fullTicketList[i].id.toLowerCase().includes(keyword.toLowerCase())){
                    keywordList.push(fullTicketList[i]);
               }
           }
        }
        else{
            keywordList = fullTicketList;
        }
        
        if(type === TicketStrings.message){
            for(let i = 0; i < keywordList.length; i++){
                if(keywordList[i].type === TicketStrings.message){
                    newFilteredList.push(keywordList[i]);
                }
            }
        }
       else if(type === TicketStrings.feedback){
           for(let i = 0; i < keywordList.length; i++){
               if(keywordList[i].type === TicketStrings.feedback){
                   newFilteredList.push(keywordList[i]);
               }
           }
       }
       else if(type === TicketStrings.bug){
           for(let i = 0; i < keywordList.length; i++){
               if(keywordList[i].type === TicketStrings.bug){
                   newFilteredList.push(keywordList[i]);
               }
           }
       }
       else if(type === TicketStrings.modulefeedback){
            for(let i = 0; i < keywordList.length; i++){
                if(keywordList[i].type === TicketStrings.modulefeedback){
                    newFilteredList.push(keywordList[i]);
                }
            }
       }
       else{
            newFilteredList = keywordList;
        }

        var resolvedList = [];
        if(resolved === "false"){
                for(let i = 0; i < newFilteredList.length; i++){
                    if(newFilteredList[i].resolved === false){
                        resolvedList.push(newFilteredList[i]);
                }
            }
        }
        else if(resolved === "true"){
            for(let i = 0; i < newFilteredList.length; i++){
                if(newFilteredList[i].resolved === true){
                    resolvedList.push(newFilteredList[i]);
                }
            }
        }
        else{
            resolvedList = newFilteredList;
        }
       setFilteredTicketList(resolvedList);
    }

    const insertionSort = (inputArr) => {
        let n = inputArr.length;
            for (let i = 1; i < n; i++) {
                let current = inputArr[i];
                let j = i-1; 
                while ((j > -1) && (current.timestamp.seconds > inputArr[j].timestamp.seconds)) {
                    inputArr[j+1] = inputArr[j];
                    j--;
                }
                inputArr[j+1] = current;
            }
        return inputArr;
    }

    return ( <React.Fragment>
        <HeightDiv>
            <ParentDiv>
                <StyledBackgroundImage />
            </ParentDiv>   
        </HeightDiv>
        <StyledContainer>
            <AdminNav />
            <Row>
                <h2>Tickets</h2>
                <br />
            </Row>
            <Row>
                <Col>
                    <Label>Ticket Type</Label>
                    <StyledInput type="select" name="filter" id="filter" onChange={e => filterTickets(e.target.value)}>
                            {TicketTypeArray.map((filter) => (
                                <option  key={filter._id} value={filter._id}>
                                    {filter.name}
                                </option>
                            ))}
                    </StyledInput>
                </Col>
                <Col>
                    <Label>Ticket Number</Label>
                    <StyledInput name="keyword" id="keyword" onChange={e => keywordFilterSearch(e.target.value)}/>
                </Col>
                <Col>
                    <Label>Resolved?</Label>
                    <StyledInput value={resolvedFilter} type="select" name="filter" id="filter" onChange={e => resolveFilterSearch(e.target.value)}>
                            {TicketResolvedArray.map((filter) => (
                                <option  key={filter._id} value={filter._id}>
                                    {filter.name}
                                </option>
                            ))}
                    </StyledInput>
                </Col>
            </Row>
            <br />
            <ButtonGroupRow vertical={true}>
                    {filteredTicketList && filteredTicketList.map((ticket, i) => {
                        var time = new Date(1970, 0, 1);
                        time.setSeconds(ticket.timestamp.seconds);
                        time = convertUTCDateToLocalDate(time);
                        return(
                            <ButtonRow key={i} href={"/view-ticket/" + ticket.id}>
                                <Row>
                                    <Col lg="2">Ticket#: {ticket.id}</Col>
                                    <Col lg="2"><h5>User: {ticket.username}</h5></Col>
                                    {ticket.type === TicketStrings.message && <Col lg="3"><h6>Messaging Concern</h6></Col>}
                                    {ticket.type === TicketStrings.bug && <Col lg="3"><h6>Bug Report</h6></Col>}
                                    {ticket.type === TicketStrings.feedback && <Col lg="3"><h6>General Feedback</h6></Col>}
                                    {ticket.type === TicketStrings.modulefeedback && <Col lg="3"><h6>Module Feedback</h6></Col>}
                                    <Col lg="3">{time.toLocaleString()}</Col>
                                    <Col lg="2">Resolved: {ticket.resolved.toString()}</Col>

                                </Row>
                        </ButtonRow>)})}
                {filteredTicketList.length === 0 && <p>No tickets found</p>}
            </ButtonGroupRow>
        </StyledContainer>
    </React.Fragment> );
}
 
export default TicketListings;

const StyledInput = styled(Input)`
max-width: 250px;
appearance: menulist;
&:focus{
    border-color: #372844;
    box-shadow: 0 0.5rem 1rem #9780af;
}
`

const ButtonRow = styled(Button)`
    background-color: white;
    color: black;
    border-radius: 15px;
    margin-bottom: 15px;
`

const ButtonGroupRow = styled(ButtonGroup)`
    display: inline;
`

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #372844;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    margin-top: 15px;
    margin-bottom: 90px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #9780af;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;