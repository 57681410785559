import React from 'react';
import { Col, Button } from 'reactstrap';
import styled from 'styled-components'

const TeamMemberList = ({ members, teamname, showMembersFunction, selected }) => {
    return (
        <ButtonCol>
            <StyledButton color={selected ? "primary" : "secondary"} onClick={() => showMembersFunction(members, teamname)} style={{ marginBottom: '1rem' }}>
                {teamname}
            </StyledButton>
        </ButtonCol>)}

const StyledButton = styled(Button)`
    font-size: 30px;
    min-width: 200px;
    &:active {
        background-color: #372844;
    }
    `

const ButtonCol = styled(Col)`
display: grid;
grid-auto-flow: column;
align-items: center;
justify-items: center;
`
 
export default TeamMemberList;