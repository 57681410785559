import React from 'react';
import { Container } from 'reactstrap';
import styled from 'styled-components';

const TermsOfUse = () => {
    return (
        <React.Fragment>
            <HeightDiv>
                <ParentDiv>
                    <StyledBackgroundImage />
                </ParentDiv>   
            </HeightDiv>
            <MarginDiv>
                <StyledContainer>
                    <h1>TERMS OF USE FOR HIMALAYAStrial.ca</h1>
                    <br />
                    <p>
                        These terms are effective June 1st, 2021. <br />
                        Please read the following terms and conditions carefully. If you do not agree to these terms and conditions, please do not proceed to register.
                        <br />
                        By using this website, you agree to the terms and conditions of HIMALAYAStrial.ca.
                    </p>
                    <p>You agree to use HIMALAYAStrial.ca in accordance with the following Code of Conduct:</p>
                    <ol>
                        <li>
                            You will keep all information provided to you as private and confidential and will not give such information to anyone without 
                            the permission of the person who provided it to you.
                        </li>
                        <li>
                            Your account is private and should not be used by anyone else. You will keep your account password secure and will not share it with others.
                        </li>
                        <li>
                            You will use the HIMALAYAStrial.ca website and services with respect to those people who are also registered users. You will not use the 
                            HIMALAYAStrial.ca website to engage in any form of harassment or offensive behaviour (e.g., posting slanderous, abusive or defamatory statements, 
                            or use of racist, obscene, or offensive language, or posting pornographic images). This website will also not be used to distribute messages or 
                            photos that violate plagiarism, copyright or trademark law;
                        </li>
                        <li>
                            You will not use the Service to distribute, promote or otherwise publish any material containing any solicitation for funds, advertising or solicitation 
                            for goods or services;
                        </li>
                        <li>
                            You will not forward chain letters or spam mail through the messaging section of HIMALAYAStrial.ca, and you will not distribute or upload any virus or 
                            other harmful actions to HIMALAYAStrial.ca or its members.
                        </li>
                        <li>
                            You agree that you are at least 18 years old, and that you have been diagnosed and treated for cancer;
                        </li>
                        <li>
                            You agree to engage in exercise at your own risk. If you have any questions or concerns about engaging in exercise given your health and conditions, 
                            please consult with HIMALAYAS trial doctor or exercise professional prior to starting or changing an exercise program. 
                        </li>
                    </ol>
                    <p>
                        We reserve the right to monitor messages, photos, and profile content to ensure that they conform to the code of conduct. If we find any such information 
                        that we deem to violate the code of conduct, your profile will not be approved and you will not be able to search for and match with other exercise partners. 
                        Also, your profile may be suspended if any information that violates the code of conduct is added at any time during use of HIMALAYAStrial.ca.
                    </p>
                    <p>
                        To help make sure that the profiles on HIMALAYAStrial.ca stay current, your profile will be removed at the end of the 24-month study period. This will not prevent 
                        you from creating a new profile on the public version of this website at a later date.
                    </p>
                    <p>
                        We are always in the process of trying to improve HIMALAYAStrial.ca, so you may receive emails inviting you to participate in evaluation studies.
                    </p>
                    <p>
                        By registering for HIMALAYAStrial.ca, I am consenting to these terms and conditions and I am agreeing to abide by the HIMALAYAStrial.ca Code of Conduct.
                    </p>
                    <br />
                </StyledContainer>
            </MarginDiv>
        </React.Fragment>
     );
}
 
export default TermsOfUse;

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #372844;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 1500px;
    margin-top: 15px;
    margin-bottom: 90px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #dddddd;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;

const MarginDiv = styled.div`
    margin-left: 30px;
    margin-right: 30px;
`