import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components'
import YoutubeEmbed from '../common/youTubeEmbed';
import { db } from '../../services/firebase';

const Support = (props) => {

    const [coreIntroVideoURL, setCoreIntroVideoURL] = useState("");
    
    const docRef = db.collection("webPages").doc("Core")

    useEffect(() => {
        ReturnPageInformation();   
    }, []);

    const ReturnPageInformation = () =>{
        docRef.onSnapshot((doc) => {
            setCoreIntroVideoURL(doc.data().coreIntroVideoURL);
        });
    }

    return (
        <React.Fragment>
            <MarginDiv>
                <StyledContainer>
                    <Row>
                        <Col>
                            <h2>HIMALAYAS Behavioural Support</h2>
                            <StyledParagraph>
                                Getting started with and maintaining a new exercise routine can be challenging, 
                                especially if you have not exercised much in the past. Whether you are just starting out, 
                                getting back into it, or looking to mix up your exercise routine – having access to reliable 
                                information and support tools can help ensure your success! 
                            </StyledParagraph>
                            <StyledParagraph>
                                We understand that how and when people need to access this information and support will be 
                                different and may change over time. So, we have developed two ways for you to access the information 
                                and support tools you need for the study. Access the Monthly Support Modules if you are new to exercise 
                                or want more of a guided experience. Access the Topic-Specific Support Modules if you have more exercise 
                                experience or are looking for some specific information.
                            </StyledParagraph>
                            <StyledParagraph>Recently received your bicycle? Click <a href="https://youtu.be/s_Sqn7e-otU" target="_blank">here</a> to see a tutorial on how to assemble it.</StyledParagraph>
                        </Col>
                        {coreIntroVideoURL !== "" && <VideoCol lg="6">
                            {coreIntroVideoURL !== "" && <YoutubeEmbed embedId={coreIntroVideoURL} /> }
                        </VideoCol>}
                    </Row>
                    <hr />
                    <Row>
                        <Col lg="6">
                            <a href="/exercise-groups/modules-monthly"><h2>Monthly Support Modules</h2></a>
                            <StyledParagraph>
                                New to exercise or want a more guided experience getting back into it?
                                <br />
                                Follow our carefully developed 12-month exercise support plan that has been designed to help you start and maintain your new exercise routine.
                            </StyledParagraph>
                        </Col>
                        <Col lg="6">
                            <a href="/exercise-groups/modules-specific"><h2>Topic-Specific Support Modules</h2></a>
                            <StyledParagraph>
                                Have a specific question / issue or need a refresher on a specific topic? 
                                <br />
                                Check out the individual support modules to get the answers you need now.
                            </StyledParagraph>
                        </Col>
                    </Row>
                </StyledContainer>
            </MarginDiv>
        </React.Fragment>
    );
}
 
export default Support;

const MarginDiv = styled.div`
    margin-left: 15px;
    margin-right: 15px;
`

const PlaceholderImage = styled.img`
    width: 100%;
`

const StyledParagraph = styled.p`
    font-size: large;
`

 
const VideoCol = styled(Col)`
min-height: 500px;
margin-bottom: 30px;
`

const StyledContainer = styled(Container)`
    justify-content: center;
    box-shadow: 0 0.5rem 1rem #372844;
    border-radius: 10px;
    border-color: #9780af #372844 #372844 #9780af;
    border: solid;
    background-color: white;
    margin: auto;
    padding: 30px;
    max-width: 1500px;
    margin-top: 15px;
    margin-bottom: 90px;
`

const StyledBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    z-index: -1000;
    object-fit: cover;
    position: fixed;
    background-color: #dddddd;
`;

const HeightDiv = styled.div`
    height: 100vh;
    position: absolute;
    z-index: -1;
`;

const ParentDiv = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`;